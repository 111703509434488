import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, customClaims, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, type Routes } from '@angular/router';
import { map, pipe } from 'rxjs';
import { CreateOrderComponent } from './create-order/create-order.component';
import { CustomerComponent } from './customers/customer/customer.component';
import { CustomersComponent } from './customers/customers.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
// import { ModelrenderComponent } from './modelrender/modelrender.component';
import { adminHashProtectionGuard } from './admin-hash/admin-hash-protection.guard';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { InvoiceComponent } from './invoices/invoice/invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { OrderComponent } from './orders/order/order.component';
import { OrdersComponent } from './orders/orders.component';
import { PatientLinkDesignComponent } from './patient-link-design/patient-link-design.component';
import { PatientComponent } from './patient/patient.component';
import { PatientsComponent } from './patients/patients.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { VerifyComponent } from './verify/verify.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const adminGuard = () =>
    pipe(
        customClaims,
        map(claims => (Array.isArray(claims) ? false : claims['admin'] === true)),
    );

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'verify', component: VerifyComponent },
    { path: 'share/:hash', component: PatientLinkDesignComponent },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
        children: [
            { path: '', redirectTo: 'orders', pathMatch: 'full' },
            { path: 'patients', component: PatientsComponent, canActivate: [AngularFireAuthGuard] },
            { path: 'patients/:id', component: PatientComponent, canActivate: [AngularFireAuthGuard] },
            { path: 'new', component: CreateOrderComponent },
            { path: 'orders', component: OrdersComponent },
            { path: 'orders/:id', component: OrderComponent },
            { path: 'orders/:id/draft', component: CreateOrderComponent },
            { path: 'profile', component: ProfileComponent },

            // TODO: #113 Finish invoice components
            // { path: 'invoices', component: InvoicesComponent },
            // { path: 'invoices/:id', component: InvoiceComponent },

            /////////////////////////////////////////////
            // From here on, only admin routes!
            /////////////////////////////////////////////
            {
                path: 'invoices',
                component: InvoicesComponent,
                canActivate: [AngularFireAuthGuard, adminHashProtectionGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'settings',
                component: AppSettingsComponent,
                canActivate: [AngularFireAuthGuard, adminHashProtectionGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers',
                component: CustomersComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers/:customerid',
                component: CustomerComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers/:customerid/orders/:id',
                component: OrderComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers/:customerid/patients/:id',
                component: PatientComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers/:customerid/invoices/:id',
                component: InvoiceComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: adminGuard },
            },
        ],
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
