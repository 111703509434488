import { Pipe, PipeTransform } from '@angular/core';

/**
 * Custom pipe that returns an array of keys from an object.
 *
 * @remarks
 * This pipe is used to extract the keys from an object and return them as an array.
 *
 * @example
 * ```
 * const obj = { name: 'John', age: 30, city: 'New York' };
 * const keys = obj | objectKeys;
 * console.log(keys); // Output: ['name', 'age', 'city']
 * ```
 */
@Pipe({ name: 'objectKeys' })
export class ObjectKeysPipe implements PipeTransform {
    transform<T extends {}, K extends keyof T>(value: T, ...args: unknown[]): K[] {
        return Object.keys(value) as K[];
    }
}
