<mat-toolbar><span>View patient design</span></mat-toolbar>
<div class="main-wrapper" [ngClass]="codeVerified ? 'fullsize' : ''">
  @if (error) {
    <mat-card>
      <mat-card-header>
        <mat-card-title>Error</mat-card-title>
      </mat-card-header>
      <mat-card-content matError>{{ error }}</mat-card-content>
    </mat-card>
  }
  @if (codeVerified) {
    <mat-card class="patient-design" [ngClass]="codeVerified ? 'fullsize' : ''">
      <mat-card-header>
        <mat-card-title>Design</mat-card-title>
        <mat-card-subtitle>Design for patient</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <iframe class="model-iframe" [src]="designURL"></iframe>
      </mat-card-content>
    </mat-card>
  } @else {
    @if (!error) {
      <mat-card class="code-card">
        <mat-card-header>
          <mat-card-title>Enter code</mat-card-title>
          <mat-card-subtitle>Enter code to view patient design</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field>
            <input [formControl]="code" matInput placeholder="Code" (keyup.enter)="verifyCode()" />
            @if (code.hasError('required')) {
              <mat-error>Code is required</mat-error>
            }
            @if (code.hasError('invalidCode')) {
              <mat-error>Wrong code</mat-error>
            }
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" (click)="verifyCode()">Verify code</button>
        </mat-card-actions>
      </mat-card>
    }
  }
</div>
