import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Firestore, doc, docData, setDoc } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { OrderType } from './types';
import { typeConverter } from './util';

/**
 * Represents the order threshold configuration.
 */
export type OrderThreshold = {
    min: number;
    price: {
        single: number;
        double: number;
    };
};

/**
 * Represents the pricing information for an order.
 */
export type OrderPricing = {
    thresholds?: OrderThreshold[];
};

/**
 * Represents the default order settings for a specific order type.
 * @template orderType - The type of the order.
 */
export type OrderDefault<orderType extends OrderType> = {
    deadline: number;
    pricing: orderType extends 'Aligner' | 'Study model(s)' ? OrderPricing : number;
};

/**
 * Represents the application settings.
 */
export type AppSettings = {
    orderNotifications: boolean;
    notificationMailbox?: string;
    orderDefaults: {
        [key in OrderType]: OrderDefault<key>;
    };
};

@Injectable({
    providedIn: 'root',
})
/**
 * Service for managing application settings.
 */
export class AppSettingsService {
    constructor(
        private readonly firestore: Firestore,
        private readonly snackbar: MatSnackBar,
    ) {}

    // TODO:#205 This document keeps throwing firestore auth errors since its instantly trying to access the document.
    // The app settings component should be lazy loaded, then the user is already authenticated when accessing this document.
    private readonly settingsDoc = doc(this.firestore, 'appsettings/latest').withConverter(typeConverter<AppSettings>());

    /**
     * Observable that emits the changes to the settings document.
     */
    readonly settingsDoc$ = docData(this.settingsDoc);

    /**
     * Signal that emits the changes to the settings document.
     */
    readonly settingsSignal$ = toSignal(this.settingsDoc$);

    /**
     * Sets the application settings.
     * @param settings The settings to be set.
     */
    async setSettings(settings: AppSettings) {
        await setDoc(this.settingsDoc, settings);
        this.snackbar.open('Settings saved!', 'close', { duration: 3000 });
    }
}
