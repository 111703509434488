<ng-container *ngIf="patient$ | async as patient">
    <mat-toolbar>
        <div class="title-box">
            <mat-icon class="title-icon" matListItemIcon>person</mat-icon>
            <span class="subtitle">Patient</span>
        </div>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <mat-card class="patient-card">
        <mat-card-header>
            <mat-card-title>
                {{ patient.firstName }} {{ patient.lastName }}
                <button mat-icon-button (click)="toggleEditForm()">
                    <mat-icon color="primary">{{ editing ? 'done' : 'edit' }}</mat-icon>
                </button>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="editPatientForm">
                <mat-form-field appearance="fill" class="birth-date">
                    <mat-label>Patient Birthdate</mat-label>
                    <input
                        matInput
                        [matDatepicker]="picker"
                        formControlName="birthdate"
                        [max]="maxDate"
                        [min]="minDate"
                        [readonly]="!editing"
                    />
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                    <!-- TODO: Error messages when the date is out of range of the maxDate or minDate -->
                    <mat-error *ngIf="editPatientForm.controls.birthdate.hasError('required')">Patient birthdate is required.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Patient e-mail</mat-label>
                    <input matInput placeholder="e-mail" formControlName="email" [readonly]="!editing" />
                    <mat-error *ngIf="editPatientForm.controls.email.hasError('email')"
                        >"{{ editPatientForm.controls.email.value }}" is not a valid email address.</mat-error
                    >
                </mat-form-field>
                <div *ngIf="customer$ | async as customer">
                    <div class="subtitle-with-button">
                        <h2>Practice</h2>
                        <button matTooltip="Add new practice" mat-icon-button (click)="openNewPracticeDialog()" *ngIf="editing">
                            <mat-icon color="primary">add_business</mat-icon>
                        </button>
                    </div>
                    <div class="practice-form">
                        <mat-form-field
                            appearance="fill"
                            class="practice-field"
                            [ngClass]="editing ? 'editing' : ''"
                            *ngIf="customer.practices?.length; else noPractices"
                        >
                            <mat-label>Treating Practice</mat-label>
                            <mat-select formControlName="practice" [compareWith]="compareFunction">
                                <mat-option *ngFor="let practice of customer?.practices" [value]="practice" [disabled]="!editing">
                                    {{ practice.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <ng-template #noPractices>Your account has no practices yet, create one to continue</ng-template>
                        <div *ngIf="editPatientForm.controls.practice.value as currentPractice">
                            <p>
                                {{ currentPractice.street }}
                                {{ currentPractice.housenumber }} <br />
                                <span *ngIf="currentPractice.street2">{{ currentPractice.street2 }}<br /></span>
                                {{ currentPractice.postalcode }}
                                {{ currentPractice.city }} <br />
                                {{ currentPractice.country }}
                            </p>
                        </div>

                        <!-- New Practice Dialog -->
                        <ng-template #newPracticeDialog>
                            <h1 mat-dialog-title>Add new practice</h1>
                            <div mat-dialog-content class="new-practice-dialog-content">
                                <form [formGroup]="newPracticeForm">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Practice name</mat-label>
                                        <input formControlName="name" matInput />
                                        <mat-error *ngIf="newPracticeForm.controls.name.hasError('required')"
                                            >Practice name is required.</mat-error
                                        >
                                    </mat-form-field>
                                    <div class="address-block">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Street</mat-label>
                                            <input formControlName="street" matInput />
                                            <mat-error *ngIf="newPracticeForm.controls.street.hasError('required')"
                                                >Street is required.</mat-error
                                            >
                                        </mat-form-field>
                                        <mat-form-field appearance="fill">
                                            <mat-label>Housenumber</mat-label>
                                            <input formControlName="housenumber" matInput />
                                            <mat-error *ngIf="newPracticeForm.controls.housenumber.hasError('required')"
                                                >Housenumber is required.</mat-error
                                            >
                                            <mat-error *ngIf="newPracticeForm.controls.housenumber.hasError('pattern')"
                                                >Not a valid housenumber.</mat-error
                                            >
                                        </mat-form-field>
                                        <mat-form-field appearance="fill">
                                            <mat-label>Street 2 (optional)</mat-label>
                                            <input formControlName="street2" matInput />
                                        </mat-form-field>
                                        <div>
                                            <mat-form-field appearance="fill">
                                                <mat-label>Postal code</mat-label>
                                                <input formControlName="postalcode" matInput />
                                                <mat-error *ngIf="newPracticeForm.controls.postalcode.hasError('required')"
                                                    >Postal code is required.</mat-error
                                                >
                                                <mat-error *ngIf="newPracticeForm.controls.postalcode.hasError('pattern')"
                                                    >Not a valid postal code.</mat-error
                                                >
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>City</mat-label>
                                                <input formControlName="city" matInput />
                                                <mat-error *ngIf="newPracticeForm.controls.city.hasError('required')"
                                                    >City is required.</mat-error
                                                >
                                            </mat-form-field>
                                        </div>
                                        <mat-form-field appearance="fill">
                                            <mat-label>Country</mat-label>
                                            <input formControlName="country" matInput />
                                        </mat-form-field>
                                    </div>
                                </form>
                            </div>
                            <div mat-dialog-actions class="button-wrapper">
                                <button mat-button mat-dialog-close>Cancel</button>
                                <button
                                    color="primary"
                                    [disabled]="newPracticeForm.invalid"
                                    [mat-dialog-close]="customer"
                                    mat-raised-button
                                >
                                    Add
                                </button>
                            </div>
                        </ng-template>
                    </div>
                    <!-- Orders -->
                    <div class="subtitle-with-button">
                        <h2>Orders / Treatments</h2>
                        @if (this.authFirestoreId() === this.customerFirestoreId()) {
                            <button
                                mat-stroked-button
                                color="primary"
                                [queryParams]="{
                                    patientFirstname: patient.firstName,
                                    patientLastname: patient.lastName,
                                    practice: patient.practice.name
                                }"
                                routerLink="/new"
                            >
                                Create new order
                            </button>
                        }
                    </div>
                    <mat-list class="patient-orders">
                        <mat-list-item
                            *ngFor="let order of patientOrders$ | async"
                            [routerLink]="
                                order.status === 'Draft'
                                    ? ['/orders', order.firestoreId, 'draft']
                                    : admin()
                                      ? ['/customers', customerFirestoreId(), 'orders', order.firestoreId]
                                      : ['/orders', order.firestoreId]
                            "
                        >
                            <mat-icon matListItemIcon>chevron_right</mat-icon>
                            <div matListItemTitle>{{ order.type }} / {{ order.creationDate | timestamp }}</div>
                            <div matListItemLine>{{ order.status | productionSubStatus | async }}</div>
                            <div matListItemMeta>
                                <button
                                    style="margin-top: 0.5rem"
                                    matTooltip="Request a Refinement for this order"
                                    mat-stroked-button
                                    color="primary"
                                    (click)="routeToNewOrder(order.id, $event)"
                                    *ngIf="order.status === 'Send' && order.type === 'Aligner' && refinementDeadlineReached(order)"
                                >
                                    <mat-icon>arrow_circle_up</mat-icon>
                                    Request Refinement
                                </button>
                                <button
                                    style="margin-top: 0.5rem"
                                    matTooltip="Delete order"
                                    mat-stroked-button
                                    color="primary"
                                    (click)="openDeleteOrderDialog($event, order)"
                                    *ngIf="order.status === 'Draft'"
                                >
                                    <mat-icon>delete</mat-icon>
                                    Delete draft
                                </button>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</ng-container>

<ng-template #deleteOrderDialog let-data>
    <h1 mat-dialog-title>Delete order</h1>
    <p mat-dialog-content>Are you sure you want to delete this draft order?</p>
    <div mat-dialog-actions class="button-wrapper">
        <button mat-button mat-dialog-close>Cancel</button>
        <button color="primary" mat-raised-button [mat-dialog-close]="true">Delete order</button>
    </div>
</ng-template>
