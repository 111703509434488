import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormControl, Validators } from '@angular/forms';
import { MatListOption } from '@angular/material/list';
import { pullAll } from 'lodash';
import { Subject, lastValueFrom, map, takeUntil } from 'rxjs';

@Component({
    selector: 'uploader',
    templateUrl: './uploader.component.html',
    styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent implements AfterViewInit, OnDestroy {
    constructor(private readonly storage: AngularFireStorage) {}

    // Expose the required validator to the template so we can use it to show the icon with hasValidator()
    requiredValidator = Validators.required;
    destroy$ = new Subject<null>();
    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        if (this.showPicture) {
            this.formcontrol?.valueChanges
                .pipe(
                    map(async v => {
                        if (v) {
                            const ref = this.storage.ref(v);
                            this.downloadURL = await lastValueFrom(ref.getDownloadURL());
                        }
                    }),
                    takeUntil(this.destroy$),
                )
                .subscribe();
        }
    }

    downloadURL?: string;
    isHovering = false;
    error?: string;

    files: File[] = [];

    @Input() orderId?: string;
    @Input() customerId?: string;
    @Input() backgroundImage?: string;
    @Input() formcontrol?: FormControl;
    @Input() showPicture = true;
    @Input() prefix?: number | string;
    @Input() multipleFiles = false;

    async deleteUploadedPicture() {
        this.error = undefined;
        if (!this.formcontrol?.value) return;
        const ref = this.storage.ref(this.formcontrol.value);
        ref.delete();
        // reset everything for this component
        this.files = [];
        this.formcontrol.reset();
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    async onDrop(files: FileList) {
        this.error = undefined;
        for (let i = 0; i < files.length; i++) {
            const item = files.item(i);
            if (!item) return;
            if (this.fileInvalid(item)) return;

            this.files.push(item);
            await this.timer(100);
        }
    }
    async chooseFileInput(fileInputEvent: any) {
        this.error = undefined;
        if (fileInputEvent.target.files.length) {
            for (const file of fileInputEvent.target.files) {
                if (this.fileInvalid(file)) return;
                this.files.push(file);
                await this.timer(100);
            }
        }
    }

    fileInvalid(item: File): boolean {
        if (
            !(
                item?.type.startsWith('image') ||
                item?.type.startsWith('model') ||
                item?.name.endsWith('.stl') ||
                item?.name.endsWith('.obj')
            )
        ) {
            this.error = `Filetype ${item?.type} is not accepted`;
            return true;
        }
        /** Max file size 20MB */
        if (item.size > 20_000_000) {
            this.error = `File size not allowed, maximum filesize is 20 MB`;
            return true;
        }
        return false;
    }

    onlyFilename(fullResource: string): string {
        const split = fullResource.split('/');
        return split[split.length - 1].slice(4);
    }

    /** Only for multi file option */
    async deleteFiles(files: MatListOption[]) {
        const filesToDelete = files.map(v => v.value);
        const filesToDeletePromises = files.map(v => {
            const ref = this.storage.ref(v.value);
            return lastValueFrom(ref.delete()).catch(e => {
                if (`${e}`.includes('(storage/object-not-found)')) {
                    console.log('File apparently already deleted, moving on.');
                } else {
                    throw e;
                }
            });
        });
        await Promise.all(filesToDeletePromises);

        const current: string[] = this.formcontrol?.value;
        const removedFiles = pullAll(current, filesToDelete);
        this.formcontrol?.setValue(removedFiles);
    }

    /** TODO: Hacky timer shit wat weg moet. Probleem zit bij meerdere files uploaden
     * Krijgt een race conditie wie het array van de formcontrol mag bijwerken.
     *
     * Oplossing zit ergens richting FormArray gebruiken. de losse componenten willen allemaal dezelfde value van zijn formcontrol wijzigen.
     */
    timer = (ms: number) => new Promise(res => setTimeout(res, ms));
}
