import { Injectable } from '@angular/core';
import {
    Firestore,
    collection,
    collectionData,
    deleteField,
    docData,
    orderBy,
    query,
    runTransaction,
    writeBatch,
} from '@angular/fire/firestore';
import { Timestamp, collectionGroup, doc, updateDoc } from '@firebase/firestore';
import { groupBy, sum } from 'lodash';
import * as moment from 'moment';
import 'moment/locale/nl';
import * as pdfMake from 'pdfmake/build/pdfmake';
import type { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { IdService } from './id.service';
import type { Invoice, Order, TotalAndDiscountAmount } from './types';
import { removeUndefinedProperties, typeConverter } from './util';
const pdfFonts = require('../assets/js/vfs_fonts');

(pdfMake.vfs as any) = pdfFonts.pdfMake.vfs;
(pdfMake.fonts as any) = {
    Quicksand: {
        normal: 'Quicksand-Regular.ttf',
        bold: 'Quicksand-Bold.ttf',
        medium: 'Quicksand-Medium.ttf',
        semiBold: 'Quicksand-SemiBold.ttf',
    },
};

type RowHeights = { [key: number]: number | 'auto' };

/** Vastgestelde hoogtes */
const ROW_HEIGHTS: RowHeights = {
    0: 30,
    1: 173,
    2: 400,
    3: 50,
};

const MONTHLY_ROW_HEIGHTS: RowHeights = {
    0: 540,
    1: 30,
    2: 30,
};

@Injectable({
    providedIn: 'root',
})
export class InvoiceService {
    constructor(
        private readonly idService: IdService,
        private readonly firestore: Firestore,
        private readonly authService: AuthService,
    ) {}

    /**
     * Retrieves a list of invoices from the Firestore database.
     *
     * This method queries the 'invoices' collection group, orders the results
     * by the 'createdAt' field in descending order, and returns the data as an
     * observable array of `Invoice` objects.
     *
     * @returns {Observable<Invoice[]>} An observable that emits an array of `Invoice` objects.
     */
    getInvoices(): Observable<Invoice[]> {
        const collectionRef = collectionGroup(this.firestore, 'invoices').withConverter(typeConverter<Invoice>());
        const queryRef = query(collectionRef, orderBy('createdAt', 'desc'));
        return collectionData(queryRef, { idField: 'firestoreId' });
    }

    /**
     * Creates a monthly invoice based on the provided orders, totals, and recipient email.
     *
     * @param orders - An array of orders with their corresponding Firestore IDs.
     * @param totals - The total and discount amount for the invoice.
     * @param recipientEmail - The email address of the invoice recipient.
     * @returns A Promise that resolves to void.
     * @throws An error if the customer with the specified ID is not found.
     */
    async createMonthlyInvoice(
        orders: Array<Order & { firestoreId: string }>,
        totals: TotalAndDiscountAmount,
        recipientEmail: string,
    ): Promise<void> {
        // Batchwrite can handle up to 500 operations. Abort if there are more than 499 orders (1 write for the actual invoice).
        if (orders.length > 499) {
            throw new Error(`Cannot create monthly invoice with more than 500 orders`);
        }
        const firstOrder = orders[0];
        if (!firstOrder) {
            throw new Error(`No orders found when creating monthly invoice`);
        }
        const { firestoreId, id, firstName, lastName } = firstOrder.customer;
        if (!firstName || !lastName) {
            throw new Error(`Customer information not present on order ${firstOrder.firestoreId} when creating monthly invoice`);
        }
        // Get the year and month for the functional invoice ID from the first order in the list.
        const firstOrderSendDate = firstOrder.sendDate?.toDate();
        if (!firstOrderSendDate) {
            throw new Error(`Desired completion date not present on order ${firstOrder.firestoreId} when creating monthly invoice`);
        }
        const year = firstOrderSendDate.getFullYear();
        const month = firstOrderSendDate.getMonth() + 1; // Months are zero-based.

        const invoiceId = await this.idService.getNewMonthlyInvoiceNr({ firestoreId, functionalId: id, year, month });
        const invoiceCollection = collection(this.firestore, `customers/${firestoreId}/invoices`).withConverter(
            typeConverter<Omit<Invoice, 'firestoreId'>>(),
        );
        const currentUser = this.authService.userSignal$()?.email ?? 'unknown';
        const batch = writeBatch(this.firestore);
        const invoice: Omit<Invoice, 'firestoreId'> = {
            functionalId: invoiceId,
            customer: {
                id: firestoreId,
                firstName,
                lastName,
            },
            yearMonth: `${year}-${month.toString().padStart(2, '0')}`,
            practice: firstOrder.practice,
            createdAt: Timestamp.fromDate(new Date()),
            orderIds: orders.map(order => order.firestoreId),
            totals,
            mail: {
                sentBy: currentUser,
                address: recipientEmail,
            },
        };

        batch.set(doc(invoiceCollection), { ...invoice });
        for (const order of orders) {
            const orderRef = doc(this.firestore, `customers/${firestoreId}/orders/${order.firestoreId}`).withConverter(
                typeConverter<Order>(),
            );
            batch.update(orderRef, { monthlyInvoiceId: invoiceId });
        }
        await batch.commit();
    }

    getMonthlyInvoice(invoiceFirestoreId: string, customerId: string): Observable<Invoice | undefined> {
        const invoiceRef = doc(this.firestore, `customers/${customerId}/invoices/${invoiceFirestoreId}`).withConverter(
            typeConverter<Invoice>(),
        );
        return docData(invoiceRef);
    }

    /**
     * Deletes an invoice and updates its associated orders.
     * This method is transactional, meaning that all operations are executed as a single unit.
     */
    async deleteInvoice({ invoiceFirestoreId, customerId }: { invoiceFirestoreId: string; customerId: string }): Promise<void> {
        // Delete the invoice and update its associated orders.
        const invoiceRef = doc(this.firestore, `customers/${customerId}/invoices/${invoiceFirestoreId}`);
        await runTransaction(this.firestore, async transaction => {
            const invoiceDoc = await transaction.get(invoiceRef);
            if (!invoiceDoc.exists()) {
                throw new Error(`Invoice with ID ${invoiceFirestoreId} not found`);
            }
            const invoice = invoiceDoc.data() as Invoice;
            const orderRefs = invoice.orderIds.map(orderId => doc(this.firestore, `customers/${customerId}/orders/${orderId}`));
            orderRefs.forEach(orderRef => transaction.update(orderRef, { monthlyInvoiceId: null }));
            transaction.delete(invoiceRef);
        });
    }

    /**
     * Retrieves the invoices for a specific customer.
     *
     * @param customerId - The ID of the customer.
     * @returns An Observable that emits an array of Invoice objects.
     */
    getCustomerInvoices(customerId: string): Observable<Invoice[]> {
        const collectionRef = collection(this.firestore, `customers/${customerId}/invoices`).withConverter(typeConverter<Invoice>());
        return collectionData(collectionRef, { idField: 'firestoreId' });
    }

    /**
     * Marks an invoice as paid for a specific customer.
     * @param customerId - The ID of the customer.
     * @param invoiceId - The ID of the invoice.
     * @returns A promise that resolves when the invoice is marked as paid.
     */
    async markInvoicePaid(customerId: string, invoiceId: string): Promise<void> {
        const invoiceRef = doc(this.firestore, `customers/${customerId}/invoices/${invoiceId}`).withConverter(typeConverter<Invoice>());
        await updateDoc(invoiceRef, { paidAt: Timestamp.fromDate(new Date()) });
    }

    /**
     * Marks an invoice as unpaid.
     *
     * @param {string} customerId - The ID of the customer.
     * @param {string} invoiceId - The ID of the invoice.
     * @returns {Promise<void>} A promise that resolves when the invoice is marked as unpaid.
     */
    async markInvoiceUnpaid(customerId: string, invoiceId: string): Promise<void> {
        const invoiceRef = doc(this.firestore, `customers/${customerId}/invoices/${invoiceId}`).withConverter(typeConverter<Invoice>());
        await updateDoc(invoiceRef, { paidAt: deleteField() });
    }

    async getPDFMonthlyInvoice(
        orders: Array<Order & { firestoreId: string }>,
        discount: number | undefined = undefined,
        concept = true,
        functionalId?: string,
    ): Promise<pdfMake.TCreatedPdf> {
        let invoiceId: string = 'CONCEPT';
        if (!concept && functionalId) {
            invoiceId = functionalId;
        }
        // Grab first order to get customer details. All orders should be from the same customer.
        const firstOrder = orders[0];
        if (!firstOrder) throw new Error(`No orders found when getting PDF for monthly invoice`);

        const pdfDocument: TDocumentDefinitions = {
            pageMargins: 1,
            content: [
                {
                    table: {
                        heights: 'auto',
                        body: [
                            [
                                {
                                    table: {
                                        widths: ['auto', '*', 'auto'],
                                        body: [
                                            [{ text: 'Smile Art', fontSize: 16 }, {}, {}],
                                            [{ text: 'Boerhaavelaan 40' }, {}, getAlignmentRight(firstOrder.practice.name)],
                                            [
                                                { text: '2713HX Zoetermeer' },
                                                {},
                                                getAlignmentRight(`${firstOrder.practice.street} ${firstOrder.practice.housenumber}`),
                                            ],
                                            [
                                                { text: 'https://www.smile-art.nl' },
                                                {},
                                                getAlignmentRight(`${firstOrder.practice.postalcode} ${firstOrder.practice.city}`),
                                            ],
                                            [{ text: 'info@smileart.nl' }, {}, {}],
                                            // [{}, {}, getAlignmentRight(`${firstOrder.patient.firstName} ${firstOrder.patient.lastName}`)],
                                            [{ text: 'BTW: NL864391274B01' }, {}, getAlignmentRight(`Factuur #${invoiceId}`)],
                                            [{ text: 'KvK: 87742063' }, {}, getAlignmentRight(moment().format('L'))],
                                            [{ text: 'IBAN: NL31 ABNA 0116 4818 70' }, {}, {}],
                                        ],
                                    },
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginBottom: 5,
                                    marginTop: 10,
                                    layout: {
                                        defaultBorder: false,
                                    },
                                },
                            ],
                            // NO DISCLAIMER FOR MONTHLY INVOICE
                            // ORDER PRICING
                            [
                                {
                                    table: {
                                        widths: ['*', 0, 110],
                                        heights: row => (orders.length <= 12 ? MONTHLY_ROW_HEIGHTS[row] : 'auto'),
                                        body: [
                                            [
                                                // TODO: Maybe we shouldnt get as one string, but as an array. Then we have a row for each order.
                                                {
                                                    text: getProductList(orders, discount, true),
                                                    lineHeight: 1.3,
                                                    marginLeft: 20,
                                                    marginTop: 20,
                                                },
                                                {},
                                                {
                                                    text: getPriceList(orders, discount, true),
                                                    lineHeight: 1.3,
                                                    alignment: 'center',
                                                    marginTop: 20,
                                                },
                                            ],
                                            [{}, {}, { text: 'Totaalbedrag', alignment: 'center', marginTop: 5, marginBottom: 5 }],
                                            [
                                                {
                                                    text: `Maandoverzicht${' '.repeat(concept ? 69 : 60)}Factuur #${invoiceId}`,
                                                    marginLeft: 20,
                                                    marginTop: 5,
                                                    marginBottom: 5,
                                                },
                                                '',
                                                {
                                                    text: `${formatEuroAmount(
                                                        getTotalAndDiscountAmount(orders, discount).totalWithDiscount ??
                                                            getTotalAndDiscountAmount(orders, discount).total,
                                                    )}`,
                                                    alignment: 'center',
                                                    marginTop: 5,
                                                },
                                            ],
                                        ],
                                    },
                                    layout: {
                                        vLineWidth: (i, node) => (i < 2 || i === node.table.body.length ? 0 : 5),
                                        hLineWidth: (i, node) => (i < 2 || i === node.table.body.length ? 0 : 5),
                                        vLineColor: () => '#F6F6F6',
                                        hLineColor: () => '#F6F6F6',
                                        marginLeft: 20,
                                    },
                                },
                            ],
                            // FOOTER
                            [
                                {
                                    text: 'Graag zien wij de betaling binnen 15 dagen na ontvangen van het maandoverzicht. \n Art 11 lid 1 sub g OB BTW vrijgesteld',
                                    marginLeft: 20,
                                    marginTop: 10,
                                    marginBottom: 10,
                                    fontSize: 10,
                                },
                            ],
                        ],
                    },
                    layout: {
                        paddingBottom: () => 0,
                        paddingLeft: () => 0,
                        paddingRight: () => 0,
                        paddingTop: () => 0,
                        hLineWidth: () => 5,
                        vLineWidth: () => 5,
                        hLineColor: () => '#F6F6F6',
                        vLineColor: () => '#F6F6F6',
                    },
                    color: '#545454',
                },
            ],
            defaultStyle: {
                font: 'Quicksand',
                lineHeight: 0.85,
            },
        };
        return pdfMake.createPdf(pdfDocument);
    }

    async createPDF(orders: Array<Order & { firestoreId: string }>) {
        const firstOrder = orders[0];
        let invoiceId: string;

        if (!firstOrder) throw new Error(`No orders found when creating PDF for invoice`);
        if (!firstOrder.invoiceId) {
            invoiceId = await this.idService.getNewInvoiceNr();
            const docRef = doc(
                this.firestore,
                `/customers/${firstOrder.customer.firestoreId}/orders/${firstOrder.firestoreId}`,
            ).withConverter(typeConverter<Order>());
            await updateDoc(docRef, { invoiceId });
        } else {
            invoiceId = firstOrder.invoiceId;
        }

        const pdfDocument: TDocumentDefinitions = {
            pageMargins: 1,
            content: [
                {
                    table: {
                        heights: row => ROW_HEIGHTS[row] ?? 'auto',
                        body: [
                            // HEADER
                            [
                                {
                                    table: {
                                        widths: ['auto', '*', 'auto'],
                                        body: [
                                            [{ text: 'Smile Art', fontSize: 16 }, {}, {}],
                                            [{ text: 'Boerhaavelaan 40' }, {}, getAlignmentRight(firstOrder.practice.name)],
                                            [
                                                { text: '2713HX Zoetermeer' },
                                                {},
                                                getAlignmentRight(`${firstOrder.practice.street} ${firstOrder.practice.housenumber}`),
                                            ],
                                            [
                                                { text: 'https://www.smile-art.nl' },
                                                {},
                                                getAlignmentRight(`${firstOrder.practice.postalcode} ${firstOrder.practice.city}`),
                                            ],
                                            [{ text: 'info@smileart.nl' }, {}, {}],
                                            [{}, {}, getAlignmentRight(`${firstOrder.patient.firstName} ${firstOrder.patient.lastName}`)],
                                            [{ text: 'BTW: NL864391274B01' }, {}, getAlignmentRight(`Factuur #${invoiceId}`)],
                                            [{ text: 'KvK: 87742063' }, {}, getAlignmentRight(moment().format('L'))],
                                            [{ text: 'IBAN: NL31 ABNA 0116 4818 70' }, {}, {}],
                                        ],
                                    },
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginBottom: 5,
                                    marginTop: 10,
                                    layout: {
                                        defaultBorder: false,
                                    },
                                },
                            ],
                            // DISCLAIMER
                            [
                                {
                                    text: `Beste,
                                    
                                Deze nota is voor het werkstuk van ${firstOrder.patient.firstName} ${firstOrder.patient.lastName}. Dit werkstuk is uitsluitend gemaakt voor ${firstOrder.patient.firstName} ${firstOrder.patient.lastName} en voldoet aan de eisen van de MDR.`,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginBottom: 50,
                                    marginTop: 50,
                                },
                            ],
                            // ORDER PRICING
                            [
                                {
                                    table: {
                                        widths: ['*', 0, 100],
                                        heights: row => (row === 0 ? 315 : row === 1 ? 30 : 50),
                                        body: [
                                            [
                                                { text: getProductList(orders), lineHeight: 2, marginLeft: 20, marginTop: 20 },
                                                {},
                                                {
                                                    text: getPriceList(orders),
                                                    lineHeight: 2,
                                                    alignment: 'center',
                                                    marginTop: 20,
                                                },
                                            ],
                                            [{}, {}, { text: 'Totaalbedrag', alignment: 'center', marginTop: 5 }],
                                            [
                                                {
                                                    text: `Order #${firstOrder.id}${' '.repeat(75)}Factuur #${invoiceId}`,
                                                    marginLeft: 20,
                                                    marginTop: 18,
                                                },
                                                '',
                                                {
                                                    text: `${formatEuroAmount(getTotalAndDiscountAmount(orders).total)}`,
                                                    alignment: 'center',
                                                    marginTop: 18,
                                                },
                                            ],
                                        ],
                                    },
                                    layout: {
                                        vLineWidth: (i, node) => (i < 2 || i === node.table.body.length ? 0 : 5),
                                        hLineWidth: (i, node) => (i < 2 || i === node.table.body.length ? 0 : 5),
                                        vLineColor: () => '#F6F6F6',
                                        hLineColor: () => '#F6F6F6',
                                        marginLeft: 20,
                                    },
                                },
                            ],
                            // FOOTER
                            [
                                {
                                    text: 'Graag zien wij de betaling binnen 15 dagen na ontvangen van het maandoverzicht. \n Art 11 lid 1 sub g OB BTW vrijgesteld',
                                    marginLeft: 20,
                                    marginTop: 10,
                                    fontSize: 10,
                                },
                            ],
                        ],
                    },
                    layout: {
                        paddingBottom: () => 0,
                        paddingLeft: () => 0,
                        paddingRight: () => 0,
                        paddingTop: () => 0,
                        hLineWidth: () => 5,
                        vLineWidth: () => 5,
                        hLineColor: () => '#F6F6F6',
                        vLineColor: () => '#F6F6F6',
                    },
                    color: '#545454',
                },
            ],
            defaultStyle: {
                font: 'Quicksand',
                lineHeight: 0.85,
            },
        };
        return pdfMake.createPdf(pdfDocument);
    }
}

function getAlignmentRight(text: string) {
    return { text, alignment: 'right' };
}

function getProductList(orders: Order[], discount: number | null = null, monthlyInvoice = false): string {
    // Add patient names as headers when its a montly invoice.
    let lines: string[] = [];
    if (monthlyInvoice) {
        const groupedByPatient = groupBy(
            orders,
            order => `${order.patient.firstName} ${order.patient.lastName} (${moment(order.patient.birthdate.toDate()).format('L')})`,
        );
        for (const [patientName, value] of Object.entries(groupedByPatient)) {
            // If header isn't already added, add it.
            if (!lines.includes(patientName)) {
                lines.push(patientName);
            }
            // Add all orders to the list.
            lines = lines.concat(
                value
                    .map(
                        order =>
                            `${
                                order.type === 'Aligner'
                                    ? order.pricing?.quantity
                                    : order.upperArch && order.lowerArch
                                      ? 'Both Arches -'
                                      : 'Single Arch -'
                            } ${order.type} ${order.type === 'Aligner' ? 'model(s)' : ''}`,
                    )
                    // Indent all order lines.
                    .map(line => `-    ${line}`),
            );
        }
    } else {
        lines = orders.map(
            order =>
                `${
                    order.type === 'Aligner'
                        ? order.pricing?.quantity
                        : order.upperArch && order.lowerArch
                          ? 'Both Arches -'
                          : 'Single Arch -'
                } ${order.type} ${order.type === 'Aligner' ? 'model(s)' : ''}`,
        );
    }
    if (discount) {
        // Push 2 lines so there is some space between the order pricing and the discount.
        lines.push('', `korting ${discount * 100}%`);
    }
    return lines.join('\n');
}

function getPriceList(orders: Order[], discount: number | null = null, monthlyInvoice = false): string {
    let lines: string[] = [];
    if (monthlyInvoice) {
        const groupedByPatient = groupBy(orders, order => `${order.patient.firstName} ${order.patient.lastName}`);
        for (const [patientName, value] of Object.entries(groupedByPatient)) {
            // If header isn't already added, add it. For the pricing column we leave some empty space.
            if (!lines.includes(patientName)) {
                lines.push('');
            }
            // Add all orders to the list.
            lines = lines.concat(value.map(order => formatEuroAmount(order.pricing?.amount)));
        }
    } else {
        lines = orders.map(order => formatEuroAmount(order.pricing?.amount));
    }
    if (discount) {
        // Push 2 lines so there is some space between the order pricing and the discount.
        lines.push('', `- ${formatEuroAmount(getTotalAndDiscountAmount(orders, discount).discountAmount)}`);
    }
    return lines.join('\n');
}

function formatEuroAmount(amount?: number): string {
    if (amount == null) return '';
    return `€ ${amount.toLocaleString('nl-NL', { minimumFractionDigits: 2 })}`;
}

/**
 * Calculates the total amount and discount amount based on the given orders and discount percentage.
 * @param orders - An array of Order objects.
 * @param discount - The discount percentage (optional).
 * @returns An object containing the total amount, discount percentage, discount amount, and total amount with discount.
 */
export function getTotalAndDiscountAmount(orders: Order[], discount?: number): TotalAndDiscountAmount {
    // TODO: BigJS gebruiken bij afronding.
    const total = sum(orders.map(order => order.pricing?.amount));
    let discountAmount: number | undefined;
    let totalWithDiscount: number | undefined;
    if (discount != null) {
        discountAmount = total * discount;
        totalWithDiscount = total - discountAmount;
    }
    return removeUndefinedProperties({ total, discount, discountAmount, totalWithDiscount });
}
