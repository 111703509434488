import { Component, effect, signal, type Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DomSanitizer, type SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import type { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { distinctUntilKeyChanged, filter } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { InvoiceService } from '../../invoice.service';
import { OrdersService } from '../../orders.service';
import type { Invoice, OrderWithFirestoreId } from '../../types';
import { isDefined } from '../../util';

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent {
    constructor(
        private readonly invoiceService: InvoiceService,
        private readonly route: ActivatedRoute,
        private readonly authService: AuthService,
        private readonly orderService: OrdersService,
        private readonly sanitizer: DomSanitizer,
    ) {
        const invoiceId = this.route.snapshot.paramMap.get('id');
        const customerId = this.route.snapshot.paramMap.get('customerid') ?? this.authService.firestoreIdSignal$();
        if (!invoiceId) {
            throw new Error('No invoice id in invoice component route');
        }
        this.invoice$ = toSignal(
            this.invoiceService.getMonthlyInvoice(invoiceId, customerId).pipe(filter(isDefined), distinctUntilKeyChanged('functionalId')),
        );

        // When the invoice changes, load the orders and the PDF.
        effect(async () => {
            const invoice = this.invoice$();
            if (!invoice) {
                return;
            }
            const orders = await this.orderService.getOrdersByFirestoreId(invoice.customer.id, invoice.orderIds);
            // Generate the PDF.
            const pdf = await this.invoiceService.getPDFMonthlyInvoice(orders, invoice.totals.discount, false, invoice.functionalId);
            if (pdf == null) {
                throw new Error('Failed to generate PDF');
            }
            const url = await this.getDataUrlAsync(pdf);
            this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${url}#navpanes=0`);
            // Set the orders.
            this.orders$.set(orders);
        });
    }

    private getDataUrlAsync(pdf: TCreatedPdf): Promise<string> {
        return new Promise((resolve, reject) => {
            pdf.getDataUrl((url: string) => {
                if (url) {
                    resolve(url);
                } else {
                    reject(new Error('Failed to get data URL'));
                }
            });
        });
    }

    /**
     * Represents an observable signal that emits `Invoice` objects or `undefined`.
     */
    invoice$: Signal<Invoice | undefined>;
    orders$ = signal<OrderWithFirestoreId[] | undefined>(undefined);
    pdfURL: SafeResourceUrl | undefined;

    // TODO: #165 Ability to cancel an invoice
}
