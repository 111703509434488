export const environment = {
    useEmulators: false,
    production: false,
    firebase: {
        projectId: 'smile-art-722f0',
        appId: '1:473162239901:web:89d7a59171a6f5c002a974',
        storageBucket: 'smile-art-722f0.appspot.com',
        apiKey: 'AIzaSyChGMfwLE4FLqkK9i5dDwpzRW0SmcdIiAI',
        authDomain: 'smile-art-722f0.firebaseapp.com',
        messagingSenderId: '473162239901',
        measurementId: 'G-X55GYFZXMK',
    },
};
