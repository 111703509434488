import type { KeyValue } from '@angular/common';
import { Component, TemplateRef, ViewChild, type OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isEqual } from 'lodash';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { AppSettingsService, type OrderPricing, type OrderThreshold } from '../app-settings.service';
import { ORDER_TYPES, type OrderType } from '../types';
import { isDefined } from '../util';

export type OrderDefaultForm<orderType extends OrderType> = {
    deadline: FormControl<number>;
    pricing: orderType extends 'Aligner' | 'Study model(s)'
        ? FormGroup<{
              thresholds: FormControl<Array<OrderThreshold>>;
          }>
        : FormControl<number>;
};

type OrderTypeForm = {
    [key in OrderType]: FormGroup<OrderDefaultForm<key>>;
};
const ONLY_NUMBERS = /^\d+$/;
@Component({
    selector: 'app-app-settings',
    templateUrl: './app-settings.component.html',
    styleUrls: ['./app-settings.component.scss'],
})
export class AppSettingsComponent implements OnDestroy {
    constructor(
        readonly settingsService: AppSettingsService,
        private readonly dialog: MatDialog,
    ) {
        // TODO: Sorting by threshold TYPE
        // Dus property toevoegen aan een order type, en daarop sorteren.
        // Mogelijk pas in de template, compareFn meegeven aan keyvalue pipe.

        /** Sorts order types, adds them to the settings form, and subscribes to settings updates. */
        const sortedByThresholdsAtEnd = [
            ...ORDER_TYPES.filter(order => !['Aligner', 'Study model(s)'].includes(order)).sort(),
            ...['Aligner', 'Study model(s)'],
        ] as const;
        sortedByThresholdsAtEnd.forEach(order => {
            if (order === 'Aligner' || order === 'Study model(s)') {
                this.settingsForm.controls.orderDefaults.addControl(order, this.getOrderSettingsForm(order));
            } else {
                this.settingsForm.controls.orderDefaults.addControl(order, this.getOrderSettingsForm(order));
            }
        });

        settingsService.settingsDoc$
            .pipe(filter(isDefined), distinctUntilChanged(isEqual), takeUntil(this.onDestroySubject))
            .subscribe(settings => this.settingsForm.patchValue(settings));
    }

    @ViewChild('thresholdsDialog') thresholdsDialog!: TemplateRef<unknown>;
    @ViewChild('addThresholdDialog') addThresholdDialog!: TemplateRef<unknown>;
    dialogRef?: MatDialogRef<any>;

    /** Keep 'manual' sorting for now */
    originalOrder = (
        _a: KeyValue<string, FormGroup<OrderDefaultForm<OrderType>>>,
        _b: KeyValue<string, FormGroup<OrderDefaultForm<OrderType>>>,
    ): number => {
        return 0;
    };

    openThresholdsDialog(
        orderType: string,
        orderPricing:
            | FormGroup<{
                  thresholds: FormControl<Array<OrderThreshold>>;
              }>
            | FormControl<number>,
    ) {
        this.dialogRef = this.dialog.open(this.thresholdsDialog, {
            data: { orderType, orderPricing },
            disableClose: true,
            width: '512px',
        });
    }

    openAddThresholdDialog() {
        this.dialogRef = this.dialog.open(this.addThresholdDialog);
    }

    addThreshold(
        control: FormGroup<{
            thresholds: FormControl<Array<OrderThreshold>>;
        }>,
    ) {
        control.setValue({ thresholds: [...(control.value.thresholds ?? []), this.thresholdForm.value as OrderThreshold] });
        this.thresholdForm.reset();
    }

    /**
     * Removes a threshold from the control's thresholds array at the specified index.
     * @param control - The form group control containing the thresholds array.
     * @param index - The index of the threshold to be removed.
     */
    removeThreshold(
        control: FormGroup<{
            thresholds: FormControl<Array<OrderThreshold>>;
        }>,
        index: number,
    ) {
        const updatedThresholds = control.value.thresholds?.filter((_elem, i) => i !== index) ?? [];
        control.setValue({ thresholds: updatedThresholds });
    }

    /**
     * Checks if the form type is simple (e.g. not with Thresholds)
     * @param form - The order default form.
     * @returns True if the form type is simple, false otherwise.
     */
    isSimpleFormType(form: OrderDefaultForm<OrderType>): boolean {
        if (!form.pricing.value) return true;
        return typeof form.pricing.value === 'number';
    }

    /**
     * Casts a value to an array type.
     * If the value is a number or does not have thresholds, an empty array is returned.
     * Otherwise, the thresholds property of the value is returned.
     * @param value - The value to be casted.
     * @returns An array of thresholds if the value has thresholds, otherwise an empty array.
     */
    castToArrayType(value: OrderPricing | number) {
        if (typeof value === 'number' || !value.thresholds) return [];
        return value.thresholds;
    }

    getOrderSettingsForm(orderType: OrderType): FormGroup<OrderDefaultForm<typeof orderType>> {
        if (orderType === 'Aligner' || orderType === 'Study model(s)') {
            return new FormGroup<OrderDefaultForm<typeof orderType>>({
                deadline: new FormControl(1, { nonNullable: true, validators: [Validators.min(1), Validators.pattern(ONLY_NUMBERS)] }),
                pricing: new FormGroup({
                    thresholds: new FormControl<Array<OrderThreshold>>([{ min: 1, price: { single: 1, double: 2 } }], {
                        nonNullable: true,
                    }),
                }),
            });
        } else {
            return new FormGroup<OrderDefaultForm<typeof orderType>>({
                deadline: new FormControl(1, { nonNullable: true, validators: [Validators.min(1), Validators.pattern(ONLY_NUMBERS)] }),
                pricing: new FormControl(1, { nonNullable: true, validators: [Validators.pattern(ONLY_NUMBERS)] }),
            });
        }
    }

    /** Make sure we dont leave any memory leaks. */
    onDestroySubject = new Subject<null>();
    ngOnDestroy(): void {
        this.onDestroySubject.next(null);
        this.onDestroySubject.complete();
    }

    /**
     * Represents the form for setting the threshold values.
     */
    thresholdForm = new FormGroup({
        min: new FormControl(1, { nonNullable: true, validators: [Validators.min(1), Validators.pattern(ONLY_NUMBERS)] }),
        price: new FormGroup({
            single: new FormControl(1, { nonNullable: true, validators: [Validators.min(1), Validators.pattern(ONLY_NUMBERS)] }),
            double: new FormControl(1, { nonNullable: true, validators: [Validators.min(1), Validators.pattern(ONLY_NUMBERS)] }),
        }),
    });

    settingsForm = new FormGroup({
        orderNotifications: new FormControl(false, { nonNullable: true }),
        notificationMailbox: new FormControl('', { nonNullable: true, validators: [Validators.email] }),
        orderDefaults: new FormGroup<OrderTypeForm>({} as OrderTypeForm),
    });

    async saveSettings() {
        await this.settingsService.setSettings(this.settingsForm.getRawValue());
    }
}
