import { Injectable } from '@angular/core';
import { addDoc, collectionData, doc, docData, Firestore } from '@angular/fire/firestore';
import { collection, updateDoc } from '@firebase/firestore';
import { Observable } from 'rxjs';
import { Patient } from './types';
import { typeConverter } from './util';

@Injectable({
    providedIn: 'root',
})
export class PatientService {
    constructor(private readonly firestore: Firestore) {}

    getPatients(customerId: string): Observable<Patient[]> {
        const collectionRef = collection(this.firestore, 'customers', customerId, 'patients').withConverter(typeConverter<Patient>());
        return collectionData(collectionRef, { idField: 'id' });
    }

    getPatient(customerId: string, patientId: string): Observable<Patient | undefined> {
        const docRef = doc(this.firestore, 'customers', customerId, 'patients', patientId).withConverter(typeConverter<Patient>());
        return docData(docRef, { idField: 'id' });
    }

    async createPatient(customerId: string, patient: Omit<Patient, 'id'>): Promise<string> {
        const collectionRef = collection(this.firestore, 'customers', customerId, 'patients');
        const doc = await addDoc(collectionRef, patient);
        return doc.id;
    }

    async updatePatient(customerId: string, patientId: string, patient: Partial<Patient>): Promise<string> {
        const docRef = doc(this.firestore, 'customers', customerId, 'patients', patientId);
        await updateDoc(docRef, patient);
        return docRef.id;
    }
}
