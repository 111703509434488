import { DatePipe } from '@angular/common';
import { Pipe, type PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timestamp',
    standalone: true,
})
export class TimestampPipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: FirebaseFirestore.Timestamp | moment.Moment | undefined, format?: string): unknown {
        if (!value) return '';

        const defaultFormat = 'DD-MM-yyyy';
        const angularFormat = mapMomentFormatToAngularFormat(format || defaultFormat);

        if (moment.isMoment(value)) {
            return value.format(format || defaultFormat);
        }

        return this.datePipe.transform(value.toMillis(), angularFormat);
    }
}

/**
 * Maps a Moment.js format string to an Angular format string.
 *
 * @param format - The Moment.js format string to be mapped.
 * @returns The mapped Angular format string.
 */
function mapMomentFormatToAngularFormat(format: string): string {
    const formatMapping: { [key: string]: string } = {
        YYYY: 'yyyy',
        YY: 'yy',
        MMMM: 'MMMM',
        MMM: 'MMM',
        MM: 'MM',
        M: 'M',
        DD: 'dd',
        D: 'd',
        HH: 'HH',
        H: 'H',
        mm: 'mm',
        m: 'm',
        ss: 'ss',
        s: 's',
        A: 'a',
        a: 'a',
    };

    return format.replace(/YYYY|YY|MMMM|MMM|MM|M|DD|D|HH|H|mm|m|ss|s|A|a/g, match => formatMapping[match] || match);
}
