import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timestamp',
})
/**
 * Custom pipe for formatting timestamps.
 */
export class TimestampPipe implements PipeTransform {
    constructor(private readonly datePipe: DatePipe) {}

    /**
     * Transforms a timestamp value into a formatted string.
     * @param value - The timestamp value to transform.
     * @param format - The format string to use for formatting the timestamp. Defaults to 'DD-MM-yyyy'.
     * @returns The formatted string representation of the timestamp.
     */
    transform(value: FirebaseFirestore.Timestamp | moment.Moment | undefined, format?: string): unknown {
        if (!value) return '';
        if (moment.isMoment(value)) return value.format(format || 'DD-MM-yyyy');
        return this.datePipe.transform(value.toMillis(), format || 'dd-MM-yyyy');
    }
}
