@if (customersWithUnpaidOrdersSignal$(); as customers) {
    @if (customers.length) {
        <ng-container>
            @if (selectedOrdersSignal$()?.length) {
                <div class="discount-block">
                    <div>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Apply discount</mat-label>
                            <span matTextSuffix>%</span>
                            <input type="number" min="0" max="100" matInput [formControl]="discount" />
                        </mat-form-field>

                        @if (discount.invalid) {
                            <mat-error class="discount-validation-error"> {{ discount.value }}% is not a valid discount</mat-error>
                        }
                    </div>
                    @if (invoiceTotalSignal$(); as bedragen) {
                        <p [@grow]="bedragen">
                            Invoice total: {{ bedragen.total | currency: 'EUR' }}
                            @if (bedragen.totalWithDiscount) {
                                <span
                                    >- {{ bedragen.discountAmount | currency: 'EUR' }} =
                                    {{ bedragen.totalWithDiscount | currency: 'EUR' }}</span
                                >
                            }
                        </p>
                    }
                </div>
            }
            <div class="invoice-actions">
                <button
                    [disabled]="!selectedOrdersSignal$()?.length || discount.invalid"
                    matTooltip="Create invoice"
                    mat-icon-button
                    color="accent"
                    class="process-invoice-button"
                    (click)="openInvoiceConfirmationDialog()"
                >
                    <mat-icon>done</mat-icon>
                </button>
                <button
                    matTooltip="Preview concept invoice"
                    [disabled]="!selectedOrdersSignal$()?.length"
                    mat-icon-button
                    color="primary"
                    (click)="previewInvoice()"
                >
                    <mat-icon>visibility</mat-icon>
                </button>
                <button
                    [disabled]="!selectedOrdersSignal$()?.length"
                    matTooltip="Mark orders as already paid"
                    mat-icon-button
                    color="accent"
                    class="process-invoice-button"
                    (click)="markOrdersAsPaid()"
                >
                    <mat-icon>price_check</mat-icon>
                </button>
            </div>
            <div class="wrapper">
                <div class="customers">
                    <label class="medium-label">Customer</label>
                    <mat-radio-group [formControl]="selectedCustomer" class="scrollbox">
                        @for (customer of customers; track customer.firestoreId) {
                            <mat-radio-button [value]="customer"
                                >{{ customer.firstName }} {{ customer.lastName }} -
                                {{ customer.amountOfMissingMoney | currency: 'EUR' }}</mat-radio-button
                            >
                        }
                    </mat-radio-group>
                </div>
                <div class="practices">
                    <label class="medium-label">Practice</label>
                    @if (selectedCustomerSignal$()?.practices?.length !== 1) {
                        <mat-radio-group [formControl]="selectedPractice" class="scrollbox">
                            @for (practice of practicesSignal$(); track trackByPracticeNameAndMissingMoney) {
                                <mat-radio-button [value]="practice"
                                    >{{ practice.name }} - {{ practice.amountOfMissingMoney | currency: 'EUR' }}</mat-radio-button
                                >
                            }
                        </mat-radio-group>
                    } @else {
                        <mat-radio-button [checked]="true"
                            >{{ selectedCustomerSignal$()?.practices?.[0]?.name }} ({{
                                selectedCustomerSignal$()?.practices?.[0]?.amountOfMissingMoney | currency: 'EUR'
                            }})</mat-radio-button
                        >
                    }
                </div>
                @if (unpaidOrders$(); as unpaidOrders) {
                    <div class="orders scrollbox">
                        <label class="medium-label" style="margin-bottom: 5px"> Orders </label>
                        <!-- TODO: Dont forget the responsive css so its also mobile friendly! -->
                        <div class="order-group-wrapper scrollbox">
                            @for (orderGroup of unpaidOrders; track $index) {
                                <div class="order-group">
                                    <div class="order-group-header">
                                        <mat-checkbox
                                            #selectAll
                                            labelPosition="before"
                                            [checked]="selectedGroup === orderGroup.date"
                                            (change)="toggleAllUnpaidOrders($event, orderGroup.orders, orderGroup.date)"
                                        >
                                        </mat-checkbox>
                                        <label> {{ orderGroup.date | date: "MMMM '''yy" }} </label>
                                    </div>

                                    <mat-selection-list
                                        class="order-group-orders scrollbox"
                                        [formControl]="selectedOrders"
                                        #selectionList
                                        (selectionChange)="onOrderSelectionChange($event)"
                                    >
                                        @for (order of orderGroup.orders; track trackByOrderId) {
                                            <mat-list-option [value]="order" class="custom-list-option">
                                                {{ order.sendDate | timestamp }} | {{ order.type }} | {{ order.patient.firstName }}
                                                {{ order.patient.lastName }} |
                                                {{ order.pricing?.amount | currency: 'EUR' }}
                                            </mat-list-option>
                                        }
                                    </mat-selection-list>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </ng-container>
    } @else {
        <div class="no-customers">
            <h3>No customers with unpaid orders found 🎉</h3>
        </div>
    }
}
