import { Component, Signal, effect, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { InvoiceService } from '../../invoice.service';
import { OrdersService } from '../../orders.service';
import { Invoice, OrderWithFirestoreId } from '../../types';

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent {
    constructor(
        private readonly invoiceService: InvoiceService,
        private readonly route: ActivatedRoute,
        private readonly authService: AuthService,
        private readonly orderService: OrdersService,
        private readonly sanitizer: DomSanitizer,
    ) {
        const invoiceId = this.route.snapshot.paramMap.get('id');
        const customerId = this.route.snapshot.paramMap.get('customerid') ?? this.authService.firestoreIdSignal$();
        if (!invoiceId) {
            throw new Error('No invoice id in invoice component route');
        }
        this.invoice$ = toSignal(this.invoiceService.getMonthlyInvoice(invoiceId, customerId));

        // When the invoice changes, load the orders and the PDF.
        effect(async () => {
            const invoice = this.invoice$();
            if (!invoice) return;
            const orders = await this.orderService.getOrdersByFirestoreId(invoice.customer.id, invoice.orderIds);
            // Generate the PDF.
            (await this.invoiceService.getPDFMonthlyInvoice(orders, invoice.totals.discount, false, invoice.functionalId)).getDataUrl(
                url => {
                    this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${url}#toolbar=0&navpanes=0`);
                },
            );
            // Set the orders.
            this.orders$.set(orders);
        });
    }

    /**
     * Represents an observable signal that emits `Invoice` objects or `undefined`.
     */
    invoice$: Signal<Invoice | undefined>;
    orders$ = signal<OrderWithFirestoreId[] | undefined>(undefined);
    pdfURL: SafeResourceUrl | undefined;
}
