import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Observable, lastValueFrom, map, of, switchMap } from 'rxjs';
import { environment } from '../environments/environment';

/**
 * The URL to redirect to after authentication.
 */
const REDIRECT_URL = environment.production ? 'https://smile-art.app' : 'https://smile-art.dev';

/**
 * Service responsible for handling authentication-related functionality.
 */
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    /**
     * Observable representing the authenticated user.
     * @type {Observable<firebase.User | null>}
     */
    user$: Observable<firebase.User | null>;
    /**
     * Observable that emits a boolean value indicating whether the user is an admin or not.
     */
    admin$: Observable<boolean>;
    /** Note: FirestoreID, not functional ID */
    firestoreId$: Observable<string>;

    adminSignal$: Signal<boolean>;
    firestoreIdSignal$: Signal<string>;
    userSignal$: Signal<firebase.User | null>;

    constructor(
        private auth: AngularFireAuth,
        private router: Router,
        private readonly functions: Functions,
        private readonly snackBar: MatSnackBar,
    ) {
        this.user$ = this.auth.user;
        this.admin$ = this.auth.user.pipe(
            switchMap(user => (user ? user.getIdTokenResult() : of(null))),
            map(idTokenResult => idTokenResult?.claims['admin'] === true),
        );
        this.firestoreId$ = this.auth.user.pipe(
            switchMap(user => (user ? user.getIdTokenResult() : of(null))),
            map(idTokenResult => idTokenResult?.claims['firestoreId']),
        );

        this.adminSignal$ = toSignal(this.admin$, { initialValue: false });
        this.firestoreIdSignal$ = toSignal(this.firestoreId$, { initialValue: '' });
        this.userSignal$ = toSignal(this.user$, { initialValue: null });
    }

    // FIXME: NO ANY TYPE
    /**
     * Creates a new customer with the provided input.
     *
     * @param input - The input object containing the customer details.
     * @returns A Promise that resolves when the customer is created and logged in successfully.
     */
    async createCustomer(input: { email: string; password: string; displayName: string; docInput: any }) {
        const callable = httpsCallableData(this.functions, 'createCustomer');
        await lastValueFrom(callable(input));
        await this.login(input.email, input.password);
    }

    /**
     * Logs in a user with the provided email and password.
     * @param email The user's email.
     * @param password The user's password.
     * @returns A Promise that resolves when the user is successfully logged in.
     */
    async login(email: string, password: string) {
        return this.auth.signInWithEmailAndPassword(email, password).then(() => this.router.navigateByUrl('/'));
    }

    /**
     * Signs out the user and navigates to the login page.
     */
    async signOut() {
        await this.auth.signOut();
        this.router.navigate(['/login']);
    }

    /**
     * Checks if a user with the given email already exists.
     * @param email - The email address to check.
     * @returns A promise that resolves to a boolean indicating whether the user already exists.
     */
    async userAlreadyExists(email: string): Promise<boolean> {
        const signInMethods = await this.auth.fetchSignInMethodsForEmail(email);
        return signInMethods.length ? true : false;
    }

    /**
     * Sends a verification email to the current user.
     * @returns A promise that resolves when the email is sent.
     */
    async sendVerificationMail() {
        const user = await this.auth.currentUser;
        return user
            ?.sendEmailVerification({ url: REDIRECT_URL })
            .then(() => this.snackBar.open('Verification email send!', 'close', { duration: 5000 }));
    }

    /**
     * Verifies the user's account using the provided verification code.
     * @param code The verification code to apply.
     * @returns A promise that resolves to the current user after the verification is successful.
     */
    async verifyAccount(code: string) {
        await this.auth.applyActionCode(code);
        return await this.auth.currentUser;
    }

    /**
     * Sends a reset password email to the specified email address.
     * @param email - The email address to send the reset password email to.
     */
    async sendResetPasswordMail(email: string) {
        this.auth.sendPasswordResetEmail(email).then(() => this.snackBar.open('Password reset mail send!', 'close', { duration: 5000 }));
    }

    /**
     * Resets the user's password using a verification code and a new password.
     * @param code The verification code sent to the user's email.
     * @param newPassword The new password to set for the user.
     */
    async resetPassword(code: string, newPassword: string) {
        await this.auth.confirmPasswordReset(code, newPassword);
    }
}
