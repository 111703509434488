import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { FormControl, Validators } from '@angular/forms';
import { DomSanitizer, type SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';

type ErrorResult = { error: string };
type SuccessResult = { designURL: string; patientName: string };
type ShareDesignCode = { order: string; code: string };

@Component({
    selector: 'app-patient-link-design',
    templateUrl: './patient-link-design.component.html',
    styleUrls: ['./patient-link-design.component.scss'],
})
export class PatientLinkDesignComponent {
    constructor(
        private readonly route: ActivatedRoute,
        private readonly sanitizer: DomSanitizer,
        private readonly functions: Functions,
    ) {
        this.route.params.pipe(takeUntilDestroyed()).subscribe(params => {
            this.order = params['hash'];
        });
        // Check local storage for code
        const code = sessionStorage.getItem('shareDesignCode');
        if (code) {
            this.code.setValue(code);
            this.verifyCode();
        }
    }

    error: string | null = null;
    order: string | null = null;
    codeVerified = false;
    designURL?: SafeResourceUrl;
    patientName?: string;

    code = new FormControl<string | null>(null, { validators: [Validators.required] });

    async verifyCode(): Promise<void> {
        if (!this.code.value) {
            this.code.setErrors({ required: true });
        } else {
            if (!this.order) throw new Error('Order not set in patient-link-design.component.ts');
            const callable = httpsCallableData<ShareDesignCode, ErrorResult | SuccessResult>(this.functions, 'verifyShareDesignCode');
            const result = await lastValueFrom(callable({ order: this.order, code: this.code.value }));
            if ('error' in result) {
                this.codeVerified = false;
                if (result.error === 'Invalid code') {
                    this.code.setErrors({ invalidCode: true });
                    return;
                } else {
                    this.error = result.error;
                    return;
                }
            }
            this.codeVerified = true;
            // // Save code to local storage
            sessionStorage.setItem('shareDesignCode', this.code.value);

            this.designURL = this.sanitizer.bypassSecurityTrustResourceUrl(result.designURL);
            this.patientName = result.patientName;
        }
        this.code.markAsTouched();
    }
}
