import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Firestore, doc, updateDoc } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Timestamp } from 'firebase/firestore';
import * as moment from 'moment';
import { PatientService } from '../patient.service';
import type { Customer, Patient, Practice } from '../types';
import { compareFunction } from '../util';

interface PatientForm {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    birthdate: FormControl<moment.Moment>;
    email: FormControl<string>;
    practice: FormControl<Patient['practice']>;
}

@Component({
    selector: 'app-new-patient',
    templateUrl: './new-patient.component.html',
    styleUrls: ['./new-patient.component.scss'],
})
export class NewPatientComponent {
    @Input({ required: true }) customer!: Customer & { firestoreId: string };
    @Output() patientCreated = new EventEmitter<{
        id: string;
        firstName: string;
        lastName: string;
        email?: string;
        practice: Practice;
    }>();

    newPatientForm!: FormGroup<PatientForm>;
    @ViewChild('newPracticeDialog') newPracticeDialog!: TemplateRef<unknown>;

    compareFunction = compareFunction;

    // Geen geboortedatums in de toekomst toelaten.
    maxDate = new Date();
    // Patienten die ouder dan 100 zijn lijkt me onwaarschijnlijk.
    minDate = moment().add(-100, 'years');

    constructor(
        private readonly patientService: PatientService,
        private readonly fb: FormBuilder,
        private readonly firestore: Firestore,
        private readonly dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.newPatientForm = this.fb.nonNullable.group({
            firstName: ['', { validators: [Validators.required, Validators.pattern('^[a-zA-Z ]*$')], nonNullable: true }],
            lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
            birthdate: [moment([2000, 0, 1]), { validators: [Validators.required], nonNullable: true }],
            email: ['', { validators: [Validators.email] }],
            practice: [this.customer.defaultPractice ?? {}, { validators: [Validators.required], nonNullable: true }],
        });
    }

    async onSubmit() {
        if (this.newPatientForm.invalid) {
            return;
        }
        const { birthdate, ...rest } = this.newPatientForm.value;
        if (!birthdate) {
            throw new Error(`Birthdate should be defined at this stage`);
        }
        const patient = {
            ...rest,
            birthdate: Timestamp.fromDate(birthdate.toDate()),
        } as Patient;
        const id = await this.patientService.createPatient(this.customer.firestoreId, patient);
        this.newPatientForm.reset();
        this.patientCreated.emit({ ...patient, id });
    }

    // FIXME: This is a copy of the code in profile.component.ts
    // Move Addpractice dialog to seperate component
    async addPractice() {
        const practice = this.newPracticeForm.value as Practice;
        // Weird, but sanity checks cant hurt.
        if (!practice || !this.customer) return;

        // Get possible current value
        let practices: Practice[] = [];
        if (this.customer.practices?.length) {
            practices = [...this.customer.practices, practice];
        } else {
            practices = [practice];
        }
        this.customer.practices = practices;

        const docRef = doc(this.firestore, `/customers/${this.customer.firestoreId}`);
        await updateDoc(docRef, { practices });
        this.newPatientForm.controls.practice.setValue(practice);
    }

    newPracticeForm = new FormGroup({
        name: new FormControl('', { nonNullable: true, validators: Validators.required }),
        city: new FormControl('', { nonNullable: true, validators: Validators.required }),
        country: new FormControl('', { nonNullable: true }),
        postalcode: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.pattern(/^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i)],
        }),
        housenumber: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')] }),
        street: new FormControl('', { nonNullable: true, validators: Validators.required }),
        street2: new FormControl(''),
    });

    openNewPracticeDialog() {
        const dialogRef = this.dialog.open(this.newPracticeDialog);
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.addPractice();
            }
        });
    }
}
