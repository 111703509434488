import { Injectable } from '@angular/core';
import { collectionData, doc, docData, Firestore, getDoc } from '@angular/fire/firestore';
import { collection } from '@firebase/firestore';
import { Observable } from 'rxjs';
import { Customer } from './types';
import { typeConverter } from './util';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    constructor(private readonly firestore: Firestore) {}

    getCustomers(): Observable<Customer[]> {
        const collectionRef = collection(this.firestore, 'customers').withConverter(typeConverter<Customer>());
        return collectionData(collectionRef, { idField: 'firestoreId' });
    }

    getCustomer(customerFirestoreId: string): Observable<Customer | undefined> {
        const docRef = doc(this.firestore, `/customers/${customerFirestoreId}`).withConverter(typeConverter<Customer>());
        return docData(docRef, { idField: 'firestoreId' });
    }

    async getCustomerPromise(customerFirestoreId: string): Promise<Customer> {
        const docRef = doc(this.firestore, `/customers/${customerFirestoreId}`).withConverter(typeConverter<Customer>());
        // Throw error if customer does not exist
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            throw new Error('Customer does not exist');
        }
        return { ...docSnap.data(), firestoreId: docSnap.id };
    }
}
