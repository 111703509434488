<mat-toolbar>
  <div class="title-box">
    <mat-icon class="title-icon" matListItemIcon>ballot</mat-icon>
    <span class="subtitle">My orders</span>
    <button mat-raised-button routerLink="/new" [disabled]="!(user$ | async)?.emailVerified">Create new order</button>
  </div>
  @if (admin()) {
    <div>
      <button class="table-settings" mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Change settings">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        @for (key of ordersToggle.controls | objectKeys; track $index) {
          <button mat-menu-item attr.aria-label="View {{ key }} orders" (click)="swapOrdersToggle(key)">
            @if (ordersToggle.controls[key].value) {
              <mat-icon class="icon-done">done</mat-icon>
            }
            @if (!ordersToggle.controls[key].value) {
              <mat-icon class="icon-off">highlight_off</mat-icon>
            }
            <span>View {{ key }} orders</span>
          </button>
        }
      </mat-menu>
    </div>
  }
</mat-toolbar>
<mat-divider></mat-divider>
@if (!(user$ | async)?.emailVerified) {
  <mat-card>
    <mat-card-header
      ><mat-card-title class="verification-warning-title"><mat-icon>warning</mat-icon>Email not verified</mat-card-title></mat-card-header
      >
      <mat-card-content>
        <p>It seems your email account is not verified yet. Your email needs to be verified before creating orders.</p>
        @if (mailSend) {
          <b>Verification mail is send! Please check your inbox and possible spam filter. <br /></b>
        }
        @if (tooManyMailRequests) {
          <b>Please wait a moment before sending another verification mail.</b>
        }
      </mat-card-content>
      <mat-card-actions
        ><button mat-raised-button (click)="sendVerificationMail()">
        {{ mailSend ? 'Send verification email again' : 'Send verification email' }}
      </button></mat-card-actions
      >
    </mat-card>
  }
  @if (admin()) {
    <mat-tab-group
      mat-stretch-tabs="false"
      animationDuration="300ms"
      [selectedIndex]="selectedTabIndex$()"
      (selectedIndexChange)="selectedTabIndex$.set($event)"
      >
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">event</mat-icon>
          Calendar
        </ng-template>
        <div class="calendar-buttons">
          <button mat-raised-button mwlCalendarPreviousView [(viewDate)]="viewDate" [view]="'month'">previous</button>
          <button mat-stroked-button mwlCalendarToday [(viewDate)]="viewDate">today</button>
          <button mat-raised-button mwlCalendarNextView [(viewDate)]="viewDate" [view]="'month'">next</button>
          <h2>{{ viewDate | date: 'MMMM' }}</h2>
        </div>
        <ng-template #customCellTemplate let-day="day" let-locale="locale">
          <div>
            @if (day.badgeTotal > 0) {
              <span class="cal-day-badge">{{ day.badgeTotal }}</span>
            }
            <span class="cal-day-number">{{ day.date | calendarDate: 'monthViewDayNumber' : locale }}</span>
          </div>
          @for (event of day.events; track event) {
            <small (click)="calendarEventClicked(event)" class="calendar-event-text"
              >- {{ event.title }}</small
              >
            }
          </ng-template>
          <mwl-calendar-month-view
            [viewDate]="viewDate"
            [events]="calendarEvents"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            [cellTemplate]="customCellTemplate"
            (eventClicked)="calendarEventClicked($event.event)"
          ></mwl-calendar-month-view>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon">list</mat-icon>
            List
          </ng-template>
          <ng-container *ngTemplateOutlet="listView"></ng-container>
        </mat-tab>
      </mat-tab-group>
    } @else {
      <div class="filter-column-menu-button">
        <mat-form-field class="table-filter" floatLabel="always" subscriptSizing="dynamic">
          <mat-label>Filter</mat-label>
          <mat-chip-grid #chipGrid>
            @for (filter of filterChips(); track filter) {
              <mat-chip-row color="primary" highlighted (removed)="removeFilter(filter)">
                {{ filter }}
                <button matChipRemove [attr.aria-label]="'remove ' + filter">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
            <input
              placeholder="e.g. by status 'Production' or name 'John'"
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addFilter($event)"
              />
          </mat-chip-grid>
        </mat-form-field>
        <button class="table-settings columnmenu" mat-icon-button [matMenuTriggerFor]="columMenu" matTooltip="Change table columns">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
      <ng-container>
        <div class="mat-table-container">
          <table mat-table matTableResponsive [dataSource]="dataSource" [trackBy]="trackByFunctionalID" class="mat-elevation-z8" matSort>
            <ng-container matColumnDef="id">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element; dataSource: dataSource">#{{ element.id }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.status | productionSubStatus | async }}</td>
            </ng-container>
            <ng-container matColumnDef="desiredCompletionDate">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Estimated date of delivery</th>
              <td mat-cell *matCellDef="let element; dataSource: dataSource">
                @if (element.deadlineSeverity) {
                  <span
                    [matBadge]="element.deadlineSeverity.daysUntilDeadline"
                    [matBadgeColor]="element.deadlineSeverity.severity === 'warning' ? 'accent' : 'warn'"
                    matBadgeOverlap="false"
                    [matTooltip]="'Deadline ' + element.deadlineSeverity.tooltip"
                    >
                    {{ element.desiredCompletionDate | timestamp }}
                  </span>
                } @else {
                  {{ element.desiredCompletionDate | timestamp }}
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Creation date</th>
              <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.creationDate | timestamp }}</td>
            </ng-container>
            <ng-container matColumnDef="practice">
              <!-- TODO: No sorting for practice yet, need to figure out how to do this with object types. -->
              <th mat-header-cell *matHeaderCellDef>Practice</th>
              <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.practice.name }}</td>
            </ng-container>
            <ng-container matColumnDef="fullname">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Patient</th>
              <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.fullname }}</td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.type }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; dataSource: dataSource" class="orders-actions">
                @if (element.status !== 'Draft') {
                  <button
                    matTooltip="Open chat for order"
                    mat-icon-button
                    (click)="routeToOrder(element.firestoreId, element.customer.firestoreId, element.status, true, $event)"
                    >
                    <mat-icon [matBadge]="element.unreadMessages ? '!' : null" matBadgeColor="accent" aria-hidden="false"
                      >chat</mat-icon
                      >
                    </button>
                  }
                  @if (element.status === 'Draft') {
                    <button
                      matTooltip="Delete draft order"
                      mat-icon-button
                      (click)="openDeleteOrderDialog(element.firestoreId, element.customer.firestoreId, $event)"
                      >
                      <mat-icon>delete</mat-icon>
                    </button>
                  }
                  @if (element.status === 'Send' && element.type === 'Aligner' && refinementDeadlineReached(element)) {
                    <button
                      matTooltip="Request Refinement"
                      mat-icon-button
                      (click)="routeToNewOrder(element.id, $event)"
                      >
                      <mat-icon>arrow_circle_up</mat-icon>
                    </button>
                  }
                </td>
              </ng-container>
              <tr class="mat-row" *matNoDataRow>
                @if (filterChips().length) {
                  <td class="mat-cell" colspan="4" [@fadeInOut]>
                    No orders found matching the filter(s) "{{ filterChips().join(' ') }}"
                  </td>
                }
                @if (!filterChips().length) {
                  <td class="mat-cell" colspan="4" [@fadeInOut]>No orders found</td>
                }
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsWithActions$()"></tr>
              <tr
                class="table-row"
                mat-row
                [@fadeInOut]
                *matRowDef="let row; columns: displayedColumnsWithActions$()"
                    [ngClass]="{
                        'warning:': row.deadlineSeverity?.severity === 'warning',
                        'danger': row.deadlineSeverity?.severity === 'danger'
                    }"
                (click)="routeToOrder(row.firestoreId, row.customer.firestoreId, row.status)"
              ></tr>
            </table>
          </div>
        </ng-container>
      }
      <ng-template #listView>
        <div class="filter-column-menu-button">
          <mat-form-field class="table-filter" floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Filter</mat-label>
            <mat-chip-grid #chipGrid>
              @for (filter of filterChips(); track filter) {
                <mat-chip-row color="primary" highlighted (removed)="removeFilter(filter)">
                  {{ filter }}
                  <button matChipRemove [attr.aria-label]="'remove ' + filter">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              }
              <input
                placeholder="e.g. by status 'Production' or name 'John'"
                [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addFilter($event)"
                />
            </mat-chip-grid>
          </mat-form-field>
          <button class="table-settings columnmenu" mat-icon-button [matMenuTriggerFor]="columMenu" matTooltip="Change table columns">
            <mat-icon>settings</mat-icon>
          </button>
        </div>
        <ng-container>
          <div class="mat-table-container">
            <table mat-table matTableResponsive [dataSource]="dataSource" [trackBy]="trackByFunctionalID" class="mat-elevation-z8" matSort>
              <ng-container matColumnDef="id">
                <th mat-sort-header mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element; dataSource: dataSource">#{{ element.id }}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-sort-header mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.status | productionSubStatus | async }}</td>
              </ng-container>

              <ng-container matColumnDef="desiredCompletionDate">
                <th mat-sort-header mat-header-cell *matHeaderCellDef>Estimated date of delivery</th>
                <td mat-cell *matCellDef="let element; dataSource: dataSource">
                  @if (element.deadlineSeverity) {
                    <span
                      [matBadge]="element.deadlineSeverity.daysUntilDeadline"
                      [matBadgeColor]="element.deadlineSeverity.severity === 'warning' ? 'accent' : 'warn'"
                      matBadgeOverlap="false"
                      [matTooltip]="'Deadline ' + element.deadlineSeverity.tooltip"
                      >
                      {{ element.desiredCompletionDate | timestamp }}
                    </span>
                  } @else {
                    {{ element.desiredCompletionDate | timestamp }}
                  }
                </td>
              </ng-container>

              <ng-container matColumnDef="creationDate">
                <th mat-sort-header mat-header-cell *matHeaderCellDef>Creation date</th>
                <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.creationDate | timestamp }}</td>
              </ng-container>

              <ng-container matColumnDef="practice">
                <!-- TODO: No sorting for practice yet, need to figure out how to do this with object types. -->
                <th mat-header-cell *matHeaderCellDef>Practice</th>
                <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.practice.name }}</td>
              </ng-container>

              <ng-container matColumnDef="fullname">
                <th mat-sort-header mat-header-cell *matHeaderCellDef>Patient</th>
                <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.fullname }}</td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-sort-header mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.type }}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; dataSource: dataSource" class="orders-actions">
                  @if (element.status !== 'Draft') {
                    <button
                      matTooltip="Open chat for order"
                      mat-icon-button
                      (click)="routeToOrder(element.firestoreId, element.customer.firestoreId, element.status, true, $event)"
                      >
                      <mat-icon [matBadge]="element.unreadMessages ? '!' : null" matBadgeColor="accent" aria-hidden="false"
                        >chat</mat-icon
                        >
                      </button>
                    }
                    @if (element.status === 'Draft') {
                      <button
                        matTooltip="Delete draft order"
                        mat-icon-button
                        (click)="openDeleteOrderDialog(element.firestoreId, element.customer.firestoreId, $event)"
                        >
                        <mat-icon>delete</mat-icon>
                      </button>
                    }
                    @if (element.status === 'Send' && element.type === 'Aligner' && refinementDeadlineReached(element)) {
                      <button
                        matTooltip="Request Refinement"
                        mat-icon-button
                        (click)="routeToNewOrder(element.id, $event)"
                        >
                        <mat-icon>arrow_circle_up</mat-icon>
                      </button>
                    }
                  </td>
                </ng-container>

                <tr class="mat-row" *matNoDataRow>
                  @if (filterChips().length) {
                    <td class="mat-cell" colspan="4" [@fadeInOut]>
                      No orders found matching the filter(s) "{{ filterChips().join(' ') }}"
                    </td>
                  }
                  @if (!filterChips().length) {
                    <td class="mat-cell" colspan="4" [@fadeInOut]>No orders found</td>
                  }
                </tr>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsWithActions$()"></tr>
                <tr
                  class="table-row"
                  mat-row
                  [@fadeInOut]
                  *matRowDef="let row; columns: displayedColumnsWithActions$()"
                    [ngClass]="{
                        'warning:': row.deadlineSeverity?.severity === 'warning',
                        'danger': row.deadlineSeverity?.severity === 'danger'
                    }"
                  (click)="routeToOrder(row.firestoreId, row.customer.firestoreId, row.status)"
                ></tr>
              </table>
            </div>
          </ng-container>
        </ng-template>

        <mat-menu #columMenu="matMenu" (click)="$event.stopPropagation()">
          <div (click)="$event.stopPropagation()" class="column-menu">
            @for (column of allColumns; track column) {
              <mat-checkbox
                [formControl]="columnForm.controls[column.key]"
                [checked]="displayedColumnsWithActions$().includes(column.key)"
                (click)="$event.stopPropagation()"
                >
                <mat-label>{{ column.displayName }}</mat-label>
              </mat-checkbox>
            }
          </div>
        </mat-menu>

        <ng-template #deleteDialog let-data>
          <h1 mat-dialog-title>Delete order</h1>
          <p mat-dialog-content>Are you sure you want to delete this draft order?</p>
          <div mat-dialog-actions class="button-wrapper">
            <button mat-button mat-dialog-close>Cancel</button>
            <button color="primary" mat-raised-button [mat-dialog-close]="true">Delete order</button>
          </div>
        </ng-template>
