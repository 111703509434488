<div *ngIf="snapshot | async as snap" class="upload-task">
    <!-- <div *ngIf="percentage | async as pct">
        <progress [value]="pct" max="100"></progress>
        {{ pct | number }}%
    </div> -->
    <!-- <div *ngIf="downloadURL as url" class="results"> -->
    <!-- <img [src]="url" /> -->
    <!-- <a mat-raised-button [href]="url" target="_blank" rel="noopener">Download</a> -->
    <!-- </div> -->

    <!-- <button (click)="task?.pause()" [disabled]="!isActive(snap)">Pause</button>
    <button (click)="task?.cancel()" [disabled]="!isActive(snap)">Cancel</button>
    <button (click)="task?.resume()" [disabled]="!(snap?.state === 'paused')">Resume</button> -->
</div>
