<mat-toolbar>
    <span
        >{{ orderId() ? 'Edit ' + firstFormGroup.controls.type.value : firstFormGroup.controls.type.value }} order
        <span *ngIf="orderId">
            {{ getDraftTitle() }}
            <button *ngIf="status === 'Draft'" matTooltip="Delete order" mat-icon-button class="delete-icon" (click)="openDeleteDialog()">
                <mat-icon>delete</mat-icon>
            </button></span
        ></span
    >
</mat-toolbar>

<mat-stepper #stepper (selectionChange)="saveOrder()">
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>General Information</ng-template>
            <div class="patient-form">
                <div class="subtitle-with-button">
                    <h3>Patient</h3>
                    <button type="button" matTooltip="Add new patient" mat-icon-button (click)="openNewPatientDialog()">
                        <mat-icon color="primary">person_add</mat-icon>
                    </button>
                </div>
                <div class="patient-names">
                    <mat-form-field>
                        <mat-label>Select Patient</mat-label>
                        <input type="text" matInput formControlName="patient" [matAutocomplete]="auto" />
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="patientAutocompleteDisplay">
                            <mat-option *ngFor="let patient of filteredPatients$ | async" [value]="patient">
                                {{ patient.firstName }} {{ patient.lastName }} {{ patient.birthdate | timestamp }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="firstFormGroup.controls.patient.hasError('required')">Patient is required</mat-error>
                        <mat-error *ngIf="firstFormGroup.controls.patient.hasError('patient')"
                            >Please select a value from the list of patients</mat-error
                        >
                    </mat-form-field>
                </div>
            </div>
            <div class="practice-form">
                <div class="subtitle-with-button">
                    <h3>Treating Practice</h3>
                    <button type="button" matTooltip="Add new practice" mat-icon-button (click)="openNewPracticeDialog()">
                        <mat-icon color="primary">add_business</mat-icon>
                    </button>
                </div>
                <div>
                    <mat-form-field appearance="fill" *ngIf="(customer$ | async)?.practices?.length; else noPractices">
                        <mat-label>Treating Practice</mat-label>
                        <mat-select formControlName="practice" [compareWith]="compareFunction">
                            <div *ngIf="firstFormGroup.controls.type.value === 'Refinement' && (admin$ | async); else simpleRefinement">
                                <mat-option *ngFor="let practice of (refinementCustomer$ | async)?.practices" [value]="practice">
                                    {{ practice.name }}
                                </mat-option>
                            </div>
                            <ng-template #simpleRefinement>
                                <mat-option *ngFor="let practice of (customer$ | async)?.practices" [value]="practice">
                                    {{ practice.name }}
                                </mat-option>
                            </ng-template>
                        </mat-select>
                    </mat-form-field>
                    <ng-template #noPractices>Your account has no practices yet, create one to continue</ng-template>
                </div>
                <div *ngIf="firstFormGroup.controls.practice.value as practice">
                    <h4>Shipping address will be:</h4>
                    <p>
                        {{ practice.street }}
                        {{ practice.housenumber }} <br />
                        <span *ngIf="practice.street2">{{ practice.street2 }}<br /></span>
                        {{ practice.postalcode }}
                        {{ practice.city }} <br />
                        {{ practice.country }}
                    </p>
                </div>
            </div>
            <div>
                <h3>Order Type</h3>
                <mat-form-field appearance="fill">
                    <mat-label>Order Type</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of ORDER_TYPES" [value]="type">
                            {{ type }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="firstFormGroup.controls.type.hasError('required')">Order type is required.</mat-error>
                </mat-form-field>
                @if (firstFormGroup.controls.type.value; as orderType) {
                    <h4>
                        Average Delivery time for {{ orderType }} is {{ getDefaultDeadline(orderType) }} business days when the design is
                        finalized.
                    </h4>
                }
            </div>
            <ng-container *ngIf="alignerOrders$ | async as alignerOrders">
                <div *ngIf="alignerOrders.length === 0; else refinementFor" class="no-aligner-orders">
                    <mat-icon>warning</mat-icon>
                    <span>Patient has no previous aligner orders to create a refinement for.</span>
                </div>

                <ng-template #refinementFor>
                    <mat-form-field appearance="fill" *ngIf="firstFormGroup.controls.type.value === 'Refinement'" style="max-width: 512px">
                        <mat-label>Refinement for order</mat-label>
                        <mat-select formControlName="refinementFor">
                            <mat-option *ngFor="let order of alignerOrders" [value]="order.id">
                                #{{ order.id }} - {{ order.patient.firstName }} {{ order.patient.lastName }} - {{ order.type }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="firstFormGroup.controls.refinementFor.hasError('required')"
                            >Referenced aligner order is required for Refinement.</mat-error
                        >
                    </mat-form-field>
                </ng-template>
            </ng-container>
            <h3>Treat Arches</h3>
            <div class="treat-arches-wrapper">
                <p><mat-checkbox formControlName="upperArch">Upper arch</mat-checkbox></p>
                <p><mat-checkbox formControlName="lowerArch">Lower Arch</mat-checkbox></p>
                <p class="arches-error" *ngIf="getArchError()">Either upper and/or lower arch selection is required</p>
            </div>
            <div>
                <button type="button" mat-raised-button color="primary" matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step
        *ngIf="firstFormGroup.controls.type.value === 'Aligner' || firstFormGroup.controls.type.value === 'Refinement'"
        [stepControl]="uploadPictureGroup"
        label="Upload Pictures"
    >
        <div class="upload-header">
            <div>
                <h2>Please upload panoramic x-ray and digital photo's</h2>
                <h3>
                    <span> If possible please use a cheek retractor for clear pictures. </span>
                    <span class="qr-code-message">
                        You can also click on
                        <button
                            mat-icon-button
                            [matTooltip]="(currentDeviceSync$ | async) ? 'Synced with device!' : ''"
                            (click)="showQRcode()"
                        >
                            <mat-icon [ngClass]="(currentDeviceSync$ | async) ? 'synced' : ''">{{
                                (currentDeviceSync$ | async) ? 'mobile_friendly' : 'phone_iphone'
                            }}</mat-icon>
                        </button>
                        to take pictures with your phone.</span
                    >
                    <span *ngIf="firstFormGroup.controls.type.value === 'Refinement'"
                        ><br />For a <b>Refinement</b> order new photo's are optional.</span
                    >
                </h3>
            </div>

            <div>
                <button style="margin-right: 1rem" mat-raised-button matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" matStepperNext>Next</button>
            </div>
        </div>

        <form [formGroup]="uploadPictureGroup" class="pictures-wrapper">
            <uploader
                [orderId]="orderId()"
                [customerId]="customerId"
                [backgroundImage]="'assets/order-pictures/1.png'"
                [formcontrol]="uploadPictureGroup.controls.first"
                [prefix]="1"
            ></uploader>
            <uploader
                [orderId]="orderId()"
                [customerId]="customerId"
                [backgroundImage]="'assets/order-pictures/2.png'"
                [formcontrol]="uploadPictureGroup.controls.second"
                [prefix]="2"
            ></uploader>
            <uploader
                [orderId]="orderId()"
                [customerId]="customerId"
                [backgroundImage]="'assets/order-pictures/3.png'"
                [formcontrol]="uploadPictureGroup.controls.third"
                [prefix]="3"
            ></uploader>
            <uploader
                [orderId]="orderId()"
                [customerId]="customerId"
                [backgroundImage]="'assets/order-pictures/4.png'"
                [formcontrol]="uploadPictureGroup.controls.fourth"
                [prefix]="4"
            ></uploader>
            <uploader
                [orderId]="orderId()"
                [customerId]="customerId"
                [backgroundImage]="'assets/order-pictures/5.png'"
                [formcontrol]="uploadPictureGroup.controls.fifth"
                [prefix]="5"
            ></uploader>
            <uploader
                [orderId]="orderId()"
                [customerId]="customerId"
                [backgroundImage]="'assets/order-pictures/6.png'"
                [formcontrol]="uploadPictureGroup.controls.sixth"
                [prefix]="6"
            ></uploader>
            <uploader
                [orderId]="orderId()"
                [customerId]="customerId"
                [backgroundImage]="'assets/order-pictures/7.png'"
                [formcontrol]="uploadPictureGroup.controls.seventh"
                [prefix]="7"
            ></uploader>
            <uploader
                [orderId]="orderId()"
                [customerId]="customerId"
                [backgroundImage]="'assets/order-pictures/8.png'"
                [formcontrol]="uploadPictureGroup.controls.eigth"
                [prefix]="8"
            ></uploader>
            <uploader
                [orderId]="orderId()"
                [customerId]="customerId"
                [backgroundImage]="'assets/order-pictures/x-ray.png'"
                [formcontrol]="uploadPictureGroup.controls.xray"
                [prefix]="'xray'"
            ></uploader>
        </form>
        <div>
            <button style="margin-right: 1rem" mat-raised-button matStepperPrevious>Back</button>
            <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step [stepControl]="stlGroup" label="Digital scans or PVS impressions">
        <form [formGroup]="stlGroup">
            <div class="stl-step">
                <div>
                    <h2>Please upload digital scans</h2>
                    <uploader
                        class="STL-uploader"
                        [showPicture]="false"
                        [multipleFiles]="true"
                        [orderId]="orderId()"
                        [customerId]="customerId"
                        [formcontrol]="stlGroup.controls.scans"
                        [prefix]="'stl'"
                    ></uploader>
                </div>
                <div>
                    <h2>Or send scans via your intra oral scanner directly to us.</h2>
                    <div class="mail-box-picture">
                        <img
                            src="assets/order-pictures/meditlink.jpg"
                            style="padding-top: 43px"
                            (click)="stlGroup.controls.meditlink.setValue(!stlGroup.controls.meditlink.value)"
                        />
                        <img
                            src="assets/order-pictures/3shape-logo-vector.png"
                            style="padding-bottom: 43px"
                            (click)="stlGroup.controls.meditlink.setValue(!stlGroup.controls.meditlink.value)"
                        />
                        <mat-checkbox formControlName="meditlink">I will send my scans to Smile Art via my scanner.</mat-checkbox>
                    </div>
                </div>
            </div>
        </form>
        <div>
            <button style="margin-right: 1rem" mat-raised-button matStepperPrevious>Back</button>
            <button mat-raised-button color="primary" matStepperNext>Next</button>
        </div>
    </mat-step>
    <mat-step [stepControl]="instructionsGroup" label="Instructions">
        <form [formGroup]="instructionsGroup" class="instructionsForm">
            <div *ngIf="firstFormGroup.controls.type.value === 'Aligner'">
                <mat-radio-group formControlName="approach" class="radio-column">
                    <mat-label>How do we approach?</mat-label>
                    <mat-radio-button value="Aesthetic"
                        >Aesthetic only: We will only align the aesthetic zone and will not change the occlusion</mat-radio-button
                    >
                    <mat-radio-button value="Full treatment"
                        >Full treatment: We will correct the occlusion and align the teeth.</mat-radio-button
                    >
                </mat-radio-group>
                <div class="radio-column">
                    <span>Do you have restorative plans after orthodontic treatment?</span>
                    <mat-radio-group formControlName="restorativePlans">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button value="No">No</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="instructionsGroup.controls.restorativePlans.value === 'Yes'">
                    <mat-divider style="padding-top: 1rem"></mat-divider>
                    <mat-radio-group formControlName="alignment" class="radio-column">
                        <mat-label>How do you want us to align the teeth?</mat-label>
                        <mat-radio-button value="gingiva">Align the teeth based on the gingiva levels</mat-radio-button>
                        <mat-radio-button value="incisal">Align the teeth based on the incisal edges</mat-radio-button>
                    </mat-radio-group>
                    <mat-radio-group formControlName="spaces" class="radio-column">
                        <mat-label>How do we proceed with spaces?</mat-label>
                        <mat-radio-button value="close all">Close all the spaces</mat-radio-button>
                        <mat-radio-button value="specific">Leave space open between the following teeth:</mat-radio-button>
                    </mat-radio-group>
                    <mat-form-field
                        appearance="fill"
                        class="instructions-textarea"
                        *ngIf="instructionsGroup.controls.spaces.value === 'specific'"
                    >
                        <mat-label>Space between:</mat-label>
                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" formControlName="spacesInstructions"></textarea>
                    </mat-form-field>
                    <mat-divider></mat-divider>
                </div>
                <div class="radio-column">
                    <span>Do you want the midline corrected?</span>
                    <mat-radio-group formControlName="midline">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button value="No">No</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="radio-column">
                    <span>Can we do IPR?</span>
                    <mat-radio-group formControlName="IPR">
                        <mat-radio-button value="Yes">Yes, with a maximum of:</mat-radio-button>
                        <mat-radio-button value="No">No</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field
                    class="instructions-textarea"
                    appearance="fill"
                    class="IPR"
                    *ngIf="instructionsGroup.controls.IPR.value === 'Yes'"
                >
                    <mat-label>IPR</mat-label>
                    <input matInput type="number" step=".01" formControlName="IPRInstructions" />
                </mat-form-field>
                <div class="radio-column">
                    <span>Can we place attachments?</span>
                    <mat-radio-group formControlName="attachments" class="instructions-textarea">
                        <mat-radio-button value="Yes">Yes, but avoid these teeth:</mat-radio-button>
                        <mat-radio-button value="No">No</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field
                    class="instructions-textarea"
                    *ngIf="instructionsGroup.controls.attachments.value === 'Yes'"
                    appearance="fill"
                    class="instructions-textarea"
                >
                    <mat-label>Teeth to avoid</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" formControlName="attachmentsInstructions"></textarea>
                </mat-form-field>

                <p>Please indicate if teeth are not to be moved, e.g. implants, bridges:</p>
                <mat-form-field appearance="fill" class="instructions-textarea">
                    <mat-label>Teeth that will not be moved</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" formControlName="noMovement"></textarea>
                </mat-form-field>
                <p>Please indicate if there are teeth that will be extracted:</p>
                <mat-form-field appearance="fill" class="instructions-textarea">
                    <mat-label>Teeth that will be extracted</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="extractions"></textarea>
                </mat-form-field>
            </div>
            <p>Any other instructions?</p>
            <mat-form-field appearance="fill" class="instructions-textarea">
                <mat-label>Further instructions</mat-label>
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" formControlName="furtherInstructions"></textarea>
            </mat-form-field>
            <div>
                <button style="margin-right: 1rem" mat-raised-button matStepperPrevious>Back</button>
                <button mat-raised-button matStepperNext color="primary">Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Submit</ng-template>
        <h2>Order summary</h2>
        <h3>Patient & Practice</h3>
        <ul>
            <li>
                Patient: {{ $any(firstFormGroup.controls.patient.value).firstName }}
                {{ $any(firstFormGroup.controls.patient.value).lastName }}
            </li>
            <li>Treating Practice: {{ firstFormGroup.controls.practice.value?.name }}</li>
            <li>Type: {{ firstFormGroup.controls.type.value }}</li>
            @if (firstFormGroup.controls.type.value; as orderType) {
                <li>
                    Average delivery time for {{ orderType }} is {{ getDefaultDeadline(orderType) }} business days when the design is
                    finalized.
                </li>
            }
        </ul>
        <h3>Instructions</h3>
        <ul>
            <ng-container *ngIf="firstFormGroup.controls.type.value === 'Aligner'">
                <li>Approach: {{ instructionsGroup.controls.approach.value }}</li>
                <li>Restorative plans: {{ instructionsGroup.controls.restorativePlans.value }}</li>
                <ul *ngIf="instructionsGroup.controls.restorativePlans.value === 'Yes'">
                    <li>
                        Alignment:
                        {{
                            instructionsGroup.controls.alignment.value === 'gingiva'
                                ? 'based on the gingiva levels'
                                : 'based on the incisal edges'
                        }}
                    </li>
                    <li *ngIf="instructionsGroup.controls.spaces.value === 'close all'; else spacesInstructions">
                        Spaces: Close all the spaces
                    </li>
                    <ng-template #spacesInstructions>
                        <li>Leave space between: {{ instructionsGroup.controls.spacesInstructions.value }}</li>
                    </ng-template>
                </ul>
                <li>Midline: {{ instructionsGroup.controls.midline.value }}</li>
                <li>IPR: {{ instructionsGroup.controls.IPR.value }}</li>
                <ul *ngIf="instructionsGroup.controls.IPR.value === 'Yes'">
                    <li>With maximum: {{ instructionsGroup.controls.IPRInstructions.value }}</li>
                </ul>
                <li>Attachments: {{ instructionsGroup.controls.attachments.value }}</li>
                <ul *ngIf="instructionsGroup.controls.attachments.value === 'Yes'">
                    <li>But avoid these teeth: {{ instructionsGroup.controls.attachmentsInstructions.value }}</li>
                </ul>
                <li *ngIf="instructionsGroup.controls.noMovement.value">
                    Teeth which will not be moved: {{ instructionsGroup.controls.noMovement.value }}
                </li>
                <li *ngIf="instructionsGroup.controls.extractions.value">
                    Extractions: {{ instructionsGroup.controls.extractions.value }}
                </li>
            </ng-container>
            <li>Further Instructions: {{ instructionsGroup.controls.furtherInstructions.value || 'None' }}</li>
        </ul>
        <div>
            <button style="margin-right: 1rem" mat-raised-button matStepperPrevious>Back</button>
            <button
                mat-raised-button
                matStepperPrevious
                color="primary"
                [disabled]="firstFormGroup.invalid || stlGroup.invalid || instructionsGroup.invalid"
                (click)="finishOrder()"
            >
                Submit
            </button>
        </div>
    </mat-step>
</mat-stepper>

<ng-template #deleteDialog>
    <h1 mat-dialog-title>Delete order</h1>
    <p mat-dialog-content>Are you sure you want to delete this draft order?</p>
    <div mat-dialog-actions class="button-wrapper">
        <button color="primary" mat-raised-button (click)="deleteOrder()">Delete order</button>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</ng-template>

<ng-template #newPracticeDialog>
    <h1 mat-dialog-title>Add new practice</h1>
    <div mat-dialog-content class="new-practice-dialog-content">
        <form [formGroup]="newPracticeForm">
            <mat-form-field appearance="fill">
                <mat-label>Practice name</mat-label>
                <input formControlName="name" matInput />
                <mat-error *ngIf="newPracticeForm.controls.name.hasError('required')">Practice name is required.</mat-error>
            </mat-form-field>
            <div class="address-block">
                <mat-form-field appearance="fill">
                    <mat-label>Street</mat-label>
                    <input formControlName="street" matInput />
                    <mat-error *ngIf="newPracticeForm.controls.street.hasError('required')">Street is required.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Housenumber</mat-label>
                    <input formControlName="housenumber" matInput />
                    <mat-error *ngIf="newPracticeForm.controls.housenumber.hasError('required')">Housenumber is required.</mat-error>
                    <mat-error *ngIf="newPracticeForm.controls.housenumber.hasError('pattern')">Not a valid housenumber.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Street 2 (optional)</mat-label>
                    <input formControlName="street2" matInput />
                </mat-form-field>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Postal code</mat-label>
                        <input formControlName="postalcode" matInput />
                        <mat-error *ngIf="newPracticeForm.controls.postalcode.hasError('required')">Postal code is required.</mat-error>
                        <mat-error *ngIf="newPracticeForm.controls.postalcode.hasError('pattern')">Not a valid postal code.</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>City</mat-label>
                        <input formControlName="city" matInput />
                        <mat-error *ngIf="newPracticeForm.controls.city.hasError('required')">City is required.</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>Country</mat-label>
                    <input formControlName="country" matInput />
                </mat-form-field>
            </div>
        </form>
        <mat-checkbox [formControl]="defaultPracticeControl">Make new practice also the default practice?</mat-checkbox>
    </div>
    <div mat-dialog-actions class="button-wrapper">
        <button mat-button mat-dialog-close>Cancel</button>
        <button color="primary" [disabled]="newPracticeForm.invalid" mat-dialog-close mat-raised-button (click)="addPractice()">Add</button>
    </div>
</ng-template>

<ng-template #qrCodeDialog>
    <h3 mat-dialog-title>QR code device sync</h3>
    <mat-dialog-content>
        <ng-container *ngIf="currentDeviceSync$ | async as deviceSync; else qrCode">
            <b
                >Synced with {{ deviceSync.device.deviceType }} {{ deviceSync.device.device }} at
                {{ deviceSync.lastSync | timestamp: 'HH:mm:ss' }}!</b
            >
        </ng-container>
        <ng-template #qrCode>
            <qrcode *ngIf="!showLoader; else spinner" [qrdata]="getQRlink()" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            <ng-template #spinner>
                <div *ngIf="showLoader" class="qrcode-spinner">
                    <mat-spinner></mat-spinner>
                </div>
            </ng-template>
        </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions class="qrCodeDialog-actions">
        <button mat-raised-button mat-dialog-close>Close</button>
        <button *ngIf="currentDeviceSync$ | async" mat-stroked-button color="primary" (click)="resetSync()">Show QR code</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #qrSyncDialog>
    <h3 mat-dialog-title>Sync with other device</h3>
    <mat-dialog-content>
        <p>Request for device sync received, do you want to sync with this device?</p>
    </mat-dialog-content>
    <mat-dialog-actions class="qrCodeDialog-actions">
        <button mat-raised-button mat-dialog-close>Close</button>
        <button mat-raised-button color="primary" [matDialogClose]="true">Sync</button>
    </mat-dialog-actions>
</ng-template>
