import { isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';

if (!isDevMode()) {
    Sentry.init({
        dsn: 'https://019dae6b98cadb11841df87bd351e7ac@o4505919902318592.ingest.sentry.io/4505919903694848',
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
