import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { AfterContentInit, Component, Injector, computed, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { isEqual, sortBy } from 'lodash';
import { combineLatest, map, startWith, type Observable } from 'rxjs';
import { InvoiceService, getTotalAndDiscountAmount } from '../../invoice.service';
import { OrdersService } from '../../orders.service';
import { TimestampPipe } from '../../timestamp.pipe';
import type { Customer, OrderWithFirestoreId } from '../../types';
import { InvoiceConfirmationDialogComponent } from '../invoice-confirmation-dialog/invoice-confirmation-dialog.component';
import { OrderConfirmationDialogComponent } from '../order-confirmation-dialog/order-confirmation-dialog.component';

type PracticeSelection = { name: string; amountOfMissingMoney: number; orders: OrderWithFirestoreId[] };
export type SparseCustomer = Pick<Customer, 'firestoreId' | 'firstName' | 'lastName' | 'firestoreId'>;
type CustomerSelection = SparseCustomer & {
    amountOfMissingMoney: number;
    practices: PracticeSelection[];
};

@Component({
    selector: 'app-create-invoice',
    standalone: true,
    imports: [
        MatRadioModule,
        ReactiveFormsModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatListModule,
        CurrencyPipe,
        DatePipe,
        TimestampPipe,
        MatTooltipModule,
        AsyncPipe,
        MatButtonModule,
        BrowserAnimationsModule,
    ],
    templateUrl: './create-invoice.component.html',
    styleUrls: ['./create-invoice.component.scss', '../../../styles.scss'],
    animations: [
        trigger('grow', [
            transition('void <=> *', []),
            transition('* <=> *', [style({ height: '{{startHeight}}px', opacity: 0 }), animate('.5s ease')], {
                params: { startHeight: 0 },
            }),
        ]),
    ],
})
export class CreateInvoiceComponent implements AfterContentInit {
    constructor(
        private readonly orderService: OrdersService,
        private readonly invoiceService: InvoiceService,
        private readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar,
        private readonly injector: Injector,
    ) {}

    onOrderSelectionChange(event: MatSelectionListChange) {
        // If the received event turns a selection on, make sure the other selections are part of the group. If other selections are not part of the group, turn them off.
        if (event.options[0]?.selected) {
            const sendDate = event.options[0]?.value.sendDate.toDate();
            const group = getGroupFromDate(sendDate);

            const selectedOrders = this.selectedOrders.value?.filter(order => {
                const orderSendDate = order.sendDate?.toDate();
                if (!orderSendDate) return false;
                const orderGroup = getGroupFromDate(orderSendDate);
                return orderGroup === group;
            });
            // If the selected orders are part of the group, set the selected orders to the group
            if (selectedOrders) {
                this.selectedOrders.setValue(selectedOrders);
            }
        }
    }

    ngAfterContentInit(): void {
        // Effect to automatically select the first customer in the array when customersWithUnpaidOrdersSignal$ has a value
        effect(
            () => {
                const customers = this.customersWithUnpaidOrdersSignal$();
                if (!this.selectedCustomer.value && customers && customers.length > 0) {
                    this.selectedCustomer.setValue(customers[0]!);
                }
            },
            { injector: this.injector },
        );

        // Effect to toggle the group when the selected orders are equal to the orders in the group
        effect(
            () => {
                const orders = this.selectedOrdersSignal$();

                if (!orders?.length) {
                    this.selectedGroup = null;
                    return;
                }
                // Base the group on the selected order
                const date = orders?.[0]?.sendDate?.toDate();
                if (!date) return;

                const group = getGroupFromDate(date);
                if (orders && group) {
                    const ordersInGroup = this.unpaidOrders$()?.find(grouped => grouped.date === group)?.orders;
                    if (ordersInGroup && isEqual(orders, ordersInGroup)) {
                        this.selectedGroup = group;
                    } else {
                        this.selectedGroup = null;
                    }
                }
            },
            { injector: this.injector },
        );
    }

    trackByPracticeNameAndMissingMoney(_index: number, practice: PracticeSelection) {
        return `${practice.name}-${practice.amountOfMissingMoney}`;
    }

    trackByOrderId(_index: number, order: OrderWithFirestoreId): string {
        return order.firestoreId;
    }

    customersWithUnpaidOrders$: Observable<CustomerSelection[]> = this.orderService.getOrdersNotPaidYet().pipe(
        map(orders => {
            const customerMap = new Map<string, CustomerSelection>();
            orders.forEach(order => {
                const customer = customerMap.get(order.customer.firestoreId);
                if (customer) {
                    // Increment the amount of orders for the customer
                    customer.amountOfMissingMoney += order.pricing?.amount ?? 0;
                    const existingPractice = customer.practices.find(p => p.name === order.practice.name);
                    if (existingPractice) {
                        // Increment the amount of missing money for the practice
                        existingPractice.amountOfMissingMoney += order.pricing?.amount ?? 0;
                        existingPractice.orders.push(order);
                        existingPractice.orders = sortBy(existingPractice.orders, 'sendDate');
                    } else {
                        customer.practices.push({
                            name: order.practice.name,
                            amountOfMissingMoney: order.pricing?.amount ?? 0,
                            orders: [order],
                        });
                    }
                } else {
                    const practices = [
                        {
                            name: order.practice.name,
                            amountOfOrders: 1,
                            amountOfMissingMoney: order.pricing?.amount ?? 0,
                            orders: [order],
                        },
                    ];
                    customerMap.set(order.customer.firestoreId, {
                        amountOfMissingMoney: order.pricing?.amount ?? 0,
                        firestoreId: order.customer.firestoreId,
                        firstName: order.customer.firstName,
                        lastName: order.customer.lastName,
                        practices: practices,
                    });
                }
            });
            // Sort the practices by name
            customerMap.forEach(customer => {
                customer.practices = sortBy(customer.practices, practice => practice.name);
            });
            // Sort the customers by last name
            return sortBy(Array.from(customerMap.values()), customer => customer.lastName);
        }),
    );

    customersWithUnpaidOrdersSignal$ = toSignal(this.customersWithUnpaidOrders$);
    practicesSignal$ = computed(() => {
        const selectedCustomer = this.selectedCustomerSignal$();
        if (!selectedCustomer) return null;
        const customers = this.customersWithUnpaidOrdersSignal$();
        if (!customers) return null;
        const customer = customers.find(c => c.firestoreId === selectedCustomer.firestoreId);
        if (!customer) return null;
        return customer.practices;
    });

    selectedCustomer = new FormControl<CustomerSelection | null>(null);
    selectedCustomerSignal$ = toSignal(this.selectedCustomer.valueChanges);
    selectedPractice = new FormControl<PracticeSelection | null>(null);
    selectedPracticeSignal$ = toSignal(this.selectedPractice.valueChanges);
    selectedOrders = new FormControl<OrderWithFirestoreId[]>([]);
    selectedOrdersSignal$ = toSignal(this.selectedOrders.valueChanges);
    discount = new FormControl<number | null>(null);
    discountSignal$ = toSignal(this.discount.valueChanges);

    /**
     * Computed property that returns the unpaid orders grouped by year and month.
     * It filters the orders based on the selected practice and the selected customer.
     * If there is no selected customer or if the selected customer has only one practice,
     * the selected practice will be set to the customer's practice automatically.
     *
     * @returns An object containing the unpaid orders grouped by year and month.
     *          Returns null if there is no selected customer.
     */
    unpaidOrders$ = computed(() => {
        const selectedCustomer: CustomerSelection | null | undefined = this.selectedCustomerSignal$();
        if (!selectedCustomer) return null;
        if (selectedCustomer.practices.length === 1) {
            this.selectedPractice.setValue(selectedCustomer.practices[0]!);
        }
        const selectedPractice = this.selectedPracticeSignal$();
        if (!selectedCustomer || !selectedPractice) return null;

        // Filter the orders based on the selected practice and the selected customer
        const orders = this.customersWithUnpaidOrdersSignal$()
            ?.flatMap(c => c.practices.flatMap(p => p.orders))
            .filter(order => {
                return order.customer.firestoreId === selectedCustomer.firestoreId && order.practice.name === selectedPractice.name;
            });

        // If there are no orders, return null
        if (!orders?.length) return null;
        return groupOrdersByYearMonth(orders);
    });

    invoiceTotalSignal$ = computed(() => {
        const orders = this.selectedOrdersSignal$();
        const parsedDiscount = this.parseDiscount(this.discountSignal$());
        if (!orders?.length) return { total: 0, discountAmount: 0, totalWithDiscount: 0 };
        return getTotalAndDiscountAmount(orders, parsedDiscount);
    });

    invoiceTotal$ = combineLatest([this.selectedOrders.valueChanges, this.discount.valueChanges.pipe(startWith(0))]).pipe(
        map(([orders, _discount]) => {
            const parsedDiscount = this.parseDiscount();
            if (!orders?.length) return { total: 0, discountAmount: 0, totalWithDiscount: 0 };
            return getTotalAndDiscountAmount(orders, parsedDiscount);
        }),
    );

    async previewInvoice() {
        const orders = this.selectedOrders.value;
        if (!orders?.length) return;
        const mappedOrders = orders.map(order => ({ ...order, firestoreId: order.id! }));
        (await this.invoiceService.getPDFMonthlyInvoice(mappedOrders, this.parseDiscount())).open();
    }

    /**
     * Represents the selected group/month for invoice management.
     * If a group/month is selected, all orders in that group will be selected.
     */
    selectedGroup: string | null = null;
    async toggleAllUnpaidOrders(event: MatCheckboxChange, orders: OrderWithFirestoreId[], group: string) {
        if (event.checked) {
            this.selectedOrders.setValue(orders);
        } else {
            this.selectedOrders.setValue([]);
        }
        this.selectedGroup = event.checked ? group : null;
    }

    /**
     * Parses the discount value and returns it as a decimal number.
     * If the discount value is null, returns null.
     * @returns The parsed discount value as a decimal number or null.
     */
    private parseDiscount(discount: number | null = this.discount.value): number | undefined {
        if (discount == null) return undefined;
        return discount / 100;
    }

    openInvoiceConfirmationDialog() {
        const orders = this.selectedOrders.value;
        if (!orders?.length) return;
        const totals = getTotalAndDiscountAmount(orders, this.parseDiscount());
        const dialogRef = this.dialog.open(InvoiceConfirmationDialogComponent, {
            data: { customer: this.selectedCustomer.value, totals },
            minWidth: '400px',
        });
        dialogRef.afterClosed().subscribe(async (result: { email: string } | undefined) => {
            if (result) {
                await this.invoiceService.createMonthlyInvoice(orders, totals, result.email);
                this.snackbar.open('Invoice send!', 'close', { duration: 5000 });
                // Reset form values
                this.discount.reset();
                this.selectedOrders.reset();

                // If after the invoice is created, the selected practice has no more orders, reset the selected practice
                const selectedPractice = this.selectedPracticeSignal$();
                const unpaidOrders = this.unpaidOrders$();
                if (selectedPractice && !unpaidOrders?.length) {
                    this.selectedPractice.reset();
                }
            }
        });
    }

    async markOrdersAsPaid() {
        const orders = this.selectedOrders.value;
        if (!orders?.length) return;
        const dialogRef = this.dialog.open(OrderConfirmationDialogComponent, { data: { orders } });

        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.orderService.markOrdersAsPaid(orders, 'manual_mark_as_paid');
                this.snackbar.open('Orders marked as paid!', 'close', { duration: 5000 });
            }
        });
    }
}

/**
 * Groups the given orders by year and month.
 *
 * @param orders - An array of orders with Firestore IDs.
 * @returns An array of objects containing the grouped orders and their corresponding date.
 */
function groupOrdersByYearMonth(orders: OrderWithFirestoreId[]): { date: string; orders: OrderWithFirestoreId[] }[] {
    // Group orders by year and month
    const grouped = orders.reduce(
        (acc, order) => {
            const date = order.sendDate?.toDate() || new Date();
            const key = getGroupFromDate(date);
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key]?.push(order);
            return acc;
        },
        {} as Record<string, OrderWithFirestoreId[]>,
    );

    // Convert the grouped object into an array and sort by date in descending order
    return Object.keys(grouped)
        .map(key => ({ date: key, orders: grouped[key] || [] }))
        .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
        });
}

function getGroupFromDate(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}`;
}
