@if (patient$ | async; as patient) {
  <mat-toolbar>
    <div class="title-box">
      <mat-icon class="title-icon" matListItemIcon>person</mat-icon>
      <span class="subtitle">Patient</span>
    </div>
  </mat-toolbar>
  <mat-divider></mat-divider>
  <mat-card class="patient-card">
    <mat-card-header>
      <mat-card-title>
        {{ patient.firstName }} {{ patient.lastName }}
        <button mat-icon-button (click)="toggleEditForm()">
          <mat-icon color="primary">{{ editing ? 'done' : 'edit' }}</mat-icon>
        </button>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="editPatientForm">
        <mat-form-field appearance="fill" class="birth-date">
          <mat-label>Patient Birthdate</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="birthdate"
            [max]="maxDate"
            [min]="minDate"
            [readonly]="!editing"
            />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker startView="multi-year"></mat-datepicker>
          <!-- TODO: Error messages when the date is out of range of the maxDate or minDate -->
          @if (editPatientForm.controls.birthdate.hasError('required')) {
            <mat-error>Patient birthdate is required.</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Patient e-mail</mat-label>
          <input matInput placeholder="e-mail" formControlName="email" [readonly]="!editing" />
          @if (editPatientForm.controls.email.hasError('email')) {
            <mat-error
              >"{{ editPatientForm.controls.email.value }}" is not a valid email address.</mat-error
              >
            }
          </mat-form-field>
          @if (customer$ | async; as customer) {
            <div>
              <div class="subtitle-with-button">
                <h2>Practice</h2>
                @if (editing) {
                  <button matTooltip="Add new practice" mat-icon-button (click)="openNewPracticeDialog()">
                    <mat-icon color="primary">add_business</mat-icon>
                  </button>
                }
              </div>
              <div class="practice-form">
                @if (customer.practices?.length) {
                  <mat-form-field
                    appearance="fill"
                    class="practice-field"
                    [ngClass]="editing ? 'editing' : ''"
                    >
                    <mat-label>Treating Practice</mat-label>
                    <mat-select formControlName="practice" [compareWith]="compareFunction">
                      @for (practice of customer?.practices; track practice) {
                        <mat-option [value]="practice" [disabled]="!editing">
                          {{ practice.name }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                } @else {
                  Your account has no practices yet, create one to continue
                }
                @if (editPatientForm.controls.practice.value; as currentPractice) {
                  <div>
                    <p>
                      {{ currentPractice.street }}
                      {{ currentPractice.housenumber }} <br />
                      @if (currentPractice.street2) {
                        <span>{{ currentPractice.street2 }}<br /></span>
                      }
                      {{ currentPractice.postalcode }}
                      {{ currentPractice.city }} <br />
                      {{ currentPractice.country }}
                    </p>
                  </div>
                }
                <!-- New Practice Dialog -->
                <ng-template #newPracticeDialog>
                  <h1 mat-dialog-title>Add new practice</h1>
                  <div mat-dialog-content class="new-practice-dialog-content">
                    <form [formGroup]="newPracticeForm">
                      <mat-form-field appearance="fill">
                        <mat-label>Practice name</mat-label>
                        <input formControlName="name" matInput />
                        @if (newPracticeForm.controls.name.hasError('required')) {
                          <mat-error
                            >Practice name is required.</mat-error
                            >
                          }
                        </mat-form-field>
                        <div class="address-block">
                          <mat-form-field appearance="fill">
                            <mat-label>Street</mat-label>
                            <input formControlName="street" matInput />
                            @if (newPracticeForm.controls.street.hasError('required')) {
                              <mat-error
                                >Street is required.</mat-error
                                >
                              }
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                              <mat-label>Housenumber</mat-label>
                              <input formControlName="housenumber" matInput />
                              @if (newPracticeForm.controls.housenumber.hasError('required')) {
                                <mat-error
                                  >Housenumber is required.</mat-error
                                  >
                                }
                                @if (newPracticeForm.controls.housenumber.hasError('pattern')) {
                                  <mat-error
                                    >Not a valid housenumber.</mat-error
                                    >
                                  }
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                  <mat-label>Street 2 (optional)</mat-label>
                                  <input formControlName="street2" matInput />
                                </mat-form-field>
                                <div>
                                  <mat-form-field appearance="fill">
                                    <mat-label>Postal code</mat-label>
                                    <input formControlName="postalcode" matInput />
                                    @if (newPracticeForm.controls.postalcode.hasError('required')) {
                                      <mat-error
                                        >Postal code is required.</mat-error
                                        >
                                      }
                                      @if (newPracticeForm.controls.postalcode.hasError('pattern')) {
                                        <mat-error
                                          >Not a valid postal code.</mat-error
                                          >
                                        }
                                      </mat-form-field>
                                      <mat-form-field appearance="fill">
                                        <mat-label>City</mat-label>
                                        <input formControlName="city" matInput />
                                        @if (newPracticeForm.controls.city.hasError('required')) {
                                          <mat-error
                                            >City is required.</mat-error
                                            >
                                          }
                                        </mat-form-field>
                                      </div>
                                      <mat-form-field appearance="fill">
                                        <mat-label>Country</mat-label>
                                        <input formControlName="country" matInput />
                                      </mat-form-field>
                                    </div>
                                  </form>
                                </div>
                                <div mat-dialog-actions class="button-wrapper">
                                  <button mat-button mat-dialog-close>Cancel</button>
                                  <button
                                    color="primary"
                                    [disabled]="newPracticeForm.invalid"
                                    [mat-dialog-close]="customer"
                                    mat-raised-button
                                    >
                                    Add
                                  </button>
                                </div>
                              </ng-template>
                            </div>
                            <!-- Orders -->
                            <div class="subtitle-with-button">
                              <h2>Orders / Treatments</h2>
                              @if (this.authFirestoreId() === this.customerFirestoreId()) {
                                <button
                                  mat-stroked-button
                                  color="primary"
                                [queryParams]="{
                                    patientFirstname: patient.firstName,
                                    patientLastname: patient.lastName,
                                    practice: patient.practice.name
                                }"
                                  routerLink="/new"
                                  >
                                  Create new order
                                </button>
                              }
                            </div>
                            <mat-list class="patient-orders">
                              @for (order of patientOrders$ | async; track order) {
                                <mat-list-item
                            [routerLink]="
                                order.status === 'Draft'
                                    ? ['/orders', order.firestoreId, 'draft']
                                    : admin()
                                      ? ['/customers', customerFirestoreId(), 'orders', order.firestoreId]
                                      : ['/orders', order.firestoreId]
                            "
                                  >
                                  <mat-icon matListItemIcon>chevron_right</mat-icon>
                                  <div matListItemTitle>{{ order.type }} / {{ order.creationDate | timestamp }}</div>
                                  <div matListItemLine>{{ order.status | productionSubStatus | async }}</div>
                                  <div matListItemMeta>
                                    @if (order.status === 'Send' && order.type === 'Aligner' && refinementDeadlineReached(order)) {
                                      <button
                                        style="margin-top: 0.5rem"
                                        matTooltip="Request a Refinement for this order"
                                        mat-stroked-button
                                        color="primary"
                                        (click)="routeToNewOrder(order.id, $event)"
                                        >
                                        <mat-icon>arrow_circle_up</mat-icon>
                                        Request Refinement
                                      </button>
                                    }
                                    @if (order.status === 'Draft') {
                                      <button
                                        style="margin-top: 0.5rem"
                                        matTooltip="Delete order"
                                        mat-stroked-button
                                        color="primary"
                                        (click)="openDeleteOrderDialog($event, order)"
                                        >
                                        <mat-icon>delete</mat-icon>
                                        Delete draft
                                      </button>
                                    }
                                  </div>
                                </mat-list-item>
                              }
                            </mat-list>
                          </div>
                        }
                      </form>
                    </mat-card-content>
                  </mat-card>
                }

                <ng-template #deleteOrderDialog let-data>
                  <h1 mat-dialog-title>Delete order</h1>
                  <p mat-dialog-content>Are you sure you want to delete this draft order?</p>
                  <div mat-dialog-actions class="button-wrapper">
                    <button mat-button mat-dialog-close>Cancel</button>
                    <button color="primary" mat-raised-button [mat-dialog-close]="true">Delete order</button>
                  </div>
                </ng-template>
