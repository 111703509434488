<div class="wrapper">
    <div class="monthly-invoices">
        <mat-card [appearance]="'raised'">
            <mat-card-header>
                <mat-card-title>Monthly invoices</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field class="full-width">
                    <mat-label>Filters</mat-label>
                    <mat-chip-grid #chipGrid>
                        <mat-chip-row color="primary" highlighted *ngFor="let filter of filterChips()" (removed)="removeFilter(filter)">
                            {{ filter }}
                            <button matChipRemove [attr.aria-label]="'remove ' + filter">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                        <input
                            placeholder="New filter, e.g. '2021', 'paid', 'not-paid'.."
                            [matChipInputFor]="chipGrid"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addFilter($event)"
                        />
                    </mat-chip-grid>
                </mat-form-field>
                <mat-list>
                    <div
                        *ngFor="let invoice of monthlyInvoices$ | async"
                        class="invoice-list-item"
                        routerLink="/customers/{{ invoice.customer.id }}/invoices/{{ invoice.firestoreId }}"
                    >
                        <mat-list-item>
                            <mat-icon
                                matTooltip="{{ invoice.paidAt ? 'Invoice paid' : 'Invoice not paid yet' }}"
                                matListItemIcon
                                [ngClass]="invoice.paidAt ? 'paid' : ''"
                                >receipt_long</mat-icon
                            >
                            <div matListItemTitle>#{{ invoice.functionalId }}</div>
                            <div matListItemLine>{{ invoice.practice.name }}</div>
                            <div matListItemLine>
                                <span>{{ getFullMonthName(invoice.yearMonth) }} | </span>
                                {{ invoice.totals.totalWithDiscount ?? invoice.totals.total | currency: 'EUR' }}
                            </div>
                        </mat-list-item>
                        <button
                            *ngIf="invoice.paidAt; else notPaid"
                            mat-icon-button
                            (click)="markInvoiceUnpaid(invoice, $event)"
                            matTooltip="Mark invoice as not paid"
                        >
                            <mat-icon class="icon-mark-unpaid">money_off</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="View pdf invoice" (click)="viewPdfInvoice(invoice, $event)">
                            <mat-icon>visibility</mat-icon>
                        </button>
                        <ng-template #notPaid>
                            <button
                                mat-icon-button
                                (click)="markInvoicePaid(invoice, $event)"
                                class="reverse-colors"
                                matTooltip="Mark invoice as paid"
                            >
                                <mat-icon class="icon-mark-paid">price_check</mat-icon>
                            </button>
                        </ng-template>
                    </div>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>

    <div *ngIf="customersWithUnpaidOrders$ | async as customers" class="unpaid-orders">
        <mat-card [appearance]="'raised'">
            <mat-card-header>
                <mat-card-title>
                    <span *ngIf="customers.length; else noOpenOrders">
                        {{ customers.length }} {{ customers.length > 1 ? 'Customers' : 'Customer' }} with orders not on a monthly invoice
                    </span>
                    <ng-template #noOpenOrders>
                        <span>No customers found with open orders! 🎉</span>
                    </ng-template></mat-card-title
                >
            </mat-card-header>
            <mat-card-content *ngIf="customers.length">
                <mat-form-field class="full-width">
                    <mat-label>Select Customer</mat-label>
                    <input type="text" matInput [formControl]="selectedCustomer" [matAutocomplete]="auto" />
                    <mat-autocomplete
                        #auto="matAutocomplete"
                        [displayWith]="customerAutocompleteDisplay"
                        (optionSelected)="selectedCustomerSignal.set($event)"
                    >
                        <mat-option *ngFor="let customer of customersWithUnpaidOrders$ | async" [value]="customer">
                            {{ customerAutocompleteDisplay(customer) }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-selection-list
                    *ngIf="selectedCustomer.value?.practices?.length !== 1"
                    [formControl]="selectedPractice"
                    [multiple]="false"
                    class="select-practice"
                >
                    <mat-label [ngClass]="!selectedCustomer.value ? 'hide' : ''">Select Practice</mat-label>
                    <mat-list-option *ngFor="let practice of selectedCustomer.value?.practices" [value]="practice">
                        {{ practice.name }} - {{ practice.amountOfOrders }}
                    </mat-list-option>
                </mat-selection-list>

                <div *ngIf="unpaidOrders$() as unpaidOrders">
                    <div class="orders-header">
                        <div>
                            <h2>
                                Orders not on a monthly invoice
                                <button
                                    matTooltip="Preview concept invoice"
                                    [disabled]="!selectedOrders.value?.length"
                                    mat-icon-button
                                    color="primary"
                                    (click)="previewInvoice()"
                                >
                                    <mat-icon>visibility</mat-icon>
                                </button>
                                <button
                                    [disabled]="!selectedOrders.value?.length"
                                    matTooltip="Process monthly invoice"
                                    mat-icon-button
                                    color="accent"
                                    class="process-invoice-button"
                                    (click)="openInvoiceConfirmationDialog()"
                                >
                                    <mat-icon>done</mat-icon>
                                </button>
                                <button
                                    [disabled]="!selectedOrders.value?.length"
                                    matTooltip="Mark orders as already paid"
                                    mat-icon-button
                                    color="accent"
                                    class="process-invoice-button"
                                    (click)="markOrdersAsPaid()"
                                >
                                    <mat-icon>price_check</mat-icon>
                                </button>
                            </h2>
                        </div>
                    </div>
                    <div class="discount-block">
                        <mat-form-field>
                            <mat-label>Apply discount</mat-label>
                            <span matTextSuffix>%</span>
                            <input type="number" matInput [formControl]="discount" />
                        </mat-form-field>
                        <p *ngIf="invoiceTotal$ | async as bedragen">
                            Invoice total: {{ bedragen.total | currency: 'EUR' }}
                            <span *ngIf="bedragen.totalWithDiscount"
                                >- {{ bedragen.discountAmount | currency: 'EUR' }} =
                                {{ bedragen.totalWithDiscount | currency: 'EUR' }}</span
                            >
                        </p>
                    </div>
                    <div *ngFor="let orderGroup of unpaidOrders">
                        <div class="monthly-header">
                            <h3>
                                {{ orderGroup.date | date: 'MMMM yyyy' }}
                            </h3>
                            <mat-checkbox
                                #selectAll
                                labelPosition="before"
                                [checked]="selectedGroup === orderGroup.date"
                                (change)="toggleAllUnpaidOrders($event, orderGroup.orders, orderGroup.date)"
                                >{{ selectAll.checked ? 'Deselect all' : 'Select all' }}</mat-checkbox
                            >
                        </div>

                        <mat-selection-list [formControl]="selectedOrders" #selectionList>
                            <mat-list-option *ngFor="let order of orderGroup.orders" [value]="order">
                                {{ order.sendDate | timestamp }} | {{ order.type }} | {{ order.patient.firstName }}
                                {{ order.patient.lastName }} | {{ order.pricing?.amount | currency: 'EUR' }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #markInvoiceDialog let-data>
    <h2 mat-dialog-title>Mark invoice as {{ data.mark }}</h2>
    <mat-dialog-content>
        <p>
            Are you sure you want to mark this invoice as <b>{{ data.mark }}</b
            >?
        </p>
        <span>
            #{{ data.invoice.functionalId }} <br />
            Customer: {{ data.invoice.customer.firstName }} {{ data.invoice.customer.lastName }} <br />
            Practice: {{ data.invoice.practice.name }} <br />
            @if (data.invoice.totals.totalWithDiscount) {
                Total amount with discount:{{ data.invoice.totals.total | currency: 'EUR' }} -
                {{ data.invoice.totals.discountAmount | currency: 'EUR' }} =
                {{ data.invoice.totals.totalWithDiscount | currency: 'EUR' }}
            } @else {
                Amount: {{ data.invoice.totals.total | currency: 'EUR' }}
            }
        </span>
    </mat-dialog-content>
    <mat-dialog-actions class="buttons-between">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
    </mat-dialog-actions>
</ng-template>
