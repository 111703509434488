<mat-drawer-container>
    <div class="order-wrapper">
        <div *ngIf="item$ | async as item" class="order-information">
            <mat-card class="order-card" appearance="outlined">
                <mat-card-header>
                    <div *ngIf="avatarURL" class="header-avatar" mat-card-avatar>
                        <img mat-card-avatar [ngSrc]="avatarURL" fill priority />
                    </div>
                    <mat-card-subtitle>#{{ item.id }} for {{ item.practice.name }}</mat-card-subtitle>
                    <mat-card-title
                        class="patient-name"
                        [routerLink]="
                            admin ? ['/customers', item.customer.firestoreId, 'patients', item.patient.id] : ['/patients', item.patient.id]
                        "
                        >{{ item.patient.firstName }} {{ item.patient.lastName }}</mat-card-title
                    >
                </mat-card-header>
                <mat-card-content>
                    <app-timeline [admin]="admin" [currentStatus]="item.status" [orderType]="item.type"></app-timeline>
                    <mat-list>
                        <mat-list-item *ngIf="item.desiredCompletionDate">
                            <span matListItemTitle>Estimated delivery date: {{ item.desiredCompletionDate | timestamp }}</span>
                            <span *ngIf="item.creationDate" matListItemLine>Created on: {{ item.creationDate | timestamp }}</span>
                        </mat-list-item>
                        <mat-list-item *ngIf="item.trackAndTrace">
                            <a class="track-and-trace" [href]="item.trackAndTrace" target="_blank">
                                <mat-icon color="primary">local_shipping</mat-icon><u>Track & Trace link</u></a
                            >
                        </mat-list-item>
                        <mat-list-item *ngIf="item.manualDelivery">
                            <div style="display: flex; flex-direction: row; gap: 0.5rem">
                                <mat-icon color="primary">local_shipping</mat-icon
                                ><span class="mdc-list-item__secondary-text"
                                    >Order was marked for manual delivery at <br />
                                    {{ item.manualDelivery.timestamp | timestamp }}</span
                                >
                            </div>
                        </mat-list-item>
                        <div *ngIf="item.pricing">
                            <mat-list-item>
                                <span matListItemTitle>Pricing</span>
                                <span *ngIf="item.type === 'Aligner'; else simplePricing" matListItemLine
                                    >{{ item.pricing.quantity }} aligner(s) for
                                    {{ (item.pricing.amount ? item.pricing.amount : 0) | currency: 'EUR' }}
                                </span>
                                <ng-template #simplePricing>
                                    <span matListItemLine
                                        >Pricing: {{ (item.pricing.amount ? item.pricing.amount : 0) | currency: 'EUR' }}
                                    </span>
                                </ng-template>
                            </mat-list-item>
                        </div>
                        <mat-list-item>
                            <span matListItemTitle>Type</span>
                            <span matListItemLine
                                >{{ item.type }}
                                <span *ngIf="item.type === 'Refinement'" (click)="navigateByFunctionalId(item.refinementFor)"
                                    >for <span class="small-functional-id-link">#{{ item.refinementFor }}</span>
                                </span>
                            </span>
                        </mat-list-item>
                        <mat-list-item>
                            <span matListItemTitle>Upper / Lower Arch</span>
                            <span matListItemLine>{{ getUpperLowerArch(item) }}</span>
                        </mat-list-item>
                        <ng-container *ngIf="item.type === 'Aligner' || item.type === 'Refinement'">
                            <mat-divider></mat-divider>
                            <mat-list-item>
                                <span matListItemTitle>Approach</span>
                                <span matListItemLine>{{ item.instructions.approach }}</span>
                            </mat-list-item>
                            <mat-list-item *ngIf="item.instructions.restorativePlans">
                                <span matListItemTitle>Restorative plans</span>
                                <span matListItemLine>{{ item.instructions.restorativePlans }}</span>
                            </mat-list-item>
                            <div *ngIf="item.instructions.restorativePlans === 'Yes'">
                                <mat-list-item>
                                    <span matListItemTitle>Teeth alignment</span>
                                    <span matListItemLine *ngIf="item.instructions.alignment === 'gingiva'"
                                        >Align the teeth based on the gingiva levels</span
                                    >
                                    <span matListItemLine *ngIf="item.instructions.alignment === 'incisal'"
                                        >Align the teeth based on the incisal edges</span
                                    >
                                </mat-list-item>
                                <mat-list-item>
                                    <span matListItemTitle>Spaces</span>
                                    <span matListItemLine *ngIf="item.instructions.spaces === 'close all'">Close all the spaces</span>
                                    <span matListItemLine *ngIf="item.instructions.spaces === 'specific'"
                                        >Leave space open between the following teeth:
                                        <span *ngIf="item.instructions.spaces === 'specific'">{{
                                            item.instructions.spacesInstructions
                                        }}</span></span
                                    >
                                </mat-list-item>
                            </div>
                            <mat-list-item>
                                <span matListItemTitle>Midline correction</span>
                                <span matListItemLine>{{ item.instructions.midline }}</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span matListItemTitle>IPR</span>
                                <span matListItemLine
                                    >{{ item.instructions.IPR }}
                                    <span *ngIf="item.instructions.IPR === 'Yes'"
                                        >- maximum: {{ item.instructions.IPRInstructions }}</span
                                    ></span
                                >
                            </mat-list-item>
                            <mat-list-item *ngIf="item.instructions.attachments">
                                <span matListItemTitle>Attachments</span>
                                <span matListItemLine>{{ item.instructions.attachments }}</span>
                            </mat-list-item>
                            <div
                                class="instructions-block"
                                *ngIf="item.instructions.attachments === 'Yes' && item.instructions.attachmentsInstructions"
                            >
                                <!--FIXME: Klopt dit wel? We tonen nu de header zonder te wete of attachmentinstructions aanwezig is-->
                                <span matListItemTitle>Teeth to avoid for attachments</span>
                                <p>{{ item.instructions.attachmentsInstructions }}</p>
                            </div>
                            <div class="instructions-block" *ngIf="item.instructions.noMovement">
                                <span matListItemTitle>Do not move these teeth:</span>
                                <p>{{ item.instructions.noMovement }}</p>
                            </div>
                            <div class="instructions-block" *ngIf="item.instructions.extractions">
                                <span matListItemTitle>Teeth that will be extracted:</span>
                                <p>{{ item.instructions.extractions }}</p>
                            </div>
                        </ng-container>
                        <div
                            class="instructions-block"
                            *ngIf="item.instructions.chiefInstructions || item.instructions.furtherInstructions"
                        >
                            <span matListItemTitle>Further Instructions</span>
                            <p>
                                {{ item.instructions.chiefInstructions || item.instructions.furtherInstructions }}
                            </p>
                        </div>
                        <ng-container *ngIf="item.digitalScans?.pvsImpressions || item.digitalScans?.meditlink">
                            <mat-divider></mat-divider>
                            <mat-list-item *ngIf="item.digitalScans?.pvsImpressions">
                                <mat-icon matListItemIcon>local_shipping</mat-icon>
                                <span matListItemLine>PVS impressions will be send by mail</span>
                            </mat-list-item>
                            <mat-list-item *ngIf="item.digitalScans?.meditlink">
                                <mat-icon matListItemIcon>link</mat-icon>
                                <span matListItemTitle>Intra-oral scanner</span>
                                <span matListItemLine>Scans will be send through Medit link / 3shape </span>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                    <mat-divider></mat-divider>
                    <div *ngIf="item.refinements">
                        <mat-list>
                            <mat-list-item>
                                <span matListItemTitle>Refinements ({{ item.refinements.length }})</span>
                                <span matListItemLine
                                    ><span
                                        class="small-functional-id-link spacing"
                                        *ngFor="let refinement of item.refinements"
                                        (click)="navigateByFunctionalId(refinement)"
                                        >#{{ refinement }}</span
                                    >
                                </span>
                            </mat-list-item>
                            <div matListItemTitle></div>
                        </mat-list>
                    </div>
                </mat-card-content>
                <mat-card-actions class="order-actions">
                    <div class="first-action-group">
                        <button
                            *ngIf="(admin || item.status === 'Ready for review') && item.status !== 'Send'"
                            mat-raised-button
                            color="primary"
                            (click)="openUpdateOrderDialog()"
                        >
                            {{ admin ? 'Update order status' : 'Accept order' }}
                        </button>
                        <button
                            *ngIf="item.status === 'Send' && item.type === 'Aligner' && refinementDeadlineReached(item)"
                            mat-raised-button
                            color="primary"
                            matTooltip="Create a new refinement order for this case"
                            [queryParams]="{ refinementFor: item.id }"
                            routerLink="/new"
                        >
                            <mat-icon>arrow_circle_up</mat-icon>Request refinement
                        </button>
                        <button *ngIf="canViewInvoice(item)" mat-raised-button (click)="openPdfViewer()">
                            <mat-icon>receipt</mat-icon>View Invoice
                        </button>
                    </div>
                    <button matTooltip="Admin menu" *ngIf="admin" mat-icon-button color="primary" [matMenuTriggerFor]="adminMenu">
                        <mat-icon>admin_panel_settings</mat-icon>
                    </button>
                    <mat-menu #adminMenu>
                        <button *ngIf="admin && item.pricing" mat-menu-item (click)="openOverridePriceDialog()">
                            <mat-icon>euro</mat-icon>Change pricing
                        </button>
                        <button *ngIf="admin" mat-menu-item (click)="openNotesDialog()"><mat-icon>description</mat-icon>Admin notes</button>
                        <button mat-menu-item *ngIf="admin && item.shareDesignCode" (click)="printQRforPatient(item)">
                            <mat-icon>qr_code</mat-icon>Print QR for patient
                        </button>
                    </mat-menu>
                </mat-card-actions>
                <mat-card-footer> </mat-card-footer>
            </mat-card>
            <mat-card class="order-card with-title-buttons" *ngIf="item.designURL">
                <mat-card-header>
                    <mat-card-title>Design</mat-card-title>
                    <div class="design-buttons">
                        <!-- Only show toggle button when not reviewing the design. -->
                        <button
                            *ngIf="modelURL && item.status !== 'Ready for review'"
                            color="primary"
                            [matTooltip]="designVisible ? 'Close design' : 'View design'"
                            (click)="toggleViewDesign()"
                            mat-icon-button
                        >
                            <mat-icon>{{ designVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <button matTooltip="Edit Design URL" *ngIf="modelURL && admin && !editURL" (click)="editURL = true" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button
                            matTooltip="Save new Design URL"
                            *ngIf="modelURL && admin && editURL"
                            [disabled]="designUrl.invalid"
                            (click)="saveEditedURL()"
                            mat-icon-button
                        >
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                </mat-card-header>
                <mat-card-content>
                    <mat-form-field *ngIf="modelURL && admin && editURL" appearance="fill" class="design-url-form-field">
                        <mat-label>Design URL</mat-label>
                        <input matInput placeholder="Design URL" [formControl]="designUrl" required />
                        <mat-error *ngIf="designUrl.hasError('required')">Design URL is required</mat-error>
                        <mat-error *ngIf="designUrl.hasError('pattern')">Input is not a valid URL</mat-error>
                    </mat-form-field>
                    <div class="model-viewer" [ngClass]="{ show: designVisible || item.status === 'Ready for review' }">
                        <ng-container *ngTemplateOutlet="modelViewer"></ng-container>
                    </div>
                    <h3 style="margin: 1rem 0 0 0">Share the design with the patient by sending them the following link and code:</h3>
                    <div class="design-link">
                        <a [href]="getSharedLink(item)" target="_blank">{{ getSharedLink(item) }}</a>
                        <button mat-icon-button matTooltip="Copy link" color="primary" (click)="copyDesignLink()">
                            <mat-icon>content_copy</mat-icon>
                        </button>
                    </div>
                    <div class="design-code">
                        <p *ngIf="item.shareDesignCode; else noCode">Code: {{ item.shareDesignCode }}</p>
                        <ng-template #noCode>
                            <p>Code: Not generated yet</p>
                            <button mat-raised-button color="primary" (click)="setShareDesignCode(item)">Generate code</button>
                        </ng-template>
                    </div>
                    <!-- <div *ngIf="item.patient.email">
                        <p>Or send the link to the patient by clicking the button below:</p>
                        <button mat-raised-button color="primary">Send link to patient</button>
                    </div> -->
                </mat-card-content>
            </mat-card>
            <mat-card class="order-card" *ngIf="pictureUploads$ | async as pictures">
                <mat-card-header>
                    <mat-card-title>Pictures </mat-card-title>
                    <mat-card-subtitle *ngIf="picturesFromReferencedAlignerOrder"
                        >Pictures are from the original Aligner order, no new pictures uploaded.
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content class="pictures-content">
                    @for (url of pictures; track $index) {
                        <div class="picture-wrap">
                            <img (click)="openPictureDialog(url)" class="uploads-picture" [ngSrc]="url" fill priority />
                        </div>
                    }
                </mat-card-content>
            </mat-card>
            <mat-card class="order-card" *ngIf="digitalScans$ | async as digitalScans">
                <mat-card-header>
                    <mat-card-title>Digital scans</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-action-list>
                        <a [download]="scan.filename" [href]="scan.url" *ngFor="let scan of digitalScans" mat-list-item>
                            <mat-icon matListItemIcon>download</mat-icon>{{ scan.filename }}
                        </a>
                    </mat-action-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <mat-drawer #messageDrawer position="end" mode="side" class="message-drawer">
        <div>
            <h2>Case discussion</h2>
            <mat-list #messageList class="scrollbox">
                <div *ngIf="!(messages$ | async)?.length">
                    <p>No messages yet!</p>
                </div>
                <div *ngFor="let message of messages$ | async" class="message" [ngClass]="{ 'own-message': isOwnMessage(message) }">
                    <ng-container *ngIf="message.type === 'chat'; else systemMessage">
                        <b class="chat-text">{{ message.admin ? 'Smile Art' : message.user }}</b>
                        <p class="chat-text">{{ message.message }}</p>
                        <p matListItemLine>{{ message?.timestamp | timestamp: "dd MMM, y 'at' HH:mm" }}</p>
                    </ng-container>
                    <ng-template #systemMessage>
                        <mat-divider></mat-divider>
                        <p class="system-text">{{ message.message }}</p>
                        <p matListItemLine>{{ message?.timestamp | timestamp: "dd MMM, y 'at' HH:mm" }}</p>
                        <mat-divider class="bottom-divider"></mat-divider>
                    </ng-template>
                </div>
            </mat-list>
        </div>
        <mat-form-field class="message-drawer-input">
            <input placeholder="Enter message" matInput type="text" [formControl]="newMessageControl" (keyup.enter)="addMessage()" />
            <button matSuffix mat-icon-button aria-label="Clear" (click)="addMessage()">
                <mat-icon>send</mat-icon>
            </button>
        </mat-form-field>
    </mat-drawer>
</mat-drawer-container>

<button
    class="fab"
    [matBadge]="this.itemSignal$()?.unreadMessages ? '!' : null"
    matBadgeColor="accent"
    mat-fab
    color="primary"
    aria-label="open/close message window"
    (click)="messageDrawer.toggle()"
>
    <mat-icon *ngIf="!messageDrawer.opened">chat</mat-icon>
    <mat-icon *ngIf="messageDrawer.opened">double_arrow</mat-icon>
</button>

<ng-template #updateDialog>
    <div *ngIf="item$ | async as item">
        <h1 mat-dialog-title>Update order status</h1>
        <div mat-dialog-content>
            <p>
                Current order status is: <b>{{ item.status }}</b>
            </p>
            <div *ngIf="item.status === 'Waiting for acceptance'">
                <p>You can accept and set the order status to <b>In design</b>.</p>
            </div>
            <div *ngIf="item.status === 'In design'">
                <ng-container *ngIf="item.type === 'Aligner'; else noDesignLinkNeeded">
                    <p>If the design is ready for review, copy/paste the design link and update the status to <b>Ready for review</b></p>
                    <mat-form-field appearance="fill" class="design-url-form-field">
                        <mat-label>Design Link</mat-label>
                        <input matInput placeholder="Design Link" [formControl]="designUrl" required />
                        <mat-error *ngIf="designUrl.hasError('required')">Design Link is required</mat-error>
                        <mat-error *ngIf="designUrl.hasError('pattern')">Input is not a valid link</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="design-url-form-field">
                        <mat-label>Amount of models</mat-label>
                        <input
                            matInput
                            placeholder="Amount of models"
                            [formControl]="amountOfModels"
                            required
                            type="number"
                            inputmode="numeric"
                        />
                        <mat-error *ngIf="amountOfModels.hasError('required')">Amount of models is required</mat-error>
                        <mat-error *ngIf="amountOfModels.hasError('pattern')">Only numbers allowed</mat-error>
                        <mat-error *ngIf="amountOfModels.hasError('min')">Amount of models can not be lower than 1</mat-error>
                    </mat-form-field>
                    @if (calculatedPricing$()) {
                        <div>
                            <h4>Pricing information</h4>
                            <p>
                                <span>{{ item.upperArch && item.lowerArch ? 'Both Arches - ' : 'Single Arch - ' }}</span
                                >{{ this.amountOfModels.value }} {{ item.type }} model(s): {{ calculatedPricing$() | currency: 'EUR' }}
                            </p>
                        </div>
                    } @else {
                        <ng-container *ngTemplateOutlet="noPricing"></ng-container>
                    }
                </ng-container>
                <ng-template #noDesignLinkNeeded
                    ><p>
                        Design review is not needed for order type <b>{{ item.type }}</b
                        >. You can update the status to <b>In Production</b>
                    </p>
                    <p>
                        The estimated completion date will be updated to
                        <b>{{ calculateDesiredCompletionDate(item) | date: 'dd-MM-YYYY' }}</b>
                    </p>
                    @if (calculatedPricing$() != null) {
                        <div class="update-status-pricing">
                            <h4><b>Pricing information</b></h4>
                            <p>
                                <span>{{ item.upperArch && item.lowerArch ? 'Both Arches - ' : 'Single Arch - ' }}</span
                                >{{ item.type }}:
                                {{ calculatedPricing$() | currency: 'EUR' }}
                            </p>
                        </div>
                    } @else {
                        <ng-container *ngTemplateOutlet="noPricing"></ng-container>
                    }
                </ng-template>
                <ng-template #noPricing>
                    <mat-progress-bar *ngIf="loadingPricing; else manualPricing" mode="indeterminate"></mat-progress-bar>
                    <ng-template #manualPricing>
                        <p *ngIf="!item.pricing?.manual; else manualPriceSet">
                            Could not determine pricing, do you want to manually set the price?
                            <button mat-stroked-button color="primary" (click)="openOverridePriceDialog()">Yes</button>
                        </p>
                    </ng-template>
                </ng-template>
                <ng-template #manualPriceSet>
                    <p>
                        Manual price for <span>{{ item.upperArch && item.lowerArch ? 'Both Arches - ' : 'Single Arch - ' }}</span
                        >{{ this.amountOfModels.value }} {{ item.type }} model(s):
                        {{ (item.pricing?.amount ? item.pricing?.amount : 0) | currency: 'EUR' }}
                        <button mat-stroked-button color="primary" (click)="openOverridePriceDialog()">Change</button>
                    </p>
                </ng-template>
            </div>
            <div *ngIf="item.status === 'Ready for review'">
                <p>If the design is to your liking, you can accept and the order will move to <b>In production</b>.</p>
                <p>Or open the case discussion and ask for any refinements. We will try to respond within 24 hours.</p>
                <br />
                <p>
                    The estimated completion date will be updated to <b>{{ calculateDesiredCompletionDate(item) | date: 'dd-MM-YYYY' }}</b>
                </p>
                <ng-container *ngIf="calculatedPricing$(); else noPricing">
                    <p>Pricing for this design: {{ calculatedPricing$() | currency: 'EUR' }}</p>
                </ng-container>
                <ng-template #noPricing>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </ng-template>
            </div>
            <div *ngIf="['Partly Printed', 'Printed', 'Vacuumed/Cut', 'Packed', 'In production'].includes(item.status)">
                <div class="production-substatus-toggle">
                    <mat-icon matTooltip="Production sub status will not be visible for non-admin users">info</mat-icon>
                    <mat-button-toggle-group [formControl]="productionSubstatusCheckbox">
                        <mat-button-toggle value="Partly Printed">Partly Printed</mat-button-toggle>
                        <mat-button-toggle value="Printed">Printed</mat-button-toggle>
                        <mat-button-toggle value="Vacuumed/Cut">Vacuumed/Cut</mat-button-toggle>
                        <mat-button-toggle value="Packed">Packed</mat-button-toggle>
                        <mat-button-toggle value="Send">Send</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div [ngSwitch]="productionSubstatusCheckbox.value">
                    <div *ngSwitchCase="'Send'">
                        <p>If the order is shipped, copy/paste the package Track & trace URL and update the status to <b>Send</b>.</p>
                        <mat-form-field appearance="fill" class="design-url-form-field">
                            <mat-label>Track & trace link</mat-label>
                            <input matInput placeholder="Track & trace link" [formControl]="trackAndTrace" />
                            <mat-error *ngIf="trackAndTrace.hasError('pattern')">Input is not a valid link</mat-error>
                        </mat-form-field>
                        <div>
                            <p>Or choose to deliver this order yourself. Order status will be set to <b>Send</b>.</p>
                            <mat-checkbox [formControl]="manualDelivery">Deliver the order yourself</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <div class="button-wrapper" *ngIf="item.status === 'Waiting for acceptance'">
                <button color="primary" mat-raised-button (click)="updateStatus('In design', item)">Accept order</button>
            </div>
            <div class="button-wrapper" *ngIf="item.status === 'In design'">
                <button
                    *ngIf="item.type === 'Aligner'"
                    color="primary"
                    [disabled]="designUrl.invalid || amountOfModels.invalid"
                    mat-raised-button
                    (click)="updateStatus('Ready for review', item)"
                >
                    Update to Ready for review
                </button>
                <button
                    *ngIf="item.type !== 'Aligner'"
                    [disabled]="item.pricing?.amount != null && calculatedPricing != null"
                    color="primary"
                    mat-raised-button
                    (click)="updateStatus('In production', item)"
                >
                    Update to In Production
                </button>
            </div>
            <!-- TODO: Also move to design viewer page so customer can accept while viewing -->
            <div class="button-wrapper space-between" *ngIf="item.status === 'Ready for review'">
                <div>
                    <button color="primary" mat-raised-button (click)="updateStatus('In production', item)">Accept design</button>
                </div>
                <button mat-raised-button mat-dialog-close (click)="messageDrawer.open()">
                    <mat-icon>chat</mat-icon>Open case discussion
                </button>
            </div>
            <div
                class="button-wrapper"
                *ngIf="['Partly Printed', 'Printed', 'Vacuumed/Cut', 'Packed', 'In production'].includes(item.status)"
            >
                <button
                    color="primary"
                    [disabled]="isProductionUpdateButtonDisabled()"
                    mat-raised-button
                    (click)="updateStatus(productionSubstatusCheckbox.value, item)"
                >
                    Update to {{ productionSubstatusCheckbox.value ? productionSubstatusCheckbox.value : 'next status' }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modelViewer>
    <ng-container *ngIf="modelURL">
        <iframe class="model-iframe" [src]="modelURL"></iframe>
    </ng-container>
</ng-template>

<ng-template #pictureDialog let-data>
    <div style="width: 100%; height: 100%; position: relative">
        <img [ngSrc]="data.url" fill style="object-fit: contain" />
    </div>
</ng-template>

<ng-template #notesDialog let-data>
    <h3 mat-dialog-title>Admin notes</h3>
    <mat-dialog-content>
        <p class="saved-message" [ngClass]="{ show: adminNotesSavedAt }">
            Last changes saved at {{ adminNotesSavedAt | date: 'd MMM HH:mm:ss' }}
        </p>
        <mat-progress-bar *ngIf="savingNotes" mode="indeterminate"></mat-progress-bar>
        <mat-form-field appearance="fill" style="width: 100%; height: 100%">
            <textarea cdkTextareaAutosize cdkAutosizeMinRows="50" #notesInput matInput [formControl]="adminNotes"> </textarea>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button matDialogClose>Close</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #overridePriceDialog>
    <h3 mat-dialog-title>Change calculated pricing</h3>
    <ng-container *ngIf="item$ | async as item">
        <mat-dialog-content>
            <mat-form-field *ngIf="admin" appearance="fill">
                <mat-label>New amount</mat-label>
                <input type="number" inputmode="numeric" matInput placeholder="New price" [formControl]="overridePrice" required />
                <span matTextSuffix>€</span>
                <mat-error *ngIf="overridePrice.hasError('required')">New amount is required</mat-error>
                <mat-error *ngIf="overridePrice.hasError('min')">New amount cannot be negative</mat-error>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" mat-dialog-close (click)="overridePricing(item)">Change pricing</button>
            <button mat-raised-button mat-dialog-close>Close</button>
        </mat-dialog-actions>
    </ng-container>
</ng-template>
