<mat-toolbar> <mat-icon class="title-icon" matListItemIcon>people</mat-icon> <span class="subtitle">My Customers</span> </mat-toolbar>
<mat-divider></mat-divider>
<mat-form-field class="table-filter">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input />
</mat-form-field>
<ng-container>
  <table mat-table matTableResponsive [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <!--- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition" -->
    <ng-container matColumnDef="practice">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Practice</th>
      <td mat-cell *matCellDef="let element">{{ element.defaultPractice?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="fullname">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Customer</th>
      <td mat-cell *matCellDef="let element">{{ element.fullname }}</td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>City</th>
      <td mat-cell *matCellDef="let element">{{ element.defaultPractice?.city }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>email</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      @if (input.value) {
        <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
      }
      @if (!input.value) {
        <td class="mat-cell" colspan="4">No customers found</td>
      }
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="['/customers', row.id]"></tr>
  </table>
</ng-container>
