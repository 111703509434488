import { animate, style, transition, trigger } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    Component,
    DestroyRef,
    Injector,
    Signal,
    TemplateRef,
    ViewChild,
    computed,
    effect,
    inject,
    signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Timestamp } from '@google-cloud/firestore';
import { CalendarEvent } from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import * as moment from 'moment';
import { Observable, Subject, combineLatest, lastValueFrom, map, startWith, switchMap, take } from 'rxjs';
import { AuthService } from '../auth.service';
import { OrdersService } from '../orders.service';
import { OrderStatus, OrderType, OrderWithFirestoreId } from '../types';
import { deadlineReached, getSessionStorageValue } from '../util';

type DeadlineSeverity = {
    severity: 'warning' | 'danger';
    daysUntilDeadline: number;
    tooltip?: string;
};

export type TableOrder = OrderWithFirestoreId & {
    fullname: string;
    deadlineSeverity?: DeadlineSeverity;
    icon?: null;
    unreadMessages?: boolean;
};

const calendarColors: Record<OrderType, EventColor & { humantext: string }> = {
    'Bleaching tray(s)': {
        primary: '#ad2121',
        secondary: '#FAE3E3',
        humantext: 'red',
    },
    'Aligner': {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
        humantext: 'blue',
    },
    'Fixed retention': {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
        humantext: 'yellow',
    },
    'Individual tray(s)': {
        primary: '#14730f',
        secondary: '#97c795',
        humantext: 'green',
    },
    'Nightguard': {
        primary: '#eca3cb',
        secondary: '#efb5d5',
        humantext: 'pink',
    },
    'Refinement': {
        primary: '#a3ecf2',
        secondary: '#b5eff4',
        humantext: 'lightblue',
    },
    'Retainer': {
        primary: '#6435e8',
        secondary: '#7349ea',
        humantext: 'purple',
    },
    'Study model(s)': {
        primary: '#222d33',
        secondary: '#7a8184',
        humantext: 'black',
    },
};

const COLUMN_DEFINITIONS: { [key: string]: { displayName: string; order: number } } = {
    id: {
        displayName: 'ID',
        order: 0,
    },
    status: {
        displayName: 'Status',
        order: 1,
    },
    fullname: {
        displayName: 'Patient',
        order: 2,
    },
    practice: {
        displayName: 'Practice',
        order: 3,
    },
    type: {
        displayName: 'Type',
        order: 4,
    },
    creationDate: {
        displayName: 'Creation date',
        order: 5,
    },
    desiredCompletionDate: {
        displayName: 'Desired date of delivery',
        order: 6,
    },
};

type ColumnDefinition = { displayName: string; key: string };

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ 'max-height': '0px', 'opacity': '0' }),
                animate('200ms', style({ 'max-height': '500px', 'opacity': '1' })),
            ]),
            transition(':leave', [animate('200ms', style({ 'max-height': '0px', 'opacity': '0' }))]),
        ]),
    ],
})
export class OrdersComponent implements AfterViewInit {
    admin = toSignal(this.auth.admin$, { initialValue: false });

    allColumns = Object.entries(COLUMN_DEFINITIONS).map(([key, value]) => ({ key, ...value }));

    displayColumns$ = signal<ColumnDefinition[]>(
        Object.entries(COLUMN_DEFINITIONS).map(([key, { displayName }]) => ({ key, displayName })),
    );
    displayedColumnsWithActions$: Signal<string[]> = computed(() => [...this.displayColumns$().map(v => v.key), 'actions']);

    /**
     * Represents the form control for the columns in the orders component.
     * Each key in the form group represents a column name, and the corresponding value represents whether the column is visible or not.
     */
    columnForm = this.fb.nonNullable.group<{ [key: string]: boolean }>({
        id: true,
        status: true,
        fullname: true,
        practice: true,
        type: true,
        creationDate: true,
        desiredCompletionDate: true,
    });

    /**
     * Tab index for the orders component, used to determine which tab is selected.
     * 0 = Calendar, 1 = Table, Default value is 0. Stored in session storage.
     */
    selectedTabIndex$ = signal<number>(getSessionStorageValue('orders.selectedTabIndex', 0));

    dataSource = new MatTableDataSource<TableOrder>();
    @ViewChild(MatSort) sort?: MatSort;
    @ViewChild('deleteDialog') deleteDialog!: TemplateRef<unknown>;

    constructor(
        private readonly auth: AuthService,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly fb: FormBuilder,
        private readonly injector: Injector,
        private readonly ordersService: OrdersService,
    ) {
        // Update session storage with the selected tab index when the signal updates
        effect(() => {
            sessionStorage.setItem('orders.selectedTabIndex', this.selectedTabIndex$().toString());
        });

        // Update the data source when the orders signal updates or the admin status changes.
        effect(
            () => {
                const orders = this.ordersSignal$();
                if (orders == null) return;
                // Update the data source with the orders
                this.dataSource.data = orders;
                this.dataSource.sort = this.sort || null;

                // Update the unseen messages signal with the order IDs that have unseen messages
                this.ordersService.unseenMessagesSignal.set(
                    orders
                        .filter(order => this.unreadMessages(order))
                        .map(({ firestoreId, id, customer }) => ({
                            firestoreId,
                            functionalId: id!,
                            customerFirestoreId: customer.firestoreId,
                        })),
                );

                if (this.admin()) {
                    this.calendarEvents = orders
                        .filter(v => v.desiredCompletionDate != null)
                        .map(
                            (v): CalendarEvent => ({
                                start: (v.desiredCompletionDate as Timestamp).toDate(),
                                // TODO: Aparte types voor verschillende status. Bij een draft is type optional.
                                title: `${v.type ?? '<draft>'}, ${v.patient.firstName} ${v.patient.lastName}, ${v.practice.name}`,
                                color: calendarColors[v.type],
                                meta: {
                                    status: v.status,
                                    orderId: v.firestoreId,
                                    customerId: v.customer.firestoreId,
                                },
                            }),
                        );
                }
            },
            // This is a risky setting, it can cause circular effects when you write to the signal that is being read.
            // In this case, it is safe because the signal is only being read and not written to. A better way is computed signals but I dont know how to share that between services.
            { allowSignalWrites: true },
        );

        // Retrieve possible filter values from session storage
        this.filterChips.set(getSessionStorageValue('orders.filterChips', []));
    }

    // TODO: Move to own component
    // Calendar properties
    viewDate = new Date();
    calendarEvents: CalendarEvent[] = [];
    refresh = new Subject<void>();
    activeDayIsOpen = false;
    calendarEventClicked(event: CalendarEvent) {
        this.routeToOrder(event.meta.orderId, event.meta.customerId, event.meta.status);
    }
    calendarDayClicked({ date, events }: { date: Date; events: CalendarEvent[] }) {
        if (events.length) {
            if ((moment(this.viewDate).isSame(date, 'day') && this.activeDayIsOpen) || events.length === 0) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    destroy$ = inject(DestroyRef);
    user$ = this.auth.user$;
    orders$?: Observable<OrderWithFirestoreId[]>;

    ordersToggle = this.fb.nonNullable.group({
        Draft: getSessionStorageValue('orders.viewDraft', false),
        Send: getSessionStorageValue('orders.viewSend', false),
    });

    swapOrdersToggle(status: 'Draft' | 'Send') {
        const swappedValue = !this.ordersToggle.controls[status].value;
        sessionStorage.setItem(`orders.view${status}`, `${swappedValue}`);
        this.ordersToggle.controls[status].setValue(swappedValue);
    }

    trackByFunctionalID(_index: number, order: OrderWithFirestoreId) {
        return order.id;
    }

    ordersSignal$ = toSignal(
        combineLatest([
            this.ordersToggle.valueChanges.pipe(startWith(this.ordersToggle.value)),
            this.auth.firestoreId$,
            this.auth.admin$,
        ]).pipe(
            switchMap(([ordersToggle, customerFirestoreID]) => {
                if (this.admin()) {
                    // Filter out the statuses that are toggled off
                    const statusFilter = Object.entries(ordersToggle)
                        .filter(([_key, value]) => !value)
                        .map(([key]) => key);
                    return this.ordersService
                        .getOrders({
                            where: statusFilter.length ? [{ field: 'status', operator: 'not-in', value: statusFilter }] : [],
                            orderBy: { field: 'creationDate', direction: 'desc' },
                        })
                        .pipe(
                            map(v =>
                                v.map(order => ({
                                    ...order,
                                    fullname: `${order.patient.firstName} ${order.patient.lastName}`,
                                    unreadMessages: this.unreadMessages(order),
                                    deadlineSeverity: this.deadlineSeverity(order),
                                })),
                            ),
                        );
                } else {
                    return this.ordersService
                        .getCustomerOrders(customerFirestoreID, { orderBy: { field: 'creationDate', direction: 'desc' } })
                        .pipe(
                            map(v =>
                                v.map(order => ({
                                    ...order,
                                    fullname: `${order.patient.firstName} ${order.patient.lastName}`,
                                    unreadMessages: this.unreadMessages(order),
                                })),
                            ),
                        );
                }
            }),
        ),
    );

    unreadMessages(order: OrderWithFirestoreId): boolean {
        const email = this.auth.userSignal$()?.email?.replace('.', ','); // Firestore does not allow dots in field names, so we replace them with commas.
        if (!email || order.status === 'Draft') return false;
        const lastSeen = order?.lastSeen?.[email];
        const lastMessage = order?.lastMessage;

        // Migration, if the lastMessage is from before 24-04-2024, assume the user has seen the message.
        if (lastMessage && lastMessage?.toDate() < new Date('2024-04-24')) return false;

        if (order.status === 'Waiting for acceptance' && !lastMessage) return false;

        // If no lastMessage is set, there shouldnt be any unread messages.
        if (!lastMessage) return false;
        // If the user has never seen the order, the user has unseen messages
        if (!lastSeen) return true;
        // If the lastSeen timestamp is before the lastMessage timestamp, the user has unseen messages
        return lastSeen < lastMessage;
    }

    /**
     * Sets the state of a column in the displayColumns$ array.
     * If the state is true and the column is not already in the array, it adds the column to the array.
     * If the state is false and the column is in the array, it removes the column from the array.
     * @param column - The column definition to set the state for.
     * @param state - The state to set for the column (true to add, false to remove).
     */
    private setColumn(column: ColumnDefinition, state: boolean) {
        const index = this.displayColumns$().findIndex(v => v.key === column.key);
        if (state) {
            // If the column is already in the list, don't add it again.
            if (index !== -1) return;
            this.displayColumns$.update(current => {
                const newCurrent = [...current];
                newCurrent.splice(COLUMN_DEFINITIONS[column.key].order, 0, { key: column.key, displayName: column.displayName });
                return newCurrent;
            });
        } else {
            // If the column should not be displayed and it is not in the list, nothing needs to be done.
            if (index === -1) return;
            this.displayColumns$.update(current => current.filter(v => v.key !== column.key));
        }
    }

    ngAfterViewInit() {
        // Update session storage and filter predicate when the filter chips change
        effect(
            () => {
                sessionStorage.setItem('orders.filterChips', JSON.stringify(this.filterChips()));
                this.dataSource.filterPredicate = (data: TableOrder, _filter: string) => {
                    const filterArray = this.filterChips();
                    const dataString = `${data.id} ${data.fullname} ${data.practice.name} ${data.type} ${data.status} ${
                        data.creationDate ? data.creationDate.toDate().toLocaleDateString('nl-Nl') : ''
                    } ${data.desiredCompletionDate?.toDate().toLocaleDateString('nl-Nl')}`;
                    return filterArray.every(filter => dataString.toLowerCase().includes(filter));
                };
                this.dataSource.filter = this.filterChips().join(' ');
            },
            { injector: this.injector },
        );

        this.columnForm.valueChanges.pipe(takeUntilDestroyed(this.destroy$)).subscribe(v => {
            Object.entries(v).forEach(([key, value]) => {
                if (value !== undefined) {
                    this.setColumn({ key, displayName: COLUMN_DEFINITIONS[key].displayName }, value);
                }
            });
        });
    }

    filterChips = signal<string[]>([]);
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    addFilter(event: MatChipInputEvent) {
        const filterValue = event.value.trim().toLowerCase();
        if (!filterValue) {
            return;
        }
        this.filterChips.update(current => [...current, filterValue]);
        // Clear the input value
        event.chipInput!.clear();
    }
    removeFilter(filter: string) {
        this.filterChips.update(current => current.filter(v => v !== filter));
    }

    async routeToNewOrder(functionalId: OrderWithFirestoreId['id'], event: Event) {
        event.stopPropagation();
        await this.router.navigate(['/new'], { queryParams: { refinementFor: functionalId } });
    }

    /**
     * Routes to the order detail page.
     * Own routing method so we can use event.stopPropagation()
     * Else the row click event will be triggered as well.
     */
    async routeToOrder(id: string, customerId: string, status: OrderStatus, openChat = false, event?: Event) {
        if (event) {
            event.stopPropagation();
        }
        const queryParam = openChat ? `?showmessages=true` : '';
        if (this.admin()) {
            const userID = await lastValueFrom(this.auth.firestoreId$.pipe(take(1)));
            // If the order is a draft and the user is the customer, route to the draft page.
            if (status === 'Draft' && customerId === userID) {
                this.router.navigateByUrl(`/orders/${id}/draft${queryParam}`);
                // If the user is an admin, route to the customer order page.
            } else {
                this.router.navigateByUrl(`/customers/${customerId}/orders/${id}${queryParam}`);
            }
            // If the user is not an admin, route to the order page.
        } else {
            this.router.navigateByUrl(`/orders/${id}${status === 'Draft' ? '/draft' : ''}${queryParam}`);
        }
    }

    /**
     * Determines the severity of the deadline based on the order status and desired completion date.
     * @param {OrderWithFirestoreId} order - The order object containing the status and desired completion date.
     * @returns {string | undefined} - The severity of the deadline ('danger', 'warning') or undefined if no severity is determined.
     */
    deadlineSeverity({ status, desiredCompletionDate }: OrderWithFirestoreId): DeadlineSeverity | undefined {
        if (!desiredCompletionDate || ['Printed', 'Vacuumed/Cut', 'Packed', 'Send'].includes(status)) return;
        const daysUntilDeadline = moment(desiredCompletionDate.toDate()).startOf('day').diff(moment().startOf('day'), 'days');
        const absoluteDaysUntilDeadline = Math.abs(daysUntilDeadline);
        const tooltip =
            daysUntilDeadline === 1
                ? 'is tomorrow!'
                : daysUntilDeadline < 0
                  ? `was ${absoluteDaysUntilDeadline} days ago!`
                  : `is in ${daysUntilDeadline} days!`;
        if (daysUntilDeadline <= 3) return { severity: 'danger', daysUntilDeadline, tooltip };
        if (daysUntilDeadline <= 5) return { severity: 'warning', daysUntilDeadline, tooltip };
        return;
    }

    /**
     * Checks if the refinement deadline has been reached for the given order.
     * Admins can always request a refinement.
     * The deadline is calculated based on the last time the order was updated.
     */
    refinementDeadlineReached(order: OrderWithFirestoreId) {
        // Admins can always request a refinement
        if (this.admin()) return true;
        if (!order.lastTimeUpdated) return false;
        return deadlineReached(order.lastTimeUpdated, { amount: order.pricing?.quantity, unit: 'weeks' });
    }

    openDeleteOrderDialog(orderFirestoreId: string, customerFirestoreID: string, event: Event) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(this.deleteDialog, { data: { orderFirestoreId, customerFirestoreID } });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.ordersService.deleteOrder(orderFirestoreId, customerFirestoreID);
            }
        });
    }

    // TODO: Move verification message to own component.
    mailSend = false;
    tooManyMailRequests = false;
    async sendVerificationMail() {
        await this.auth
            .sendVerificationMail()
            .then(() => {
                this.mailSend = true;
                this.tooManyMailRequests = false;
            })
            .catch(e => {
                if (e.code.includes('too-many-requests')) {
                    this.tooManyMailRequests = true;
                } else {
                    throw e;
                }
            });
    }
}

export function mapToIcon(status: OrderStatus) {
    switch (status) {
        case 'Draft':
            return 'add';
        case 'Send':
            return 'done';
        default:
            return 'pending';
    }
}

export function getIconColor(status: OrderStatus) {
    switch (status) {
        case 'Draft':
            return 'black';
        case 'Send':
            return 'green';
        default:
            return 'orange';
    }
}
