<div class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering">
  @if (formcontrol?.hasValidator(requiredValidator) && formcontrol?.valid) {
    <mat-icon class="upload-icon">done</mat-icon>
  }
  @if (formcontrol?.value && !multipleFiles) {
    <button
      class="delete-icon"
      mat-icon-button
      matTooltip="Delete picture"
      (click)="deleteUploadedPicture()"
      >
      <mat-icon>delete</mat-icon>
    </button>
  }
  @if (!backgroundImage) {
    <div class="multifile-upload-list">
      <!-- Bij geen image dan maar tekst -->
      <div>
        <h3>File Upload Drop Zone</h3>
        <p>Drag and Drop a File</p>
      </div>
    </div>
  }
  <!-- Achtergrond plaatje, NIET wat is geupload. -->
  @if (!files.length && !formcontrol?.value) {
    <img [src]="backgroundImage" />
  }
  <!-- Choose file -->
  @if (multipleFiles || !formcontrol?.value) {
    <div>
      <input
        #chooseFile
        hidden="true"
        type="file"
        [multiple]="multipleFiles"
        onclick="this.value=null"
        accept=".png,.jpg,.stl,.jpeg,.obj,.dcm,.tiff"
        (change)="chooseFileInput($event)"
        />
      <button
        class="choosefile-button"
        [ngClass]="{ 'multiple-files': multipleFiles }"
        mat-button
        color="primary"
        (click)="chooseFile.click()"
        >
        Open File@if (multipleFiles) {
        <span>(s)</span>
      }
    </button>
  </div>
}

@if (formcontrol?.value && showPicture) {
  <div>
    <img [src]="downloadURL" />
  </div>
} @else {
  @for (file of files; track file) {
    <div class="upload-tasks">
      <upload-task
        [prefix]="prefix"
        [customerId]="customerId"
        [orderId]="orderId"
        [file]="file"
        [formcontrol]="formcontrol"
      ></upload-task>
    </div>
  }
}
</div>
<!-- Error message -->
@if (error) {
  <div class="error-message">
    <p>{{ error }}</p>
  </div>
}
@if (formcontrol?.value?.length && multipleFiles) {
  <div>
    <div class="filelist-title">
      <h3 style="margin: 0">Uploaded files</h3>
      <button
        [ngClass]="{ show: files.selectedOptions.selected.length }"
        ng
        matTooltip="Delete files"
        mat-icon-button
        (click)="deleteFiles(files.selectedOptions.selected)"
        >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <mat-selection-list #files>
      @for (file of formcontrol?.value; track file) {
        <mat-list-option [value]="file">
          {{ onlyFilename(file) }}
        </mat-list-option>
      }
    </mat-selection-list>
  </div>
}
