import { Component } from '@angular/core';
import { PromptUpdateService } from './promp-update.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(readonly _updateService: PromptUpdateService) {}
    title = 'smile-art';
}
