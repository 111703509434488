import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, TemplateRef, ViewChild, computed, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Timestamp } from 'firebase/firestore';
import { omit, sortBy } from 'lodash';
import * as moment from 'moment';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { InvoiceService, getTotalAndDiscountAmount } from '../../invoice.service';
import { OrdersService } from '../../orders.service';
import { Customer, Invoice, OrderWithFirestoreId } from '../../types';
import { InvoiceConfirmationDialogComponent } from '../invoice-confirmation-dialog/invoice-confirmation-dialog.component';
import { OrderConfirmationDialogComponent } from '../order-confirmation-dialog/order-confirmation-dialog.component';

type PracticeSelection = { name: string; amountOfOrders: number; orders: OrderWithFirestoreId[] };
export type SparseCustomer = Pick<Customer, 'firestoreId' | 'firstName' | 'lastName' | 'firestoreId'>;
type CustomerSelection = SparseCustomer & {
    amountOfOrders: number;
    practices: PracticeSelection[];
};

@Component({
    selector: 'app-invoice-management',
    templateUrl: './invoice-management.component.html',
    styleUrls: ['./invoice-management.component.scss'],
})
export class InvoiceManagementComponent {
    constructor(
        private readonly dialog: MatDialog,
        private readonly invoiceService: InvoiceService,
        private readonly orderService: OrdersService,
        private readonly snackbar: MatSnackBar,
        private readonly datePipe: DatePipe,
    ) {}

    @ViewChild('markInvoiceDialog') markInvoiceDialog!: TemplateRef<unknown>;

    selectedCustomer = new FormControl<CustomerSelection | null>(null);
    // Seperate signal so we can listen when the customer is selected instead of the valueChanges.
    // ValueChanges is triggered when the user types for the autocomplete.
    selectedCustomerSignal = signal<MatAutocompleteSelectedEvent | null>(null);
    selectedPractice = new FormControl<PracticeSelection[] | null>(null);
    selectedPractice$ = toSignal(this.selectedPractice.valueChanges);
    discount = new FormControl<number | null>(null);
    selectedOrders = new FormControl<OrderWithFirestoreId[]>([]);

    // Filters
    filterChips = signal<string[]>(['not-paid']);
    filterChips$ = toObservable(this.filterChips);

    monthlyInvoices$ = combineLatest([this.filterChips$, this.invoiceService.getInvoices()]).pipe(
        map(([filters, invoices]) => {
            // If no filters are set, return all invoices
            if (!filters.length) return invoices;
            // Else, filter the invoices based on the filters
            return invoices.filter(invoice => {
                const invoiceFields = Object.values(invoice)
                    // Omit the mail field from the invoice, since we don't want to filter on that
                    .map(value => deepValuesAsString(omit(value, 'mail')))
                    .join('');
                return filters.every(filter => {
                    // Special case for not-paid filter
                    if (filter === 'not-paid') {
                        return invoice.paidAt == null;
                    }
                    // Special case for paid filter
                    if (filter === 'paid') {
                        return invoice.paidAt != null;
                    }
                    // Else just check if the invoice fields contain the filter
                    return invoiceFields.includes(filter);
                });
            });
        }),
    );

    invoiceTotal$ = combineLatest([this.selectedOrders.valueChanges, this.discount.valueChanges.pipe(startWith(0))]).pipe(
        map(([orders, _discount]) => {
            const parsedDiscount = this.parseDiscount();
            if (!orders?.length) return { total: 0, discountAmount: 0, totalWithDiscount: 0 };
            return getTotalAndDiscountAmount(orders, parsedDiscount);
        }),
    );

    customersWithUnpaidOrders$: Observable<CustomerSelection[]> = this.orderService.getOrdersNotPaidYet().pipe(
        map(orders => {
            // TODO: Super weird unique case where 2 practices from different customers should be considered as 1 practice for both customers.
            const customerMap = new Map<string, CustomerSelection>();
            orders.forEach(order => {
                const customer = customerMap.get(order.customer.firestoreId);
                if (customer) {
                    // Increment the amount of orders for the customer
                    customer.amountOfOrders += 1;
                    const existingPractice = customer.practices.find(p => p.name === order.practice.name);
                    if (existingPractice) {
                        // Increment the amount of orders for the practice
                        existingPractice.amountOfOrders += 1;
                        existingPractice.orders.push(order);
                        existingPractice.orders = sortBy(existingPractice.orders, 'sendDate');
                    } else {
                        customer.practices.push({ name: order.practice.name, amountOfOrders: 1, orders: [order] });
                    }
                } else {
                    const practices = [{ name: order.practice.name, amountOfOrders: 1, orders: [order] }];
                    customerMap.set(order.customer.firestoreId, {
                        amountOfOrders: 1,
                        firestoreId: order.customer.firestoreId,
                        firstName: order.customer.firstName,
                        lastName: order.customer.lastName,
                        practices: practices,
                    });
                }
            });
            return Array.from(customerMap.values());
        }),
    );

    /**
     * Computed property that returns the unpaid orders grouped by year and month.
     * It filters the orders based on the selected practice and the selected customer.
     * If there is no selected customer or if the selected customer has only one practice,
     * the selected practice will be set to the customer's practice automatically.
     *
     * @returns An object containing the unpaid orders grouped by year and month.
     *          Returns null if there is no selected customer.
     */
    unpaidOrders$ = computed(() => {
        const selectedCustomer: CustomerSelection | null = this.selectedCustomerSignal()?.option.value;
        if (!selectedCustomer) return null;
        if (selectedCustomer.practices.length === 1) {
            this.selectedPractice.setValue(selectedCustomer.practices);
        }
        const selectedPractice = this.selectedPractice$();
        // Filter the orders based on the selected practice
        return groupOrdersByYearMonth(
            selectedCustomer.practices.flatMap(p => p.orders).filter(order => selectedPractice?.some(p => p.name === order.practice.name)),
        );
    });

    customerAutocompleteDisplay(customer: CustomerSelection | null): string {
        // Not sure why..but sometimes the customer is null
        if (customer == null) return '';
        return `${customer.firstName} ${customer.lastName} - ${customer.amountOfOrders} orders`;
    }

    // Check the length of the amount of practices. If its just 1, set the selectedPractice to that practice and return true.
    checkPractices(practices?: PracticeSelection[]) {
        if (practices?.length === 1) {
            this.selectedPractice.setValue(practices);
            return true;
        }
        return false;
    }

    /**
     * Parses the discount value and returns it as a decimal number.
     * If the discount value is null, returns null.
     * @returns The parsed discount value as a decimal number or null.
     */
    private parseDiscount(): number | undefined {
        const discount = this.discount.value;
        if (discount == null) return undefined;
        return discount / 100;
    }

    async previewInvoice() {
        const orders = this.selectedOrders.value;
        if (!orders?.length) return;
        const mappedOrders = orders.map(order => ({ ...order, firestoreId: order.id! }));
        (await this.invoiceService.getPDFMonthlyInvoice(mappedOrders, this.parseDiscount())).open();
    }

    async viewPdfInvoice(invoice: Invoice, event: Event) {
        event.stopPropagation();
        const orders = await this.orderService.getOrdersByFirestoreId(invoice.customer.id, invoice.orderIds);
        if (!orders?.length) return;
        (await this.invoiceService.getPDFMonthlyInvoice(orders, invoice.totals.discount, false, invoice.functionalId)).open();
    }

    /**
     * Represents the selected group/month for invoice management.
     */
    selectedGroup: string | null = null;
    async toggleAllUnpaidOrders(event: MatCheckboxChange, orders: OrderWithFirestoreId[], group: string) {
        if (event.checked) {
            this.selectedOrders.setValue(orders);
        } else {
            this.selectedOrders.setValue([]);
        }
        this.selectedGroup = event.checked ? group : null;
    }

    openInvoiceConfirmationDialog() {
        const orders = this.selectedOrders.value;
        if (!orders?.length) return;
        const totals = getTotalAndDiscountAmount(orders, this.parseDiscount());
        const dialogRef = this.dialog.open(InvoiceConfirmationDialogComponent, { data: { customer: this.selectedCustomer.value, totals } });
        dialogRef.afterClosed().subscribe(async (result: { email: string } | undefined) => {
            if (result) {
                await this.invoiceService.createMonthlyInvoice(orders, totals, result.email);
                this.snackbar.open('Invoice send!', 'close', { duration: 5000 });
                // Reset form values
                this.discount.setValue(null);
                this.selectedOrders.setValue([]);
            }
        });
    }

    async markOrdersAsPaid() {
        const orders = this.selectedOrders.value;
        if (!orders?.length) return;
        const dialogRef = this.dialog.open(OrderConfirmationDialogComponent, { data: { orders } });

        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.orderService.markOrdersAsPaid(orders, 'manual_mark_as_paid');
                this.snackbar.open('Orders marked as paid!', 'close', { duration: 5000 });
            }
        });
    }

    async markInvoicePaid(invoice: Invoice, event: Event) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(this.markInvoiceDialog, { data: { mark: 'paid', invoice } });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.invoiceService.markInvoicePaid(invoice.customer.id, invoice.firestoreId);
                this.snackbar.open('Invoice marked as paid!', 'close', { duration: 5000 });
            }
        });
    }
    async markInvoiceUnpaid(invoice: Invoice, event: Event) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(this.markInvoiceDialog, { data: { mark: 'not paid' } });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.invoiceService.markInvoiceUnpaid(invoice.customer.id, invoice.firestoreId);
                this.snackbar.open('Invoice marked as not paid!', 'close', { duration: 5000 });
            }
        });
    }

    // Filters
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    addFilter(event: MatChipInputEvent) {
        const filterValue = event.value.trim().toLowerCase();
        if (!filterValue) {
            return;
        }
        this.filterChips.update(current => [...current, filterValue]);
        // Clear the input value
        event.chipInput!.clear();
    }
    removeFilter(filter: string) {
        this.filterChips.update(current => current.filter(v => v !== filter));
    }

    // Method to format yearMonth to full month name
    getFullMonthName(yearMonth: string): string {
        const [year, month] = yearMonth.split('-');
        const date = new Date(Number(year), Number(month) - 1); // Months are 0-indexed in JavaScript Date
        return this.datePipe.transform(date, 'MMMM  `yy') || '';
    }
}

/**
 * Groups the given orders by year and month.
 *
 * @param orders - An array of orders with Firestore IDs.
 * @returns An array of objects containing the grouped orders and their corresponding date.
 */
function groupOrdersByYearMonth(orders: OrderWithFirestoreId[]): { date: string; orders: OrderWithFirestoreId[] }[] {
    // Group orders by year and month
    const grouped = orders.reduce(
        (acc, order) => {
            const date = order.sendDate?.toDate() || new Date();
            const key = `${date.getFullYear()}-${date.getMonth() + 1}`; // Month is 0-indexed
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(order);
            return acc;
        },
        {} as Record<string, OrderWithFirestoreId[]>,
    );

    // Convert the grouped object into an array and sort by date in descending order
    return Object.keys(grouped)
        .map(key => ({ date: key, orders: grouped[key] }))
        .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
        });
}

function deepValuesAsString(value: unknown): string {
    if (value == null) return '';
    if (typeof value === 'string') return value.toLowerCase();
    if (typeof value === 'number') return value.toString();
    if (value instanceof Timestamp) return moment(value.toDate()).format('L').toString();
    if (value instanceof Date) return value.toLocaleDateString();
    if (typeof value === 'object')
        return Object.values(value)
            .map(v => deepValuesAsString(v))
            .join('');
    return '';
}
