<mat-card>
    <mat-card-header>
        <mat-card-title>Create new patient</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h3>Patient Information</h3>
        <form [formGroup]="newPatientForm">
            <div class="patient-names">
                <mat-form-field appearance="fill">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First name" formControlName="firstName" required />
                    <mat-error *ngIf="newPatientForm.controls.firstName.hasError('required')">Patient first name is required.</mat-error>
                    <mat-error *ngIf="newPatientForm.controls.firstName">Only letters are allowed for first name</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last name" formControlName="lastName" required />
                    <mat-error *ngIf="newPatientForm.controls.lastName">Patient last name is required.</mat-error>
                    <mat-error *ngIf="newPatientForm.controls.firstName">Only letters are allowed for last name</mat-error>
                </mat-form-field>
            </div>

            <mat-form-field appearance="fill" class="birth-date">
                <mat-label>Patient Birthdate</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="birthdate" [max]="maxDate" [min]="minDate" />
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                <!-- TODO: Error messages when the date is out of range of the maxDate or minDate -->
                <mat-error *ngIf="newPatientForm.controls['birthdate'].hasError('required')">Patient birthdate is required.</mat-error>
            </mat-form-field>
            <div class="email-wrapper">
                <mat-form-field appearance="fill">
                    <mat-label>Patient e-mail</mat-label>
                    <input matInput placeholder="e-mail" formControlName="email" />
                    <mat-error *ngIf="newPatientForm.controls.email.hasError('email')"
                        >"{{ newPatientForm.controls.email.value }}" is not a valid email address.</mat-error
                    >
                </mat-form-field>
                <mat-icon
                    (click)="tooltip.show()"
                    #tooltip="matTooltip"
                    style="color: orange"
                    matTooltip="This will be used if you want us to send files to your patient, such as the informed consent, cost estimate etc. Check
                this box only if your patient has agreed upon recieving files from us."
                    >info</mat-icon
                >
                <span></span>
            </div>
            <div class="practice-form">
                <div class="subtitle-with-button">
                    <h2>Treating Practice</h2>
                    <button matTooltip="Add new practice" mat-icon-button (click)="openNewPracticeDialog()">
                        <mat-icon color="primary">add_business</mat-icon>
                    </button>
                </div>
                <div>
                    <mat-form-field appearance="fill" class="practice-field" *ngIf="customer.practices?.length; else noPractices">
                        <mat-label>Treating Practice</mat-label>
                        <mat-select formControlName="practice" [compareWith]="compareFunction">
                            <mat-option *ngFor="let practice of customer?.practices" [value]="practice">
                                {{ practice.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-template #noPractices>Your account has no practices yet, create one to continue</ng-template>
                </div>
            </div>
            <button mat-raised-button color="primary" [disabled]="newPatientForm.invalid" (click)="onSubmit()">Create new patient</button>
        </form>
    </mat-card-content>
</mat-card>

<ng-template #newPracticeDialog>
    <h1 mat-dialog-title>Add new practice</h1>
    <div mat-dialog-content class="new-practice-dialog-content">
        <form [formGroup]="newPracticeForm">
            <mat-form-field appearance="fill">
                <mat-label>Practice name</mat-label>
                <input formControlName="name" matInput />
                <mat-error *ngIf="newPracticeForm.controls.name.hasError('required')">Practice name is required.</mat-error>
            </mat-form-field>
            <div class="address-block">
                <mat-form-field appearance="fill">
                    <mat-label>Street</mat-label>
                    <input formControlName="street" matInput />
                    <mat-error *ngIf="newPracticeForm.controls.street.hasError('required')">Street is required.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Housenumber</mat-label>
                    <input formControlName="housenumber" matInput />
                    <mat-error *ngIf="newPracticeForm.controls.housenumber.hasError('required')">Housenumber is required.</mat-error>
                    <mat-error *ngIf="newPracticeForm.controls.housenumber.hasError('pattern')">Not a valid housenumber.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Street 2 (optional)</mat-label>
                    <input formControlName="street2" matInput />
                </mat-form-field>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Postal code</mat-label>
                        <input formControlName="postalcode" matInput />
                        <mat-error *ngIf="newPracticeForm.controls.postalcode.hasError('required')">Postal code is required.</mat-error>
                        <mat-error *ngIf="newPracticeForm.controls.postalcode.hasError('pattern')">Not a valid postal code.</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>City</mat-label>
                        <input formControlName="city" matInput />
                        <mat-error *ngIf="newPracticeForm.controls.city.hasError('required')">City is required.</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>Country</mat-label>
                    <input formControlName="country" matInput />
                </mat-form-field>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="button-wrapper">
        <button mat-button mat-dialog-close>Cancel</button>
        <button color="primary" [disabled]="newPracticeForm.invalid" [mat-dialog-close]="true" mat-raised-button>Add</button>
    </div>
</ng-template>
