<mat-toolbar>
  <div class="title-box">
    <mat-icon class="title-icon" matListItemIcon>payments</mat-icon>
    <span class="subtitle">My invoices</span>
  </div>
</mat-toolbar>
@if (admin$()) {
  <mat-tab-group
    mat-stretch-tabs="false"
    animationDuration="300ms"
    [selectedIndex]="selectedTabIndex"
    (selectedTabChange)="onTabChange($event)"
    >
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">list</mat-icon>
        List
      </ng-template>
      <ng-container *ngTemplateOutlet="invoicesList"></ng-container>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">post_add</mat-icon>
        Create new invoice
      </ng-template>
      <app-create-invoice></app-create-invoice>
    </mat-tab>
  </mat-tab-group>
} @else {
  <ng-container *ngTemplateOutlet="invoicesList"></ng-container>
}
<ng-template #invoicesList>
  <mat-form-field class="table-filter" floatLabel="always" subscriptSizing="dynamic">
    <mat-label>Filter</mat-label>
    <mat-chip-grid #chipGrid>
      @for (filter of filterChips(); track filter) {
        <mat-chip-row color="primary" highlighted (removed)="removeFilter(filter)">
          {{ filter }}
          <button matChipRemove [attr.aria-label]="'remove ' + filter">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        placeholder="e.g. by ID, practice, month"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addFilter($event)"
        />
    </mat-chip-grid>
  </mat-form-field>
  <div class="mat-table-wrapper">
    <table
      (matSortChange)="onSortChange($event)"
      mat-table
      matTableResponsive
      [dataSource]="dataSource"
      [trackBy]="trackByFunctionalID"
      class="mat-elevation-z8"
      matSort
      >
      <ng-container matColumnDef="functionalId">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">
          <div class="icon-id-container">
            <mat-icon matListItemIcon [ngClass]="element.paidAt ? 'icon-paid' : 'icon-not-paid'">receipt_long</mat-icon>
            #{{ element.functionalId }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="practice">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Practice</th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.practice.name }}</td>
      </ng-container>

      <ng-container matColumnDef="invoiceDate">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Invoice date</th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.createdAt | timestamp }}</td>
      </ng-container>

      <ng-container matColumnDef="yearMonth">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Invoiced month</th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ getFullMonthName(element.yearMonth) }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">
          {{ element.totals.totalWithDiscount ?? element.totals.total | currency: 'EUR' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; dataSource: dataSource">
          @if (admin$()) {
            @if (element.paidAt) {
              <button
                mat-icon-button
                class="icon-not-paid"
                matTooltip="Mark invoice as not paid"
                matTooltipPosition="left"
                (click)="markInvoiceUnpaid(element, $event)"
                >
                <mat-icon>money_off</mat-icon>
              </button>
            } @else {
              <button
                mat-icon-button
                matTooltip="Mark invoice as paid"
                matTooltipPosition="left"
                class="icon-paid"
                (click)="markInvoicePaid(element, $event)"
                >
                <mat-icon>price_check</mat-icon>
              </button>
            }
            <button
              matTooltipPosition="left"
              mat-icon-button
              matTooltip="Delete invoice"
              class="icon-delete"
              (click)="deleteInvoice(element, $event)"
              >
              <mat-icon>delete</mat-icon>
            </button>
          }
        </td>
      </ng-container>

      <!-- Row when no data matches the selected filters -->
      <tr class="mat-row" *matNoDataRow>
        @if (filterChips().length) {
          <td class="mat-cell no-data" colspan="4">
            No invoices found matching the filter(s) "{{ filterChips().join(' ') }}"
          </td>
        }
        @if (!filterChips().length) {
          <td class="mat-cell no-data" colspan="4">No invoices found!</td>
        }
      </tr>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr
        class="table-row"
        mat-row
        *matRowDef="let row; columns: columns"
        (click)="routeToInvoice(row)"
        [ngClass]="row.paidAt ? 'invoice-paid' : 'invoice-not-paid'"
      ></tr>
    </table>
  </div>

  <ng-template #markInvoiceDialog let-data>
    <h2 mat-dialog-title>Mark invoice as {{ data.mark }}</h2>
    <mat-dialog-content>
      <p>
        Are you sure you want to mark this invoice as <b>{{ data.mark }}</b
        >?
      </p>
      <span>
        #{{ data.invoice.functionalId }} <br />
        Customer: {{ data.invoice.customer.firstName }} {{ data.invoice.customer.lastName }} <br />
        Practice: {{ data.invoice.practice.name }} <br />
        @if (data.invoice.totals.totalWithDiscount) {
          Total amount with discount:{{ data.invoice.totals.total | currency: 'EUR' }} -
          {{ data.invoice.totals.discountAmount | currency: 'EUR' }} =
          {{ data.invoice.totals.totalWithDiscount | currency: 'EUR' }}
        } @else {
          Amount: {{ data.invoice.totals.total | currency: 'EUR' }}
        }
      </span>
    </mat-dialog-content>
    <mat-dialog-actions class="buttons-between">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
    </mat-dialog-actions>
  </ng-template>
  <ng-template #deleteInvoiceDialog let-data>
    <h2 mat-dialog-title>Delete invoice</h2>
    <mat-dialog-content>
      <p>Are you sure you want to <b>DELETE</b> this invoice?</p>
      <b>All orders on this monthly invoice will be marked as pending and visible in the 'Create invoice' list</b>
      <mat-divider style="margin: 1rem"></mat-divider>
      <span>
        #{{ data.invoice.functionalId }} <br />
        Customer: {{ data.invoice.customer.firstName }} {{ data.invoice.customer.lastName }} <br />
        Practice: {{ data.invoice.practice.name }} <br />
        @if (data.invoice.totals.totalWithDiscount) {
          Total amount with discount:{{ data.invoice.totals.total | currency: 'EUR' }} -
          {{ data.invoice.totals.discountAmount | currency: 'EUR' }} =
          {{ data.invoice.totals.totalWithDiscount | currency: 'EUR' }}
        } @else {
          Amount: {{ data.invoice.totals.total | currency: 'EUR' }}
        }
      </span>
    </mat-dialog-content>
    <mat-dialog-actions class="buttons-between">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
    </mat-dialog-actions>
  </ng-template>
</ng-template>
