<h1 mat-dialog-title>Create invoice</h1>
<mat-dialog-content>
  <p>Create monthly invoice for {{ data.customer.firstName }} {{ data.customer.lastName }}?</p>
  @if (data.totals.discount) {
    <table class="discount-table">
      <tr>
        <td>Total:</td>
        <td>{{ data.totals.total | currency: 'EUR' }}</td>
      </tr>
      <tr>
        <td>Discount:</td>
        <td>{{ data.totals.discount | percent }}</td>
      </tr>
      <tr>
        <td>Total with discount:</td>
        <td>{{ data.totals.totalWithDiscount | currency: 'EUR' }}</td>
      </tr>
    </table>
  } @else {
    <p>Total: {{ data.totals.total | currency: 'EUR' }}</p>
  }
  <mat-divider></mat-divider>
  <mat-form-field appearance="fill" class="full-flex-width">
    <mat-label>Email</mat-label>
    <input [formControl]="email" matInput placeholder="Email" type="email" />
    @if (email.invalid) {
      <mat-error>Please enter a valid email address</mat-error>
    }
    @if (prefilled) {
      <mat-hint>Preferred invoice email address pre-filled!</mat-hint>
    } @else {
      <mat-hint>No invoice email found, pre-filled with customer account email</mat-hint>
    }
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="buttons-between">
  <button mat-stroked-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button color="primary" mat-raised-button [disabled]="email.invalid" [mat-dialog-close]="{ email: email.value }">Create invoice</button>
</mat-dialog-actions>
