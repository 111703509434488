import { Component, TemplateRef, ViewChild, type AfterViewInit } from '@angular/core';
import { Firestore, doc, getDoc, updateDoc } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { isEqual } from 'lodash';
import { distinctUntilChanged, map } from 'rxjs';
import { adminHashProtection } from '../admin-hash/admin-hash-protection.guard';
import { AuthService } from '../auth.service';
import type { Practice } from '../types';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements AfterViewInit {
    constructor(
        private readonly auth: AuthService,
        private readonly firestore: Firestore,
        public readonly dialog: MatDialog,
        private snackBar: MatSnackBar,
        private readonly router: Router,
    ) {}
    ngAfterViewInit(): void {
        this.auth.firestoreId$
            .pipe(
                map(async id => {
                    // TODO: This logic should be in customer service. And imported here.
                    this.firestoreId = id;
                    const document = doc(this.firestore, `/customers/${id}`);
                    const value = (await getDoc(document)).data();
                    if (value) {
                        // Bij het invullen van huidige data geen event emitten om te voorkomen dat we onnodig nog een keer data opslaan.
                        this.profileForm.patchValue(value, { emitEvent: false });
                        // TODO: Fix index signature...Customer type gebruiken.
                        this.customerId = value['customerId'];
                    }
                }),
            )
            // FIXME: Takeuntil destroy
            .subscribe();

        // FIXME: Takeuntil destroy
        this.pForm.defaultPractice.valueChanges.pipe(distinctUntilChanged(isEqual)).subscribe(async v => {
            if (this.pForm.defaultPractice.valid) {
                const docRef = doc(this.firestore, `/customers/${this.firestoreId}`);
                await updateDoc(docRef, {
                    defaultPractice: v,
                });
            }
        });
    }

    @ViewChild('newPracticeDialog') newPracticeDialog!: TemplateRef<unknown>;
    @ViewChild('deletePracticeDialog') deletePracticeDialog!: TemplateRef<unknown>;
    dialogRef?: MatDialogRef<any>;
    firestoreId?: string;
    customerId?: string;

    admin$ = this.auth.adminSignal$;

    editingExtraPassword = false;

    // Aanmaken extra praktijk
    newPracticeForm = new FormGroup({
        name: new FormControl('', { nonNullable: true, validators: Validators.required }),
        city: new FormControl('', { nonNullable: true, validators: Validators.required }),
        country: new FormControl('', { nonNullable: true }),
        postalcode: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.pattern(/^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i)],
        }),
        housenumber: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')] }),
        street: new FormControl('', { nonNullable: true, validators: Validators.required }),
        street2: new FormControl(''),
    });

    // Checkbox om gelijk nieuwe praktijk default te maken.
    defaultPracticeControl = new FormControl(false, Validators.required);

    profileForm = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        phonenumber: new FormControl('', [Validators.required, Validators.pattern('[0-9]{0,15}')]),
        invoicesEmail: new FormControl('', [Validators.email]),
        adminHash: new FormControl('', [Validators.minLength(8)]),
        defaultPractice: new FormControl<Practice | null>(null),
        practices: new FormControl([] as Practice[]),
    });

    compareFunction(o1: any, o2: any) {
        return o1.name == o2.name && o1.id == o2.id;
    }

    /** For cleaner template references */
    get pForm() {
        return this.profileForm.controls;
    }

    openNewPracticeDialog() {
        this.dialogRef = this.dialog.open(this.newPracticeDialog);
    }
    onCancel() {
        this.dialogRef?.close();
    }

    private fieldsToCheck = ['firstName', 'lastName', 'phonenumber', 'invoicesEmail', 'adminHash'] as const;
    personalInfoInvalid() {
        return this.fieldsToCheck.some(field => this.pForm[field].invalid);
    }
    personalInfoDirty() {
        return this.fieldsToCheck.some(field => this.pForm[field].dirty);
    }
    async savePersonalInfo() {
        const docRef = doc(this.firestore, `/customers/${this.firestoreId}`);
        await updateDoc(docRef, {
            firstName: this.pForm.firstName.value,
            lastName: this.pForm.lastName.value,
            phonenumber: this.pForm.phonenumber.value,
            invoicesEmail: this.pForm.invoicesEmail.value,
            adminHash: this.pForm.adminHash.value,
        });
        this.profileForm.markAsPristine();
        this.snackBar.open('Personal info saved!', 'close', { duration: 5000 });
    }

    openDeletePracticeDialog(practice: Practice) {
        this.dialogRef = this.dialog.open(this.deletePracticeDialog, { data: practice });
    }

    async deletePractice(practice: string) {
        const currentValue = this.pForm.practices.getRawValue();
        // Would be weird, but sanity checks cant hurt.
        if (!currentValue) return;

        const newValue = currentValue.filter(v => v.name !== practice);
        this.pForm.practices.setValue(newValue);

        // Update firestore, remove defaultPractice if this was the last Practice in the array.
        const payload =
            newValue.length > 0
                ? {
                      practices: newValue,
                  }
                : {
                      practices: newValue,
                      defaultPractice: null,
                  };
        const docRef = doc(this.firestore, `/customers/${this.firestoreId}`);
        await updateDoc(docRef, payload);

        this.dialogRef?.close();
    }
    async addPractice() {
        const practice = this.newPracticeForm.value as Practice;
        const defaultPractice = this.defaultPracticeControl.value;
        if (!practice) return;
        // Get current value
        const current = this.pForm.practices.value;
        // If there are multiple practices, only set default when asked
        if (current?.length) {
            this.pForm.practices.setValue([...current, practice]);
            if (defaultPractice) {
                this.pForm.defaultPractice.setValue(practice);
            }
        } else {
            // If its the first practice added, also make it the default one.
            this.pForm.practices.setValue([practice]);
            this.pForm.defaultPractice.setValue(practice);
        }
        const docRef = doc(this.firestore, `/customers/${this.firestoreId}`);
        await updateDoc(docRef, {
            defaultPractice: this.pForm.defaultPractice.value,
            practices: this.pForm.practices.value,
        });
        this.dialogRef?.close();
        // Reset form.
        this.newPracticeForm.reset();
        this.snackBar.open(`Succesfully added new practice!`, 'close', { duration: 5000 });
    }

    async changeExtraSecurity() {
        if (
            await adminHashProtection({
                authService: this.auth,
                firestore: this.firestore,
                dialog: this.dialog,
                snack: this.snackBar,
                router: this.router,
                allowUndefined: true,
            })
        ) {
            this.editingExtraPassword = true;
        }
    }
}
