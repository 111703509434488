<mat-toolbar>
  <span class="subtitle"
    >Profile @if (customerId) {
    <span>for customer ID #{{ customerId }}</span>
    }</span
    >
  </mat-toolbar>
  <mat-divider></mat-divider>
  <mat-card>
    <form [formGroup]="profileForm">
      <h2>
        Personal information
        @if (personalInfoDirty()) {
          <button
            (click)="savePersonalInfo()"
            [disabled]="personalInfoInvalid()"
            color="accent"
            mat-raised-button
            >
            Save changes
          </button>
        }
      </h2>
      <div class="name-block">
        <mat-form-field appearance="fill">
          <mat-label>First name</mat-label>
          <input formControlName="firstName" matInput />
          @if (pForm.firstName.hasError('required')) {
            <mat-error>First name is required.</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Last name</mat-label>
          <input formControlName="lastName" matInput />
          @if (pForm.lastName.hasError('required')) {
            <mat-error>Last name is required.</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="name-block">
        <mat-form-field appearance="fill">
          <mat-label>Phone number</mat-label>
          <input #phoneinput formControlName="phonenumber" matInput />
          <mat-icon matSuffix>phone</mat-icon>
          @if (pForm.phonenumber.hasError('pattern')) {
            <mat-error>Only numbers are allowed (max 15)</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Invoice email address</mat-label>
          <mat-icon matSuffix matTooltip="The email address you want to receive invoices on">info</mat-icon>
          <input formControlName="invoicesEmail" matInput />
          @if (pForm.invoicesEmail.hasError('email')) {
            <mat-error>Not a valid email address</mat-error>
          }
        </mat-form-field>
      </div>
      @if (!editingExtraPassword && admin$()) {
        <button mat-raised-button (click)="changeExtraSecurity()">Change extra admin password</button>
      }
      @if (admin$() && editingExtraPassword) {
        <div class="name-block">
          <mat-form-field appearance="fill">
            <mat-label>Extra security password</mat-label>
            <input formControlName="adminHash" matInput type="password" />
            @if (pForm.adminHash.hasError('minlength')) {
              <mat-error>Password must be at least 8 characters long</mat-error>
            }
          </mat-form-field>
        </div>
      }
      <div class="subtitle-with-button">
        <h2>Practices</h2>
        <button matTooltip="Add new practice" mat-icon-button (click)="openNewPracticeDialog()">
          <mat-icon color="primary">add_business</mat-icon>
        </button>
      </div>
      @if (pForm.practices.value?.length) {
        <mat-accordion>
          @for (practice of pForm.practices.value; track practice; let i = $index) {
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> {{ practice.name }} </mat-panel-title>
              </mat-expansion-panel-header>
              <span matListItemLine>{{ practice.street }} {{ practice.housenumber }}</span>
              @if (practice.street2) {
                <span matListItemLine>{{ practice.street2 }}</span>
              }
              <span matListItemLine>{{ practice.postalcode }}</span>
              <span matListItemLine>{{ practice.city }}</span>
              <span matListItemLine>{{ practice.country }}</span>
              <button mat-raised-button (click)="openDeletePracticeDialog(practice)">Remove practice</button>
            </mat-expansion-panel>
          }
        </mat-accordion>
      } @else {
        <h3>Your profile has no practices yet, click the icon above to add one!</h3>
      }
      <h2 class="defaults-title">Profile defaults</h2>
      <div class="profile-defaults">
        @if (pForm.practices.value?.length) {
          <div class="info-wrapper">
            <mat-icon matTooltip="This practice will be set by default when creating a new order">info</mat-icon>
            <mat-form-field appearance="fill">
              <mat-label>Select Default Practice</mat-label>
              <mat-select formControlName="defaultPractice" [compareWith]="compareFunction">
                @for (practice of pForm.practices.value; track practice) {
                  <mat-option [value]="practice">
                    {{ practice.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        }
      </div>
    </form>
  </mat-card>


  <ng-template #deletePracticeDialog let-data>
    <h1 mat-dialog-title>Delete practice</h1>
    <div mat-dialog-content>
      <span matListItemLine>{{ data.street }} {{ data.housenumber }}</span>
      @if (data.street2) {
        <span matListItemLine>{{ data.street2 }}</span>
      }
      <span matListItemLine>{{ data.postalcode }}</span>
      <span matListItemLine>{{ data.city }}</span>
      <span matListItemLine>{{ data.country }}</span>
      <p>This deletes the practice from your profile, are you sure?</p>
    </div>
    <div mat-dialog-actions class="button-wrapper">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button color="primary" mat-raised-button (click)="deletePractice(data.name)">Delete</button>
    </div>
  </ng-template>

  <!-- TODO: naar los component -->
  <ng-template #newPracticeDialog>
    <h1 mat-dialog-title>Add new practice</h1>
    <div mat-dialog-content class="new-practice-dialog-content">
      <form [formGroup]="newPracticeForm">
        <mat-form-field appearance="fill">
          <mat-label>Practice name</mat-label>
          <input formControlName="name" matInput />
          @if (newPracticeForm.controls.name.hasError('required')) {
            <mat-error>Practice name is required.</mat-error>
          }
        </mat-form-field>
        <div class="address-block">
          <mat-form-field appearance="fill">
            <mat-label>Street</mat-label>
            <input formControlName="street" matInput />
            @if (newPracticeForm.controls.street.hasError('required')) {
              <mat-error>Street is required.</mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Housenumber</mat-label>
            <input formControlName="housenumber" matInput />
            @if (newPracticeForm.controls.housenumber.hasError('required')) {
              <mat-error>Housenumber is required.</mat-error>
            }
            @if (newPracticeForm.controls.housenumber.hasError('pattern')) {
              <mat-error>Not a valid housenumber.</mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Street 2 (optional)</mat-label>
            <input formControlName="street2" matInput />
          </mat-form-field>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>Postal code</mat-label>
              <input formControlName="postalcode" matInput />
              @if (newPracticeForm.controls.postalcode.hasError('required')) {
                <mat-error>Postal code is required.</mat-error>
              }
              @if (newPracticeForm.controls.postalcode.hasError('pattern')) {
                <mat-error>Not a valid postal code.</mat-error>
              }
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>City</mat-label>
              <input formControlName="city" matInput />
              @if (newPracticeForm.controls.city.hasError('required')) {
                <mat-error>City is required.</mat-error>
              }
            </mat-form-field>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>Country</mat-label>
            <input formControlName="country" matInput />
          </mat-form-field>
        </div>
      </form>
      @if (pForm.practices.value?.length) {
        <mat-checkbox [formControl]="defaultPracticeControl"
          >Make new practice also the default practice?</mat-checkbox
          >
        }
      </div>
      <div mat-dialog-actions class="button-wrapper">
        <button mat-button (click)="onCancel()">Cancel</button>
        <button color="primary" [disabled]="newPracticeForm.invalid" mat-raised-button (click)="addPractice()">Add</button>
      </div>
    </ng-template>
