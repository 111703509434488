import { Pipe, PipeTransform } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from './auth.service';
import { OrderStatus, ProductionSubStatus } from './types';

@Pipe({
    name: 'productionSubStatus',
})
export class ProductionSubStatusPipe implements PipeTransform {
    constructor(private readonly auth: AuthService) {}
    async transform(value: OrderStatus): Promise<OrderStatus> {
        const admin = await firstValueFrom(this.auth.admin$);
        // Admins can see the real status
        if (admin) {
            return value;
        }
        // Non-admins see a simplified status of the production flow
        if (isProductionSubStatus(value)) {
            return 'In production';
        }
        return value;
    }
}

function isProductionSubStatus(status: OrderStatus): status is ProductionSubStatus {
    return ['Partly Printed', 'Printed', 'Vacuumed/Cut', 'Packed'].includes(status);
}
