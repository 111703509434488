import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, DestroyRef, Injector, TemplateRef, ViewChild, effect, inject, signal, type AfterViewInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import type { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import type { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { InvoiceService } from '../invoice.service';
import type { Invoice } from '../types';

@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements AfterViewInit {
    selectedTabIndex = 0;
    constructor(
        private readonly auth: AuthService,
        private readonly injector: Injector,
        private readonly router: Router,
        private readonly invoiceService: InvoiceService,
        private readonly datePipe: DatePipe,
        private readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar,
        private readonly route: ActivatedRoute,
    ) {
        effect(() => {
            const invoices = this.invoicesSignal$();
            if (invoices == null) return;
            // Update the data source with the orders
            this.dataSource.data = invoices;
        });

        // Read the query parameter and set the active tab
        this.route.queryParams.pipe(takeUntilDestroyed()).subscribe(params => {
            const tab = params['tab'];
            if (tab) {
                this.selectedTabIndex = +tab;
            }
        });
    }

    /**
     * A subject that emits a value when the component is destroyed.
     * This is typically used to unsubscribe from observables to prevent memory leaks.
     */
    destroy$ = inject(DestroyRef);
    admin$ = toSignal(this.auth.admin$, { initialValue: false });
    dataSource = new MatTableDataSource<Invoice>();
    columns = ['functionalId', 'practice', 'invoiceDate', 'yearMonth', 'amount', 'actions'];

    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild('markInvoiceDialog') markInvoiceDialog!: TemplateRef<unknown>;
    @ViewChild('deleteInvoiceDialog') deleteInvoiceDialog!: TemplateRef<unknown>;

    ngAfterViewInit() {
        // Update session storage and filter predicate when the filter chips change
        effect(
            () => {
                // TODO: Setup a generic util so we can harness routing parameters instead of localStorage.
                // This way we can use the back button to go back to the previous filter state and share the link with others
                sessionStorage.setItem('orders.filterChips', JSON.stringify(this.filterChips()));
                this.dataSource.sort = this.sort;
                this.dataSource.filterPredicate = (data: Invoice, _filter: string) => {
                    const filterArray = this.filterChips();
                    // Parse yearMonth to full month name
                    const yearMonth = this.getFullMonthName(data.yearMonth);
                    // Parse paidAt to either string 'Paid' or 'Pending'. We use 'Pending' instead of 'Unpaid' cause it cant have the word 'paid' in it for the filter to work.
                    const paidAt = data.paidAt ? 'Paid' : 'Pending';
                    const dataString = `${data.functionalId}${data.customer.firstName}${data.customer.lastName}${data.practice.name}${yearMonth}${data.totals.total}${data.totals.totalWithDiscount}${paidAt}`;
                    return filterArray.every(filter => dataString.toLowerCase().includes(filter));
                };
                this.dataSource.filter = this.filterChips().join(' ');
            },
            { injector: this.injector },
        );
    }

    /**
     * Handles the change in sorting state for the invoices data.
     *
     * @param sortState - The current state of sorting, including the active sort field and direction.
     *
     * The function sorts the `dataSource.data` array based on the active sort field:
     * - 'amount': Sorts by the total amount, considering any discounts.
     * - 'practice': Sorts by the practice name.
     * - 'invoiceDate': Sorts by the invoice creation date.
     */
    onSortChange(sortState: Sort) {
        switch (sortState.active) {
            case 'amount':
                this.dataSource.data = this.dataSource.data.sort((a, b) => {
                    const amountA = a.totals.totalWithDiscount ?? a.totals.total;
                    const amountB = b.totals.totalWithDiscount ?? b.totals.total;
                    return (amountA - amountB) * (sortState.direction === 'asc' ? 1 : -1);
                });
                break;
            case 'practice':
                this.dataSource.data = this.dataSource.data.sort((a, b) => {
                    return a.practice.name.localeCompare(b.practice.name) * (sortState.direction === 'asc' ? 1 : -1);
                });
                break;
            case 'invoiceDate':
                this.dataSource.data = this.dataSource.data.sort((a, b) => {
                    const dateA = a.createdAt.toDate();
                    const dateB = b.createdAt.toDate();
                    return (dateA.getTime() - dateB.getTime()) * (sortState.direction === 'asc' ? 1 : -1);
                });
                break;
            case 'yearMonth':
                this.dataSource.data = this.dataSource.data.sort((a, b) => {
                    const dateA = new Date(a.yearMonth + '-01'); // Convert yearMonth to date object
                    const dateB = new Date(b.yearMonth + '-01'); // Convert yearMonth to date object
                    return (dateA.getTime() - dateB.getTime()) * (sortState.direction === 'asc' ? 1 : -1);
                });
                break;
        }
    }

    invoicesSignal$ = toSignal(
        combineLatest([this.auth.firestoreId$, this.auth.admin$]).pipe(
            switchMap(([_customerFirestoreID]) => {
                if (this.admin$()) {
                    return this.invoiceService.getInvoices();
                } else {
                    // TODO: Implement getInvoicesForCustomer
                    return this.invoiceService.getInvoices();
                }
            }),
        ),
    );

    // Method to format yearMonth to full month name
    getFullMonthName(yearMonth: string): string {
        const [year, month] = yearMonth.split('-');
        const date = new Date(Number(year), Number(month) - 1); // Months are 0-indexed in JavaScript Date
        return this.datePipe.transform(date, "MMMM  ''yy") || '';
    }

    // Filter chips
    filterChips = signal<string[]>([]);
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    addFilter(event: MatChipInputEvent) {
        const filterValue = event.value.trim().toLowerCase();
        if (!filterValue) {
            return;
        }
        this.filterChips.update(current => [...current, filterValue]);
        // Clear the input value after adding the chip
        event.chipInput!.clear();
    }
    removeFilter(filter: string) {
        this.filterChips.update(current => current.filter(v => v !== filter));
    }

    // Track by functional ID
    trackByFunctionalID(_index: number, invoice: Invoice) {
        return invoice.functionalId;
    }
    routeToInvoice(invoice: Invoice) {
        this.router.navigate(['/customers', invoice.customer.id, 'invoices', invoice.firestoreId]);
    }

    onTabChange(event: MatTabChangeEvent) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { tab: event.index },
            queryParamsHandling: 'merge', // Merge with existing query params
        });
    }

    async markInvoicePaid(invoice: Invoice, event: Event) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(this.markInvoiceDialog, { data: { mark: 'paid', invoice } });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.invoiceService.markInvoicePaid(invoice.customer.id, invoice.firestoreId);
                this.snackbar.open('Invoice marked as paid!', 'close', { duration: 5000 });
            }
        });
    }
    async markInvoiceUnpaid(invoice: Invoice, event: Event) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(this.markInvoiceDialog, { data: { mark: 'not paid', invoice } });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.invoiceService.markInvoiceUnpaid(invoice.customer.id, invoice.firestoreId);
                this.snackbar.open('Invoice marked as not paid!', 'close', { duration: 5000 });
            }
        });
    }

    async deleteInvoice(invoice: Invoice, event: Event) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(this.deleteInvoiceDialog, { data: { invoice } });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.invoiceService.deleteInvoice({ customerId: invoice.customer.id, invoiceFirestoreId: invoice.firestoreId });
                this.snackbar.open('Invoice deleted!', 'close', { duration: 5000 });
            }
        });
    }
}
