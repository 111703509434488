<mat-toolbar> <mat-icon class="title-icon" matListItemIcon>settings</mat-icon> <span class="subtitle">App settings</span> </mat-toolbar>
<mat-card>
  <form [formGroup]="settingsForm">
    <h2>Notifications</h2>
    <p><mat-checkbox formControlName="orderNotifications">Turn on email notifications for every order created</mat-checkbox></p>
    <mat-form-field>
      <mat-label>Notification mailbox</mat-label>
      <input matInput placeholder="info@example.com" formControlName="notificationMailbox" />
      @if (settingsForm.controls.notificationMailbox.invalid) {
        <mat-error>Not a valid email</mat-error>
      }
    </mat-form-field>
    <h2>Order types</h2>
    <div class="order-type-settings">
      @for (orderType of settingsForm.controls.orderDefaults.controls | keyvalue: originalOrder; track orderType) {
        <div
          formGroupName="orderDefaults"
          class="per-order-block"
          >
          <h3>{{ orderType.key }}</h3>
          @for (orderTypeSettings of orderType.value.controls | keyvalue; track orderTypeSettings) {
            <div>
              @if (isSimpleFormType(orderType.value.controls)) {
                <mat-form-field
                  formGroupName="{{ orderType.key }}"
                  >
                  <mat-label>{{ orderTypeSettings.key }}</mat-label>
                  <input matInput type="number" inputmode="numeric" [formControlName]="orderTypeSettings.key" />
                  <span matTextSuffix>{{ orderTypeSettings.key === 'deadline' ? 'days' : '\u20AC' }}</span>
                  @if (orderTypeSettings.value.hasError('pattern')) {
                    <mat-error>Only numbers allowed</mat-error>
                  }
                </mat-form-field>
              } @else {
                @if (orderTypeSettings.key === 'deadline') {
                  <mat-form-field formGroupName="{{ orderType.key }}">
                    <mat-label>{{ orderTypeSettings.key }}</mat-label>
                    <input min="1" matInput type="number" inputmode="numeric" [formControlName]="orderTypeSettings.key" />
                    <span matTextSuffix>{{ orderTypeSettings.key === 'deadline' ? 'days' : '\u20AC' }}</span>
                    @if (orderTypeSettings.value.hasError('pattern')) {
                      <mat-error>Only numbers allowed</mat-error>
                    }
                    @if (orderTypeSettings.value.hasError('min')) {
                      <mat-error>Minimum of 1</mat-error>
                    }
                  </mat-form-field>
                }
                <!-- Actual pricing thresholds -->
                @if (orderTypeSettings.key === 'pricing') {
                  <div>
                    <button mat-stroked-button (click)="openThresholdsDialog(orderType.key, orderTypeSettings.value)">
                      <mat-icon>euro</mat-icon>View thresholds
                    </button>
                  </div>
                }
              }
              <!-- Templating with thresholds -->
            </div>
          }
        </div>
      }
    </div>
    <mat-card-actions>
      <button mat-raised-button color="primary" type="submit" [disabled]="settingsForm.invalid" (click)="saveSettings()">
        Save settings
      </button>
    </mat-card-actions>
  </form>
</mat-card>

<ng-template #thresholdsDialog let-data>
  <p mat-dialog-title>Thresholds for {{ data.orderType }}</p>
  <div mat-dialog-content>
    <mat-list>
      @for (threshold of castToArrayType(data.orderPricing.value); track threshold; let i = $index) {
        <mat-list-item>
          <span matListItemTitle
            >From <b>{{ threshold.min }}</b> product(s)</span
            >
            <span matListItemLine>{{ threshold.price.single | currency: 'EUR' }} for single arch</span>
            <span matListItemLine>{{ threshold.price.double | currency: 'EUR' }} for double arch</span>
            <button matListItemMeta mat-icon-button matTooltip="Delete threshold" (click)="removeThreshold(data.orderPricing, i)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-list-item>
        }
      </mat-list>
      <button mat-raised-button (click)="openAddThresholdDialog()">New threshold</button>
      <ng-template #addThresholdDialog>
        <p mat-dialog-title>New threshold for {{ data.orderType }}</p>
        <form mat-dialog-content [formGroup]="thresholdForm">
          <mat-form-field>
            <mat-label>Minimum amount of product(s)</mat-label>
            <input matInput placeholder="Minimum" formControlName="min" type="number" inputmode="numeric" />
          </mat-form-field>
          <div formGroupName="price">
            <mat-form-field>
              <mat-label>Price for single arch</mat-label>
              <input matInput placeholder="Single Arch" formControlName="single" type="number" inputmode="numeric" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Price for double arch</mat-label>
              <input matInput placeholder="Double Arch" formControlName="double" type="number" inputmode="numeric" />
            </mat-form-field>
          </div>
          <div mat-dialog-actions>
            <button mat-dialog-close mat-button>Close</button>
            <button mat-dialog-close color="primary" mat-raised-button (click)="addThreshold(data.orderPricing)">Add</button>
          </div>
        </form>
      </ng-template>
    </div>
    <div mat-dialog-actions>
      <button mat-dialog-close mat-button>Close</button>
      <button mat-dialog-close color="primary" mat-raised-button (click)="saveSettings()">Save</button>
    </div>
  </ng-template>
