<mat-toolbar><span>View patient design</span></mat-toolbar>
<div class="main-wrapper" [ngClass]="codeVerified ? 'fullsize' : ''">
    <mat-card *ngIf="error">
        <mat-card-header>
            <mat-card-title>Error</mat-card-title>
        </mat-card-header>
        <mat-card-content matError>{{ error }}</mat-card-content>
    </mat-card>
    <mat-card *ngIf="codeVerified; else codeForm" class="patient-design" [ngClass]="codeVerified ? 'fullsize' : ''">
        <mat-card-header>
            <mat-card-title>Design</mat-card-title>
            <mat-card-subtitle>Design for patient</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <iframe class="model-iframe" [src]="designURL"></iframe>
        </mat-card-content>
    </mat-card>
    <ng-template #codeForm>
        <mat-card class="code-card" *ngIf="!error">
            <mat-card-header>
                <mat-card-title>Enter code</mat-card-title>
                <mat-card-subtitle>Enter code to view patient design</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field>
                    <input [formControl]="code" matInput placeholder="Code" (keyup.enter)="verifyCode()" />
                    <mat-error *ngIf="code.hasError('required')">Code is required</mat-error>
                    <mat-error *ngIf="code.hasError('invalidCode')">Wrong code</mat-error>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" (click)="verifyCode()">Verify code</button>
            </mat-card-actions>
        </mat-card>
    </ng-template>
</div>
