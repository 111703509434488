<mat-toolbar>Smile Art</mat-toolbar>
<mat-card>
    <mat-card-header>
        <mat-card-title>
            <div *ngIf="error; else happyTitle">
                <div class="error-message">
                    <mat-icon>error</mat-icon><span>{{ mode }} failed</span>
                </div>
            </div>
            <ng-template #happyTitle>
                <ng-container [ngSwitch]="mode">
                    <p *ngSwitchCase="'verifyEmail'">Email verification complete!</p>
                    <p *ngSwitchCase="'resetPassword'">Reset password</p>
                </ng-container>
            </ng-template>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngIf="error; else happy">
            <p>Details: {{ error }}</p>
            <p>Using the button below you can retry the process.</p>
        </div>
        <ng-template #happy>
            <ng-container [ngSwitch]="mode">
                <div *ngSwitchCase="'resetPassword'" class="password-form">
                    <mat-form-field>
                        <mat-label>New password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" [formControl]="newPasswordForm.controls.password" />
                        <button
                            tabindex="-1"
                            mat-icon-button
                            matSuffix
                            type="button"
                            (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide"
                        >
                            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <mat-error *ngIf="newPasswordForm.controls.password.hasError('required')">Password is required.</mat-error>
                        <mat-error *ngIf="newPasswordForm.controls.password.hasError('minlength')"
                            >Password must be at least 8 characters long.</mat-error
                        >
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Confirm new Password</mat-label>
                        <input matInput type="password" [formControl]="newPasswordForm.controls.confirmPassword" />
                        <mat-error *ngIf="newPasswordForm.controls.confirmPassword.hasError('required')"
                            >Password confirmation is required.</mat-error
                        >
                        <mat-error *ngIf="newPasswordForm.controls.confirmPassword.hasError('mismatch')"
                            >Password confirmation does not match.</mat-error
                        >
                    </mat-form-field>
                </div>
            </ng-container>
        </ng-template>
    </mat-card-content>
    <mat-card-actions>
        <ng-container *ngIf="error; else happyActions" [ngSwitch]="mode">
            <button *ngSwitchCase="'verifyEmail'" mat-raised-button (click)="resendVerificationMail()">Re-send email</button>
            <button *ngSwitchCase="'resetPassword'" mat-raised-button routerLink="/">Retry</button>
        </ng-container>
        <ng-template #happyActions [ngSwitch]="mode">
            <button *ngSwitchCase="'verifyEmail'" mat-raised-button routerLink="{{ continueUrl }}">Continue</button>
            <button *ngSwitchCase="'resetPassword'" mat-raised-button (click)="resetPassword()" [disabled]="newPasswordForm.invalid">
                Confirm new password
            </button>
        </ng-template>
    </mat-card-actions>
</mat-card>
