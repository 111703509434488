<mat-toolbar>
    <span class="subtitle">Customer information</span>
    <div>
        <button class="table-settings" mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Change settings">
            <mat-icon>settings</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item aria-label="View draft orders" (click)="swapViewDraft()">
                <mat-icon class="icon-done" *ngIf="viewDraft.value" style="color: darkgreen">done</mat-icon>
                <mat-icon class="icon-off" *ngIf="!viewDraft.value" style="color: darkred">highlight_off</mat-icon>
                <span>View Draft orders</span>
            </button>
            <button mat-menu-item aria-label="View Send orders" (click)="swapViewSend()">
                <mat-icon class="icon-done" *ngIf="viewSend.value" style="color: darkgreen">done</mat-icon>
                <mat-icon class="icon-off" *ngIf="!viewSend.value" style="color: darkred">highlight_off</mat-icon>
                <span>View Send orders</span>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
<mat-divider></mat-divider>
<ng-container *ngIf="customer$ | async as customer">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ customer.firstName }}{{ customer.lastName }}</mat-card-title>
            <mat-card-subtitle>{{ customer.defaultPractice?.name }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="customer.defaultPractice">
            <p>
                {{ customer.defaultPractice.street }} {{ customer.defaultPractice.housenumber }} <br />
                <span *ngIf="customer.defaultPractice.street2">{{ customer.defaultPractice.street2 }}<br /></span>
                {{ customer.defaultPractice.postalcode }}
                {{ customer.defaultPractice.city }}
            </p>
        </mat-card-content>
    </mat-card>
</ng-container>
<mat-form-field class="table-filter">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input />
</mat-form-field>
<ng-container>
    <table mat-table matTableResponsive [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <!-- TODO: Fix these horrendous ternary expressions and map from the observable -->
                <mat-icon [style]="{ color: getIconColor(element.status) }">{{ mapToIcon(element.status) }}</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Date of creation</th>
            <td mat-cell *matCellDef="let element">
                {{ element.creationDate ? (element.creationDate | timestamp: 'd MMM, y, HH:mm:ss') : 'unknown' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="fullname">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Patient</th>
            <td mat-cell *matCellDef="let element">{{ element.fullname }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
            <td *ngIf="input.value" class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
            <td *ngIf="!input.value" class="mat-cell" colspan="4">No orders found</td>
        </tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            class="table-row"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [routerLink]="['/customers', customerId, 'orders', row.id]"
        ></tr>
    </table>
</ng-container>
