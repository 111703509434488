import { Component, Input, type OnInit } from '@angular/core';
import { AngularFireStorage, type AngularFireUploadTask } from '@angular/fire/compat/storage';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'upload-task',
    templateUrl: './upload-task.component.html',
    styleUrls: ['./upload-task.component.scss'],
})
export class UploadTaskComponent implements OnInit {
    @Input() file!: File;
    @Input() orderId?: string;
    @Input() customerId?: string;
    @Input() formcontrol?: FormControl;
    @Input() prefix?: number | string;

    task?: AngularFireUploadTask;

    percentage?: Observable<number | undefined>;
    snapshot?: Observable<any>;

    constructor(private storage: AngularFireStorage) {}

    ngOnInit() {
        this.startUpload();
    }

    startUpload() {
        if (!this.customerId || !this.orderId) {
            throw new Error(`Not enough customer information for uploading file. ${this.customerId} ${this.orderId}`);
        }
        // The storage path
        const path = `${this.customerId}/${this.orderId}/${this.prefix ? this.prefix + '_' : ''}${this.file.name}`;

        // The main task
        this.task = this.storage.upload(path, this.file);

        // Progress monitoring
        this.percentage = this.task.percentageChanges();

        // Notify formcontrol with uploaded task completion and path.
        this.snapshot = this.task.snapshotChanges().pipe(
            finalize(() => {
                const value = this.formcontrol?.value;
                if (Array.isArray(value)) {
                    this.formcontrol?.setValue([...value, path]);
                } else {
                    this.formcontrol?.setValue(path);
                }
            }),
        );
    }

    isActive(snapshot: any) {
        return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
    }
}
