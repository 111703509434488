import { Component, ViewChild } from '@angular/core';
import { Firestore, collection, collectionData } from '@angular/fire/firestore';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs';
import type { Customer } from '../types';
import { typeConverter } from '../util';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent {
    displayedColumns = ['practice', 'fullname', 'city', 'email'];
    dataSource!: MatTableDataSource<any>;
    @ViewChild(MatSort) sort?: MatSort;

    constructor(readonly firestore: Firestore) {
        const customers = collection(firestore, `customers`).withConverter(typeConverter<Customer & { id: string }>());
        collectionData(customers, { idField: 'id' })
            .pipe(map(v => v.map(customer => ({ ...customer, fullname: `${customer.firstName} ${customer.lastName}` }))))
            .subscribe(v => {
                this.dataSource = new MatTableDataSource(v);
                this.dataSource.sort = this.sort || null;
            });
    }

    /**
     * Applies a filter to the data source based on the input value.
     *
     * @param event The event object triggered by the input element.
     */
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
}
