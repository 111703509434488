import { Directive, ElementRef, Renderer2, type AfterViewInit, type OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, map, takeUntil } from 'rxjs';

/**
 * Directive for making a Material table responsive.
 * This directive observes changes in the table's thead and tbody sections,
 * and sets the "data-column-name" attribute for every body row cell based on the corresponding header cell.
 */
@Directive({
    selector: '[matTableResponsive]',
})
export class MatTableResponsiveDirective implements AfterViewInit, OnDestroy {
    private onDestroy$ = new Subject<boolean>();

    private thead!: HTMLTableSectionElement;
    private tbody!: HTMLTableSectionElement;

    private theadChanged$ = new BehaviorSubject(true);
    private tbodyChanged$ = new BehaviorSubject(true);

    private theadObserver = new MutationObserver(() => this.theadChanged$.next(true));
    private tbodyObserver = new MutationObserver(() => this.tbodyChanged$.next(true));

    constructor(
        private table: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit() {
        this.thead = this.table.nativeElement.querySelector('thead');
        this.tbody = this.table.nativeElement.querySelector('tbody');

        if (!this.thead || !this.tbody) {
            throw new Error('The table must have thead and tbody sections');
        }

        this.theadObserver.observe(this.thead, {
            characterData: true,
            subtree: true,
        });
        this.tbodyObserver.observe(this.tbody, { childList: true });

        /**
         * Set the "data-column-name" attribute for every body row cell, either on
         * thead row changes (e.g. language changes) or tbody rows changes (add, delete).
         */
        combineLatest([this.theadChanged$, this.tbodyChanged$])
            .pipe(
                map(() => ({ headRow: this.thead.rows.item(0)!, bodyRows: this.tbody.rows })),
                map(({ headRow, bodyRows }) => ({
                    columnNames: [...headRow.children].map(headerCell => headerCell.textContent!),
                    rows: [...bodyRows].map(row => [...row.children]),
                })),
                takeUntil(this.onDestroy$),
            )
            .subscribe(({ columnNames, rows }) =>
                rows.forEach(rowCells =>
                    rowCells.forEach(cell => {
                        const value = columnNames[(cell as HTMLTableCellElement).cellIndex];
                        this.renderer.setAttribute(cell, 'data-column-name', value ?? '');
                    }),
                ),
            );
    }

    ngOnDestroy(): void {
        this.theadObserver.disconnect();
        this.tbodyObserver.disconnect();

        this.onDestroy$.next(true);
    }
}
