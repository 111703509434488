import { Injectable } from '@angular/core';
import {
    collection,
    collectionData,
    CollectionReference,
    doc,
    Firestore,
    getDoc,
    getDocFromServer,
    orderBy,
    query,
    serverTimestamp,
    Timestamp,
    updateDoc,
    writeBatch,
} from '@angular/fire/firestore';
import { lastValueFrom, take } from 'rxjs';
import { AuthService } from './auth.service';
import { OrdersService } from './orders.service';
import type { Customer, Order } from './types';
import { typeConverter } from './util';

export type ChatMessage = {
    type: 'chat';
    message: string;
    timestamp: Timestamp;
    user: string;
    email: string;
    admin: boolean;
};

export type SystemMessage = {
    type: 'system';
    timestamp: Timestamp;
    message: string;
};

export type ChatroomItem = ChatMessage | SystemMessage;

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(
        private readonly firestore: Firestore,
        private readonly auth: AuthService,
        private readonly ordersService: OrdersService,
    ) {}

    /**
     * Adds a message to the specified customer's order.
     *
     * @param message - The message to be added.
     * @param customerId - The ID of the customer.
     * @param orderId - The ID of the order.
     * @returns A promise that resolves when the message is successfully added.
     */
    async addMessage({
        customerId,
        orderId,
        message,
        type,
    }: {
        message: string;
        type: 'chat' | 'system';
        customerId: string;
        orderId: string;
    }) {
        // Get some user data
        const user = await lastValueFrom(this.auth.user$.pipe(take(1)));
        const admin = await lastValueFrom(this.auth.admin$.pipe(take(1)));

        if (!user || !user.email) throw new Error('User not found while adding message.');

        // Setup references
        const collectionRef = collection(this.firestore, `customers/${customerId}/orders/${orderId}/messages`);
        const notificationDocRef = doc(this.firestore, `customers/${customerId}/orders/${orderId}/notifications/chatEmailNotification`);
        const customerDocRef = doc(this.firestore, `customers/${customerId}`).withConverter(typeConverter<Customer>());

        // Construct message structure
        const messageWithTimestamp = { message, timestamp: serverTimestamp(), user: user.displayName, email: user.email, admin, type };
        // Get possible notification doc
        const notificationDoc = (await getDocFromServer(notificationDocRef)).data();
        // Check if email of the order is the same as current user
        const customerEmail = (await getDoc(customerDocRef)).data()?.email;
        // Create a batch to write multiple documents atomically
        const batch = writeBatch(this.firestore);
        // Simply add the message if a notification document already exists or if the customer's email matches the current user's email.
        if (notificationDoc || customerEmail === user?.email) {
            batch.set(doc(collectionRef), messageWithTimestamp);
        } else {
            // Otherwise, create a notification document and add the message.
            batch.set(notificationDocRef, { timestamp: serverTimestamp(), admin });
            batch.set(doc(collectionRef), messageWithTimestamp);
        }
        // Only update the lastMessage field if the type is 'chat'
        if (type === 'chat') {
            batch.update(doc(this.firestore, `customers/${customerId}/orders/${orderId}`).withConverter(typeConverter<Order>()), {
                // Update the order with the lastMessage field.
                lastMessage: serverTimestamp(),
                // Also mark the lastSeen field so the chatroom is marked as seen.
                lastSeen: {
                    // Firestore does not allow dots in field names, so we replace them with commas.
                    [user.email.replace('.', ',')]: serverTimestamp(),
                },
            });
        }
        await batch.commit();
    }

    async markMessagesAsSeen(customerId: string, orderFirestoreId: string, email: string) {
        const orderRef = doc(this.firestore, `customers/${customerId}/orders/${orderFirestoreId}`);
        // Update the lastSeen field in the order document
        await updateDoc(orderRef, {
            // Firestore does not allow dots in field names, so we replace them with commas.
            [`lastSeen.${email.replace('.', ',')}`]: serverTimestamp(), // Firestore server timestamp
        });
        // Remove the order from the unseenMessagesSignal
        this.ordersService.unseenMessagesSignal.update(current =>
            current ? current.filter(order => order.firestoreId !== orderFirestoreId) : null,
        );
    }

    /**
     * Retrieves the chatroom for a specific customer and order.
     *
     * @param customerId - The ID of the customer.
     * @param orderId - The ID of the order.
     * @returns A Promise that resolves to an array of messages in the chatroom.
     */
    getChatroom(customerId: string, orderId: string) {
        // Typeconverter is not compatible with union types (yet) so we cast to CollectionReference<ChatroomItem>
        const collectionRef = collection(
            this.firestore,
            `customers/${customerId}/orders/${orderId}/messages`,
        ) as CollectionReference<ChatroomItem>;
        return collectionData(query(collectionRef, orderBy('timestamp')));
    }
}
