import type { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { switchMap, timer } from 'rxjs';
import { AuthService } from './auth.service';

export class PasswordValidators {
    constructor() {}

    /**
     * Creates a validator function that checks if the control value matches the specified regular expression pattern.
     * @param regex - The regular expression pattern to match against.
     * @param error - The error object to return if the value does not match the pattern.
     * @returns A validator function.
     */
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const valid = regex.test(control.value);
            return valid ? null : error;
        };
    }

    /**
     * Validates if the password and confirmPassword fields match.
     * @param control - The form control containing the password and confirmPassword fields.
     * @returns An error object if the passwords do not match, otherwise null.
     */
    static MatchValidator(control: AbstractControl) {
        const password: string = control.get('password')?.value;
        const confirmPassword: string = control.get('confirmPassword')?.value;

        if (!confirmPassword?.length) {
            return null;
        }

        if (password !== confirmPassword) {
            control.get('confirmPassword')?.setErrors({ mismatch: true });
            return null;
        } else {
            return null;
        }
    }
}

/**
 * Validates if a user already exists or is unknown.
 * @param authService - The authentication service.
 * @param options - Optional configuration options.
 * @returns An asynchronous validator function.
 */
export function userExistsValidator(authService: AuthService, options = { reverse: false }): AsyncValidatorFn {
    return (control: AbstractControl<string>) => {
        return timer(300).pipe(
            switchMap(async () => {
                const result = await authService.userAlreadyExists(control.value);
                control.markAsTouched();
                return result && !options.reverse ? { userAlreadyExists: true } : !result && options.reverse ? { userUnknown: true } : null;
            }),
        );
    };
}
