import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { Patient } from './patient';
import { Practice } from './practice';

export const ORDER_TYPES = [
    'Aligner',
    'Bleaching tray(s)',
    'Fixed retention',
    'Individual tray(s)',
    'Nightguard',
    'Refinement',
    'Retainer',
    'Study model(s)',
] as const;

export const PRODUCTION_SUB_STATUSES = ['Partly Printed', 'Printed', 'Vacuumed/Cut', 'Packed'] as const;
export type ProductionSubStatus = (typeof PRODUCTION_SUB_STATUSES)[number];
export const PUBLIC_STATUSES = ['Draft', 'Waiting for acceptance', 'In design', 'Ready for review', 'In production', 'Send'] as const;
export type PublicStatus = (typeof PUBLIC_STATUSES)[number];
export type OrderStatus = PublicStatus | ProductionSubStatus;
export type OrderType = (typeof ORDER_TYPES)[number];
export type Order = {
    /**
     * FunctionalID, e.g. 315
     * Optional cause draft orders don't have ID's yet
     */
    id?: string;
    invoiceId?: string;
    /** Id from the monthly invoice this order is referenced on */
    monthlyInvoiceId: string | null;
    status: OrderStatus;
    lastTimeUpdated?: Timestamp;
    /** Timestamp when the order was submitted */
    creationDate?: Timestamp;
    /** Date the customer would like to have the product.
     * This is not a hard deadline, but a guideline for the production team.
     * If the customer needs the product on a specific date, they should mention this in the instructions.
     *
     * TODO: #206 This should be renamed to estimatedCompletionDate
     */
    desiredCompletionDate?: Timestamp;
    /** Timestamp when the order was marked 'Send' */
    sendDate?: Timestamp;
    designURL?: string | null;
    shareDesignCode?: string | null;
    trackAndTrace?: string | null;
    amountOfModels?: number;
    /** Functional orderId for the corresponding Aligner order this refinement is for */
    refinementFor?: string;
    /** Functional ids of possible refinements after this order is completed */
    refinements?: string[];
    pricing?: {
        /** The amount of products */
        quantity?: number;
        /** The actual price of the order */
        amount?: number;
        /** Flag to determine if the pricing was manually set / overridden */
        manual?: true;
        /** User who manually set/override the price */
        onBehalf?: string;
    };
    manualDelivery?: {
        timestamp?: Timestamp;
    };
    customer: {
        /** FunctionalID, e.g. 315 */
        id: string;
        /** FirestoreID, e.g. 3j4h5j3h4j5h3j4h5 */
        firestoreId: string;
        firstName: string;
        lastName: string;
    };
    patient: Patient;
    practice: Practice;
    type: OrderType;
    lowerArch?: boolean;
    upperArch?: boolean;
    pictures?: {
        first?: string;
        second?: string;
        third?: string;
        fourth?: string;
        fifth?: string;
        sixth?: string;
        seventh?: string;
        eigth?: string;
    };
    digitalScans?: {
        scans: string[];
        pvsImpressions: boolean;
        meditlink: boolean;
    };
    instructions: {
        approach: string;
        restorativePlans: string;
        alignment?: string;
        spaces?: string;
        spacesInstructions?: string;
        midline: string;
        IPR: string;
        IPRInstructions?: number;
        attachments: string;
        attachmentsInstructions?: string;
        noMovement?: string;
        extractions?: string;
        /** @deprecated Use furtherInstructions */
        chiefInstructions?: string;
        furtherInstructions?: string;
    };
    adminNotes?: string;
    lastSeen?: Record<string, Timestamp>;
    lastMessage?: Timestamp;
};

export type OrderWithFirestoreId = Order & { firestoreId: string };

// Improved utility type
type ReplaceTimestampWithFieldValue<T> = T extends Timestamp
    ? FieldValue | Date // Directly replace Timestamp with FieldValue | Date
    : T extends Array<infer U>
      ? Array<ReplaceTimestampWithFieldValue<U>> // Handle arrays
      : T extends object
        ? { [P in keyof T]: ReplaceTimestampWithFieldValue<T[P]> } // Handle objects
        : T; // Default case

// Creating a new type from Order where Timestamp is replaced with FieldValue
export type OrderWithFieldValue = ReplaceTimestampWithFieldValue<Order>;

export type UpdateOrderRequest = Partial<OrderWithFieldValue>;
