import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from 'src/app/customer.service';
import { Invoice } from '../../types';
import { SparseCustomer } from '../invoice-management/invoice-management.component';

@Component({
    selector: 'app-invoice-confirmation-dialog',
    templateUrl: './invoice-confirmation-dialog.component.html',
    styleUrls: ['./invoice-confirmation-dialog.component.scss'],
})
export class InvoiceConfirmationDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { customer: SparseCustomer; totals: Invoice['totals'] },
        readonly customerService: CustomerService,
    ) {
        // Sadly we dont have the full customer data here, so we need to use the sparse customer data to get the email.
        customerService.getCustomerPromise(data.customer.firestoreId).then(customer => {
            // If the customer has an invoicesEmail set we should use that, otherwise we use the email from the customer profile.
            if (customer.invoicesEmail) {
                this.email.setValue(customer.invoicesEmail);
                this.prefilled = true;
            } else {
                this.email.setValue(customer.email);
            }
        });
    }
    /** Set when the email recipient is already filled from the customers profile */
    prefilled = false;
    email = new FormControl<string | undefined>(undefined, { validators: [Validators.email, Validators.required], nonNullable: true });
}
