<mat-toolbar>Smile Art</mat-toolbar>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      @if (error) {
        <div>
          <div class="error-message">
            <mat-icon>error</mat-icon><span>{{ mode }} failed</span>
          </div>
        </div>
      } @else {
        @switch (mode) {
          @case ('verifyEmail') {
            <p>Email verification complete!</p>
          }
          @case ('resetPassword') {
            <p>Reset password</p>
          }
        }
      }
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @if (error) {
      <div>
        <p>Details: {{ error }}</p>
        <p>Using the button below you can retry the process.</p>
      </div>
    } @else {
      @switch (mode) {
        @case ('resetPassword') {
          <div class="password-form">
            <mat-form-field>
              <mat-label>New password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" [formControl]="newPasswordForm.controls.password" />
              <button
                tabindex="-1"
                mat-icon-button
                matSuffix
                type="button"
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
                >
                <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
              @if (newPasswordForm.controls.password.hasError('required')) {
                <mat-error>Password is required.</mat-error>
              }
              @if (newPasswordForm.controls.password.hasError('minlength')) {
                <mat-error
                  >Password must be at least 8 characters long.</mat-error
                  >
                }
              </mat-form-field>
              <mat-form-field>
                <mat-label>Confirm new Password</mat-label>
                <input matInput type="password" [formControl]="newPasswordForm.controls.confirmPassword" />
                @if (newPasswordForm.controls.confirmPassword.hasError('required')) {
                  <mat-error
                    >Password confirmation is required.</mat-error
                    >
                  }
                  @if (newPasswordForm.controls.confirmPassword.hasError('mismatch')) {
                    <mat-error
                      >Password confirmation does not match.</mat-error
                      >
                    }
                  </mat-form-field>
                </div>
              }
            }
          }
        </mat-card-content>
        <mat-card-actions>
          @if (error) {
            @switch (mode) {
              @case ('verifyEmail') {
                <button mat-raised-button (click)="resendVerificationMail()">Re-send email</button>
              }
              @case ('resetPassword') {
                <button mat-raised-button routerLink="/">Retry</button>
              }
            }
          } @else {
            @switch (mode) {
              @case ('verifyEmail') {
                <button mat-raised-button routerLink="{{ continueUrl }}">Continue</button>
              }
              @case ('resetPassword') {
                <button mat-raised-button (click)="resetPassword()" [disabled]="newPasswordForm.invalid">
                  Confirm new password
                </button>
              }
            }
          }
        </mat-card-actions>
      </mat-card>
