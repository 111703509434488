import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[dropzone]',
})
export class DropzoneDirective {
    @Output() dropped = new EventEmitter<FileList>();
    @Output() hovered = new EventEmitter<boolean>();

    @HostListener('drop', ['$event'])
    onDrop($event: DragEvent) {
        $event.preventDefault();
        this.dropped.emit($event.dataTransfer?.files);
        this.hovered.emit(false);
    }

    @HostListener('dragover', ['$event'])
    onDragOver($event: DragEvent) {
        $event.preventDefault();
        this.hovered.emit(true);
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event: DragEvent) {
        $event.preventDefault();
        this.hovered.emit(false);
    }
}
