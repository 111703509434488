<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="drawer" mode="over">
        <div class="menu-header">
            <img src="assets/smile-art-logo-original.svg" class="logo" />
        </div>
        <mat-nav-list>
            <mat-divider></mat-divider>
            <mat-list-item (click)="navigateTo('orders')">
                <mat-icon matListItemIcon>ballot</mat-icon>
                <div matListItemTitle>Orders</div>
            </mat-list-item>
            <mat-list-item (click)="navigateTo('patients')">
                <mat-icon matListItemIcon>person_search</mat-icon>
                <div matListItemTitle>Patients</div>
            </mat-list-item>
            <!-- <ng-template #customerInvoices>
                <mat-list-item (click)="navigateTo('invoices')">
                    <mat-icon matListItemIcon>payments</mat-icon>
                    <div matListItemTitle>Invoices</div>
                </mat-list-item>
            </ng-template> -->
            <mat-list-item *ngIf="admin$()" (click)="navigateTo('invoice-management')">
                <mat-icon matListItemIcon>payments</mat-icon>
                <div matListItemTitle>Invoices</div>
            </mat-list-item>
            <mat-list-item *ngIf="admin$()" (click)="navigateTo('customers')">
                <mat-icon matListItemIcon>people</mat-icon>
                <div matListItemTitle>Customers</div>
            </mat-list-item>
        </mat-nav-list>
    </mat-drawer>
    <mat-toolbar #homeToolbar>
        <button mat-icon-button (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
        <img routerLink="/" src="assets/smile-art-logo-wit.svg" class="toolbar-logo" />
        <span class="toolbar_spacer"></span>
        <span class="toolbar_environment"> {{ environment ? '' : 'Development version' }}</span>
        <span class="toolbar_spacer"></span>
        <span *ngIf="admin$()"><mat-icon class="admin-icon" matTooltip="Admin mode is enabled">admin_panel_settings</mat-icon></span>

        @if (ordersWithUnseenMessages$()?.length) {
            <button mat-icon-button [matMenuTriggerFor]="messagesMenu" aria-label="menu icon button">
                <mat-icon [matBadge]="ordersWithUnseenMessages$()?.length" matBadgeColor="accent" aria-hidden="false"
                    >notifications</mat-icon
                >
            </button>
            <mat-menu #messagesMenu="matMenu">
                @for (order of ordersWithUnseenMessages$(); track $index) {
                    <button
                        [routerLink]="
                            admin$()
                                ? ['/customers', order.customerFirestoreId, 'orders', order.firestoreId]
                                : ['/orders', order.firestoreId]
                        "
                        [queryParams]="{ showmessages: true }"
                        mat-menu-item
                    >
                        <mat-icon color="primary">chat</mat-icon>
                        <span>New messages in Order #{{ order.functionalId }}!</span>
                    </button>
                }
            </mat-menu>
        }

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu icon button">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button routerLink="/profile" *ngIf="user$ | async as user" mat-menu-item aria-label="Profile">
                <mat-icon aria-label="Profile"> person</mat-icon>
                <span> {{ user.displayName || user.email }}</span>
            </button>
            <mat-divider></mat-divider>
            <button *ngIf="admin$()" mat-menu-item aria-label="App settings" routerLink="/settings">
                <mat-icon aria-label="Open 3d model viewer">settings</mat-icon>
                <span>App Settings</span>
            </button>
            <button mat-menu-item aria-label="Logout" (click)="logout()">
                <mat-icon aria-label="Logout"> logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </mat-toolbar>
    <router-outlet></router-outlet>
</mat-drawer-container>
