import { Component, TemplateRef, ViewChild, computed } from '@angular/core';
import { Firestore, doc, updateDoc } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Timestamp } from 'firebase/firestore';
import * as moment from 'moment';
import { Observable, filter, lastValueFrom, switchMap, take } from 'rxjs';
import { AuthService } from '../auth.service';
import { CustomerService } from '../customer.service';
import { OrdersService } from '../orders.service';
import { PatientService } from '../patient.service';
import { Customer, OrderWithFirestoreId, Patient, Practice } from '../types';
import { compareFunction, deadlineReached, isDefined } from '../util';

interface EditPatientForm {
    birthdate: FormControl<moment.Moment>;
    email: FormControl<string>;
    practice: FormControl<Patient['practice']>;
}

@Component({
    selector: 'app-patient',
    templateUrl: './patient.component.html',
    styleUrls: ['./patient.component.scss'],
})
export class PatientComponent {
    patientId: string;
    patient$: Observable<Patient | undefined>;
    customer$: Observable<Customer | undefined>;
    patientOrders$: Observable<OrderWithFirestoreId[]> | undefined;

    editPatientForm!: FormGroup<EditPatientForm>;
    @ViewChild('newPracticeDialog') newPracticeDialog!: TemplateRef<unknown>;
    @ViewChild('deleteOrderDialog') deleteOrderDialog!: TemplateRef<unknown>;

    // Geen geboortedatums in de toekomst toelaten.
    maxDate = new Date();
    // Patienten die ouder dan 100 zijn lijkt me onwaarschijnlijk.
    minDate = moment().add(-100, 'years');

    routingCustomerId: string | null;

    /**
     * The Firestore ID of the customer associated with this patient.
     */
    customerFirestoreId = computed(() => this.routingCustomerId ?? this.authFirestoreId() ?? null);

    /**
     * The Firestore ID of the authenticated user.
     */
    authFirestoreId = this.authService.firestoreIdSignal$;

    /**
     * Indicates whether the patient is currently being edited.
     */
    editing: boolean = false;

    admin = this.authService.adminSignal$;

    compareFunction = compareFunction;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly patientService: PatientService,
        private readonly authService: AuthService,
        private readonly fb: FormBuilder,
        private readonly firestore: Firestore,
        private readonly dialog: MatDialog,
        private readonly snackbar: MatSnackBar,
        private readonly router: Router,
        private readonly ordersService: OrdersService,
        private readonly customerService: CustomerService,
    ) {
        const patientId = this.route.snapshot.paramMap.get('id');
        this.routingCustomerId = this.route.snapshot.paramMap.get('customerid');

        if (!patientId) {
            throw new Error('No patient id in patient component route');
        }
        this.patientId = patientId;
        this.patient$ = this.authService.firestoreId$.pipe(
            switchMap(customerId => this.patientService.getPatient(this.routingCustomerId ?? customerId, patientId)),
        );
        this.customer$ = this.authService.firestoreId$.pipe(
            switchMap(customerId => this.customerService.getCustomer(this.routingCustomerId ?? customerId)),
        );
        this.patientOrders$ = this.customer$.pipe(
            filter(isDefined),
            switchMap(({ firestoreId }) =>
                this.patient$.pipe(
                    switchMap(patient => {
                        if (!patient) {
                            return [];
                        }
                        return this.ordersService.getPatientOrders(firestoreId, patient);
                    }),
                ),
            ),
        );
    }

    ngOnInit() {
        this.patient$.pipe(take(1)).subscribe(patient => {
            if (!patient) {
                throw new Error('No patient found');
            }
            this.editPatientForm = this.fb.nonNullable.group({
                birthdate: [moment(patient.birthdate.toDate()), { validators: [Validators.required], nonNullable: true }],
                email: [patient.email || '', { validators: [Validators.email] }],
                practice: [patient.practice, { validators: [Validators.required], nonNullable: true }],
            });
        });
    }

    toggleEditForm() {
        this.editing = !this.editing;
        if (!this.editing && this.editPatientForm.valid && this.editPatientForm.dirty) {
            this.save();
        }
    }

    refinementDeadlineReached(order: OrderWithFirestoreId) {
        // Admins can always request a refinement
        if (this.admin()) return true;
        if (!order.lastTimeUpdated) return false;
        return deadlineReached(order.lastTimeUpdated, { amount: order.pricing?.quantity, unit: 'weeks' });
    }

    // Seperate routing method so we can stop the event propagation.
    // Else the row click event will also be triggered.
    async routeToNewOrder(functionalId: OrderWithFirestoreId['id'], event: Event) {
        event.stopPropagation();
        await this.router.navigate(['/new'], { queryParams: { refinementFor: functionalId } });
    }

    async save() {
        const { birthdate, ...rest } = this.editPatientForm.value;
        await lastValueFrom(
            this.authService.firestoreId$.pipe(
                take(1),
                switchMap(customerId =>
                    this.patientService.updatePatient(customerId, this.patientId, {
                        ...rest,
                        birthdate: birthdate ? Timestamp.fromDate(birthdate.toDate()) : undefined,
                    }),
                ),
            ),
        );
        this.snackbar.open('Patient information saved!', 'close', { duration: 3000 });
    }

    // FIXME: This is a copy of the code in profile.component.ts
    // Move Addpractice dialog to seperate component
    async addPractice(customer: Customer) {
        const practice = this.newPracticeForm.value as Practice;

        // Get possible current value
        let practices: Practice[] = [];
        if (customer.practices?.length) {
            practices = [...customer.practices, practice];
        } else {
            practices = [practice];
        }
        customer.practices = practices;

        const docRef = doc(this.firestore, `/customers/${customer.firestoreId}`);
        await updateDoc(docRef, { practices });
        this.editPatientForm.controls.practice.setValue(practice);
    }

    newPracticeForm = new FormGroup({
        name: new FormControl('', { nonNullable: true, validators: Validators.required }),
        city: new FormControl('', { nonNullable: true, validators: Validators.required }),
        country: new FormControl('', { nonNullable: true }),
        postalcode: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.pattern(/^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i)],
        }),
        housenumber: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')] }),
        street: new FormControl('', { nonNullable: true, validators: Validators.required }),
        street2: new FormControl(''),
    });

    openNewPracticeDialog() {
        const dialogRef = this.dialog.open(this.newPracticeDialog);
        dialogRef.afterClosed().subscribe(async (result: Customer) => {
            if (result) {
                await this.addPractice(result);
            }
        });
    }

    openDeleteOrderDialog(event: Event, order: OrderWithFirestoreId) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(this.deleteOrderDialog, { data: order });
        dialogRef.afterClosed().subscribe(async (result: boolean) => {
            if (result) {
                await this.ordersService.deleteOrder(order.firestoreId, order.customer.firestoreId);
            }
        });
    }
}
