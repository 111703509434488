import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, signal, TemplateRef, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { collection, deleteDoc, doc, docData, Firestore, getDoc, setDoc, updateDoc, writeBatch } from '@angular/fire/firestore';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Timestamp } from 'firebase/firestore';
import { isEqual } from 'lodash';
import * as moment from 'moment';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import {
    combineLatest,
    delay,
    delayWhen,
    distinctUntilChanged,
    filter,
    interval,
    map,
    Observable,
    startWith,
    Subject,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from '../app-settings.service';
import { AuthService } from '../auth.service';
import { CustomerService } from '../customer.service';
import { IdService } from '../id.service';
import { NewPatientComponent } from '../new-patient/new-patient.component';
import { OrdersService } from '../orders.service';
import { PatientService } from '../patient.service';
import { Customer, Order, ORDER_TYPES, OrderStatus, OrderType, Patient, Practice } from '../types';
import { compareFunction, isDefined, typeConverter } from '../util';

@Component({
    selector: 'app-create-order',
    templateUrl: './create-order.component.html',
    styleUrls: ['./create-order.component.scss'],
    providers: [{ provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true, displayDefaultIndicatorType: false } }],
})
export class CreateOrderComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
    constructor(
        private formBuilder: FormBuilder,
        private readonly auth: AuthService,
        private readonly idService: IdService,
        private readonly firestore: Firestore,
        private readonly route: ActivatedRoute,
        private readonly customerService: CustomerService,
        private readonly router: Router,
        private readonly settingsService: AppSettingsService,
        public readonly dialog: MatDialog,
        private readonly patientService: PatientService,
        private readonly deviceService: DeviceDetectorService,
        private readonly ordersService: OrdersService,
    ) {}

    @ViewChild('deleteDialog') deleteDialog!: TemplateRef<unknown>;
    @ViewChild('newPracticeDialog') newPracticeDialog!: TemplateRef<unknown>;
    @ViewChild('qrCodeDialog') qrCodeDialog!: TemplateRef<unknown>;
    @ViewChild('stepper') stepper!: MatStepper;
    @ViewChild('qrSyncDialog') qrSyncDialog!: TemplateRef<unknown>;

    dialogRef?: MatDialogRef<any>;

    destroy$ = new Subject<null>();
    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    admin$ = this.auth.admin$;
    /**
     * An observable that emits a `Customer` object or `undefined` for refinement purposes.
     * Is only defined when the order is a refinement order, and the current user is an admin.
     * If the current user is not an admin, the observable will emit `undefined`.
     */
    refinementCustomer$: Observable<Customer | undefined> | undefined;

    ngOnInit(): void {
        combineLatest([this.route.paramMap, this.auth.firestoreId$, this.orderId$])
            .pipe(
                takeUntil(this.destroy$),
                map(async ([paramMap, firestoreId]) => {
                    if (!firestoreId) throw new Error('Could not determine firestoreID from auth during create order Init');
                    // Haal customerdata op
                    const document = doc(this.firestore, `/customers/${firestoreId}`).withConverter(typeConverter<Customer>());
                    this.customer = (await getDoc(document)).data();

                    this.customerId = firestoreId;

                    this.alignerOrders$ = combineLatest([
                        this.customer$.pipe(filter(isDefined)),
                        this.firstFormGroup.controls.patient.valueChanges.pipe(filter((v): v is Patient => v !== typeof 'string')),
                        this.firstFormGroup.controls.type.valueChanges.pipe(filter(type => type === 'Refinement')),
                        this.auth.admin$,
                    ]).pipe(
                        takeUntil(this.destroy$),
                        switchMap(([customer, patient, admin]) =>
                            admin
                                ? this.ordersService.getCompletedAlignerOrdersByPatient(patient.id)
                                : this.ordersService.getCompletedAlignerOrdersByPatient(customer.firestoreId, patient.id),
                        ),
                    );

                    // Mogelijke docId uit routeparams
                    const docId = paramMap.get('id') || this.orderId();
                    // Als we als een doc/order hebben, update form met al bestaande data
                    if (docId) {
                        this.orderId.set(docId);
                        this.completeNewOrder = false;
                        // TODO: CustomerID en Routing fixen voor tegen URL hacking.
                        const documentRef = doc(this.firestore, `/customers/${firestoreId}/orders/${docId}`).withConverter(
                            typeConverter<Order>(),
                        );
                        docData(documentRef)
                            .pipe(filter(isDefined), distinctUntilChanged<Order>(isEqual), takeUntil(this.destroy$))
                            .subscribe(data => {
                                if (data) {
                                    this.status = data.status;
                                    this.firstFormGroup.patchValue({
                                        practice: data.practice,
                                        patient: data.patient,
                                        type: data.type,
                                        upperArch: data.upperArch,
                                        lowerArch: data.lowerArch,
                                    });
                                    if (data.pictures) {
                                        this.uploadPictureGroup.patchValue(data.pictures);
                                    }
                                    if (data.instructions) {
                                        this.instructionsGroup.patchValue(data.instructions);
                                    }
                                    if (data.digitalScans) {
                                        this.stlGroup.patchValue(data.digitalScans);
                                    }
                                }
                            });
                    } else {
                        // Bij geen bestaande order, zet default practice
                        this.firstFormGroup.controls.practice.setValue(this.customer?.defaultPractice ?? null);
                        this.completeNewOrder = true;
                    }
                    // Bij elke picturegroup change gelijk opslaan
                    this.uploadPictureGroup.valueChanges
                        .pipe(
                            takeUntil(this.destroy$),
                            distinctUntilChanged(isEqual),
                            filter(v => this.firstFormGroup.controls.type.value !== 'Refinement'),
                            tap(async () => await this.updateOrderWithPictures()),
                        )
                        .subscribe();

                    // Bij elke stlGroup change gelijk opslaan
                    this.stlGroup.valueChanges
                        .pipe(
                            takeUntil(this.destroy$),
                            distinctUntilChanged(isEqual),
                            filter(v => this.firstFormGroup.controls.type.value !== 'Refinement'),
                            tap(async () => await this.updateOrderWithDigitalScans()),
                        )
                        .subscribe();

                    // Bij elke patient change, gelijk de practice updaten
                    this.firstFormGroup.controls.patient.valueChanges
                        .pipe(
                            takeUntil(this.destroy$),
                            filter((v): v is Patient => typeof v !== 'string'),
                        )
                        .subscribe(async patient => this.firstFormGroup.controls.practice.setValue(patient.practice));

                    this.firstFormGroup.controls.type.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(type => {
                        // De required validators voor de instructions zijn alleen nodig voor Aligner.
                        if (type === 'Aligner') {
                            this.instructionsGroup.controls.approach.setValidators(Validators.required);
                            this.instructionsGroup.controls.restorativePlans.setValidators(Validators.required);
                            this.instructionsGroup.controls.midline.setValidators(Validators.required);
                            this.instructionsGroup.controls.IPR.setValidators(Validators.required);
                            this.instructionsGroup.controls.attachments.setValidators(Validators.required);
                        } else {
                            // Als het geen Aligner type is, de instructionsGroup validators verwijderen.
                            for (const control of Object.values(this.instructionsGroup.controls)) {
                                control.clearValidators();
                            }
                        }
                        // Als het een Refinement type is, de refinementFor validators toevoegen.
                        if (type === 'Refinement') {
                            this.firstFormGroup.controls.refinementFor.setValidators(Validators.required);
                            // En de required validators van de de foto's en stlGroup verwijderen. Deze zijn optioneel voor Refinement.
                            for (const control of Object.values(this.uploadPictureGroup.controls)) {
                                control.clearValidators();
                            }
                        }
                    });
                }),
            )
            .subscribe();
    }

    ngAfterContentInit(): void {
        // Als het een refinement order is, gelijk de originele order ophalen en de velden van de originele order invullen.
        combineLatest([
            this.firstFormGroup.controls.type.valueChanges.pipe(filter(v => v === 'Refinement')),
            this.firstFormGroup.controls.refinementFor.valueChanges.pipe(filter(isDefined)),
            this.customer$.pipe(filter(isDefined)),
            this.auth.admin$,
        ])
            .pipe(
                takeUntil(this.destroy$),
                switchMap(([_type, refinementFor, customer, admin]) => {
                    if (!refinementFor) return [];
                    if (admin) {
                        return this.ordersService.getOrderByFunctionalID(refinementFor);
                    } else {
                        return this.ordersService.getOrderByFunctionalID(refinementFor, customer.firestoreId);
                    }
                }),
                filter(isDefined),
            )
            .subscribe(order => {
                // If the order is a refinement order, and we are an admin, we need to get the customer from the original order.
                // Set the observable to the customer of the original order so we can later use it when saving the order.
                // We want to save the new refinement order to the same customer as the original order. So we can not simply use the auth.firestoreId$.
                this.refinementCustomer$ = combineLatest([this.admin$, this.customerService.getCustomer(order.customer.firestoreId)]).pipe(
                    filter(([admin]) => admin),
                    map(([_, customer]) => customer),
                );
                this.firstFormGroup.patchValue({
                    upperArch: order.upperArch,
                    lowerArch: order.lowerArch,
                    patient: order.patient,
                    practice: order.practice,
                });
                this.stlGroup.patchValue(order.digitalScans ?? {});
                this.instructionsGroup.patchValue({ ...order.instructions, furtherInstructions: '' });
            });
    }

    async ngAfterViewInit(): Promise<void> {
        this.route.queryParamMap.pipe(takeUntil(this.destroy$), delay(400)).subscribe(async queryParamMap => {
            // Switch to step if step param is in the queryparams
            const step = queryParamMap.get('step');
            if (step && parseInt(step) < this.stepper.steps.length) {
                // Set timeout to prevent ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    this.stepper.selectedIndex = parseInt(step);
                });
            }
            const patientFirstname = queryParamMap.get('patientFirstname');
            const patientLastname = queryParamMap.get('patientLastname');
            const practice = queryParamMap.get('practice');
            const refinementFor = queryParamMap.get('refinementFor');
            const QRsync = queryParamMap.get('QRsync');
            if (QRsync) {
                const device = this.deviceService.getDeviceInfo();
                const qrSyncDocReference = doc(this.firestore, `customers/${this.customerId}/orders/${this.orderId()}/sync/__latest`);
                const docExists = (await getDoc(qrSyncDocReference)).data();
                if (!docExists) {
                    const dialogRef = this.dialog.open(this.qrSyncDialog);
                    dialogRef.afterClosed().subscribe(async result => {
                        if (result) {
                            await setDoc(qrSyncDocReference, {
                                device,
                                lastSync: Timestamp.now(),
                            });
                        }
                    });
                }
            }
            if (patientFirstname && patientLastname) {
                this.patients$.pipe(take(1)).subscribe(patients => {
                    const newValue = patients.find(p => p.firstName === patientFirstname && p.lastName === patientLastname);
                    if (!newValue) return;
                    this.firstFormGroup.controls.patient.setValue(newValue);
                });
            }
            if (practice && this.customer?.practices) {
                const newValue = this.customer.practices.find(p => p.name === practice);
                if (!newValue) return;
                this.firstFormGroup.controls.practice.setValue(newValue);
            }

            if (refinementFor) {
                this.firstFormGroup.controls.type.setValue('Refinement');
                this.firstFormGroup.controls.refinementFor.setValue(refinementFor);
            }
        });
    }

    compareFunction = compareFunction;

    /** Zitten we in een compleet nieuwe order flow? */
    completeNewOrder = false;
    /** Firestore id, NOT functional ID */
    orderId = signal<string | undefined>(undefined);
    orderId$ = toObservable(this.orderId);
    /** Firestore id, NOT functional ID */
    customerId?: string;
    customer?: Customer;
    // TODO: Kan dit hele component niet o.b.v. observable customer.
    customer$ = this.auth.firestoreId$.pipe(switchMap(id => this.customerService.getCustomer(id)));
    alignerOrders$?: Observable<Order[]>;
    status?: OrderStatus;

    // Geen geboortedatums in de toekomst toelaten.
    maxDate = new Date();
    // Patienten die ouder dan 100 zijn lijkt me onwaarschijnlijk.
    minDate = moment().add(-100, 'years');

    /**
     * Represents the available order types.
     */
    ORDER_TYPES = ORDER_TYPES;

    /**
     * Represents the default deadline and pricing settings in the application settings.
     */
    orderSettings$ = this.settingsService.settingsSignal$;

    /**
     * Represents the form group for the first step of the create order component.
     */
    firstFormGroup = this.formBuilder.group(
        {
            practice: new FormControl<Practice | null>(null, Validators.required),
            // String is alleen een search string voor autocomplete
            patient: new FormControl<Patient | string>('', {
                validators: [Validators.required, this.patientValidator],
                nonNullable: true,
            }),
            type: new FormControl<OrderType | null>(null, Validators.required),
            refinementFor: new FormControl<string | null>(null),
            upperArch: new FormControl(false),
            lowerArch: new FormControl(false),
        },
        { validators: this.archValidator },
    );

    /**
     * Form group for uploading pictures.
     */
    uploadPictureGroup = this.formBuilder.group({
        first: ['', Validators.required],
        second: ['', Validators.required],
        third: ['', Validators.required],
        fourth: ['', Validators.required],
        fifth: ['', Validators.required],
        sixth: ['', Validators.required],
        seventh: ['', Validators.required],
        eigth: ['', Validators.required],
        xray: ['', Validators.required],
    });

    /**
     * Represents the form group for the instructions section of the create order component.
     */
    instructionsGroup = this.formBuilder.group({
        approach: ['', Validators.required],
        restorativePlans: ['', Validators.required],
        // Alignment & spaces are not required, only if restorativePlans is set to 'Yes'
        alignment: [''],
        spaces: [''],
        spacesInstructions: '',
        midline: ['', Validators.required],
        IPR: ['', Validators.required],
        IPRInstructions: 0.4,
        attachments: ['', Validators.required],
        attachmentsInstructions: '',
        noMovement: '',
        extractions: '',
        furtherInstructions: '',
    });

    stlGroup = new FormGroup(
        {
            scans: new FormControl(new Array(), this.arrayLengthValidator),
            pvsImpressions: new FormControl(false, { validators: Validators.required, nonNullable: true }),
            meditlink: new FormControl(false, { validators: Validators.required, nonNullable: true }),
        },
        { validators: this.stlGroupValidator },
    );

    newPracticeForm = new FormGroup({
        name: new FormControl('', { nonNullable: true, validators: Validators.required }),
        city: new FormControl('', { nonNullable: true, validators: Validators.required }),
        country: new FormControl('', { nonNullable: true }),
        postalcode: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.pattern(/^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i)],
        }),
        housenumber: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')] }),
        street: new FormControl('', { nonNullable: true, validators: Validators.required }),
        street2: new FormControl(''),
    });

    // Checkbox om gelijk nieuwe praktijk default te maken.
    defaultPracticeControl = new FormControl(false, Validators.required);

    private patients$ = this.auth.firestoreId$.pipe(
        switchMap(id => {
            if (!id) throw new Error('Could not determine firestoreID in patientsForPractice$');
            return this.patientService.getPatients(id);
        }),
    );

    // Filter patients based on input (autocomplete)
    filteredPatients$: Observable<Patient[]> = combineLatest([
        this.patients$,
        this.firstFormGroup.controls.patient.valueChanges.pipe(startWith('')),
    ]).pipe(
        map(([patients, patient]) => {
            if (!patients) return [];
            if (!patient) return patients;
            const input = typeof patient === 'string' ? patient : `${patient.firstName} ${patient.lastName}`;
            return patients.filter(p => `${p.firstName} ${p.lastName}`.toLowerCase().includes(input.toLowerCase()));
        }),
    );

    completionDateFilter = (d: moment.Moment | null): boolean => {
        const day = (d?.toDate() || new Date()).getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    };

    patientAutocompleteDisplay(patient: Patient): string {
        return patient ? `${patient.firstName} ${patient.lastName}` : '';
    }

    getDraftTitle() {
        const formValue = this.firstFormGroup.controls.patient.value;
        if (typeof formValue === 'string' || !formValue.firstName || !formValue.lastName) return '';
        return `for ${formValue?.firstName} ${formValue?.lastName}`;
    }

    async addPractice() {
        const practice = this.newPracticeForm.value as Practice;
        const defaultPractice = this.defaultPracticeControl.value;
        this.firstFormGroup.controls.practice.setValue(practice);

        // Weird, but sanity checks cant hurt.
        if (!practice || !this.customer) return;

        // Get possible current value
        let practices: Practice[] = [];
        if (this.customer.practices?.length) {
            practices = [...this.customer.practices, practice];
        } else {
            practices = [practice];
        }
        this.customer.practices = practices;

        const value = defaultPractice ? { defaultPractice: this.newPracticeForm.value, practices } : { practices };
        const docRef = doc(this.firestore, `/customers/${this.customerId}`);
        await updateDoc(docRef, value);
    }

    getArchError() {
        const upperArch = this.firstFormGroup.get('upperArch');
        const lowerArch = this.firstFormGroup.get('lowerArch');
        if (upperArch?.dirty || lowerArch?.dirty) {
            return upperArch?.hasError('oneOf') || lowerArch?.hasError('oneOf');
        }
        return false;
    }

    private archValidator(control: AbstractControl): ValidationErrors | null {
        const upperArch = control.get('upperArch');
        const lowerArch = control.get('lowerArch');
        if (upperArch?.value || lowerArch?.value) {
            lowerArch?.setErrors(null);
            upperArch?.setErrors(null);
            return null;
        } else {
            upperArch?.setErrors({ oneOf: true });
            lowerArch?.setErrors({ oneOf: true });
            return null;
        }
    }

    // Make sure the patient is not the searchstring but the actual patient object.
    private patientValidator(control: AbstractControl): ValidationErrors | null {
        return typeof control?.value === 'string' ? { patient: true } : null;
    }

    private arrayLengthValidator(control: AbstractControl): ValidationErrors | null {
        return control?.value.length > 0 ? null : { length: true };
    }
    private stlGroupValidator(control: AbstractControl): ValidationErrors | null {
        const scans = control.get('scans');
        const pvsImpressions = control.get('pvsImpressions');
        const meditlink = control.get('meditlink');
        if (scans?.value.length > 0 || pvsImpressions?.value === true || meditlink?.value === true) {
            scans?.setErrors(null);
            pvsImpressions?.setErrors(null);
            meditlink?.setErrors(null);
            return null;
        } else {
            scans?.setErrors({ oneOf: true });
            pvsImpressions?.setErrors({ oneOf: true });
            meditlink?.setErrors({ oneOf: true });
            return null;
        }
    }

    saveOrder() {
        this.auth.firestoreId$.pipe(take(1)).subscribe(async id => {
            // Kan geen kwaad om customerId nog eens te zetten, ook al zou dit gek zijn.
            if (!id) throw new Error('Could not determine firestoreID in saveOrder()');
            this.customerId = id;
            // Sanity check, Als status niet Draft is zouden we hier niet moeten zijn.
            if (this.status && this.status !== 'Draft') return;

            // If patient is string, return cause we dont want to save that. Its just a search string from autocomplete.
            if (typeof this.firstFormGroup.controls.patient.value === 'string') {
                // TODO: Is this the problem when the submit button doesnt work?
                return;
            }

            const firestoreCustomerID = await this.getPossibleCustomerRefinementID();

            // TODO: Update doc alleen als values anders zijn.

            if (!this.customer || !this.customerId) throw new Error('Could not determine customer in saveOrder()');

            if (this.completeNewOrder) {
                // Alleen nieuwe firestoreId bij compleet nieuwe order.
                const orderId = doc(collection(this.firestore, `_`)).id;
                const docRef = doc(this.firestore, `customers/${firestoreCustomerID}/orders/${orderId}`);
                this.orderId.set(orderId);

                // TODO: Use Order type
                await setDoc(docRef, {
                    status: 'Draft',
                    // This doesnt make sense, but it is a workaround for now.
                    // If creationDate is not set, firestore orderBy will not take the document into account.
                    // So we set it to a placeholder value and update it later. This way the document is shown in the orders list.
                    creationDate: '' as any,
                    customer: {
                        id: this.customer.customerId,
                        firestoreId: firestoreCustomerID,
                        firstName: this.customer.firstName,
                        lastName: this.customer.lastName,
                    },
                    practice: this.firstFormGroup.controls.practice.value,
                    patient: {
                        ...this.firstFormGroup.controls.patient.value,
                    },
                    upperArch: this.firstFormGroup.controls.upperArch.value,
                    lowerArch: this.firstFormGroup.controls.lowerArch.value,
                    refinementFor: this.firstFormGroup.controls.refinementFor.value,
                    type: this.firstFormGroup.controls.type.value,
                    instructions: { ...this.instructionsGroup.value },
                });
                // Na deze actie zou het geen nieuwe order meer moeten zijn.
                this.completeNewOrder = false;
            } else {
                const docRef = doc(this.firestore, `customers/${firestoreCustomerID}/orders/${this.orderId()}`);

                // Update een bestaande order in Draft status
                // TODO: Use Order type
                await updateDoc(docRef, {
                    customer: {
                        id: this.customer?.customerId,
                        firestoreId: firestoreCustomerID,
                        firstName: this.customer?.firstName,
                        lastName: this.customer?.lastName,
                    },
                    practice: this.firstFormGroup.controls.practice.value,
                    patient: {
                        ...this.firstFormGroup.controls.patient.value,
                    },
                    upperArch: this.firstFormGroup.controls.upperArch.value,
                    lowerArch: this.firstFormGroup.controls.lowerArch.value,
                    refinementFor: this.firstFormGroup.controls.refinementFor.value,
                    type: this.firstFormGroup.controls.type.value,
                    instructions: { ...this.instructionsGroup.value },
                });
            }
        });
    }

    async updateOrderWithPictures() {
        if (!this.orderId() || !this.customerId) {
            throw new Error(`Not enough customer information for update order`);
        }
        const docRef = doc(this.firestore, `customers/${this.customerId}/orders/${this.orderId()}`);
        await updateDoc(docRef, {
            pictures: { ...this.uploadPictureGroup.value },
        });
    }
    async updateOrderWithDigitalScans() {
        if (!this.orderId() || !this.customerId) {
            throw new Error(`Not enough customer information for update order`);
        }
        const docRef = doc(this.firestore, `customers/${this.customerId}/orders/${this.orderId()}`);
        await updateDoc(docRef, {
            digitalScans: { ...this.stlGroup.value },
        });
    }

    openNewPracticeDialog() {
        this.dialogRef = this.dialog.open(this.newPracticeDialog);
    }

    openNewPatientDialog() {
        if (!this.customer || !this.customerId) throw new Error('Could not determine customer in openNewPatientDialog()');
        const dialogRef: MatDialogRef<NewPatientComponent> = this.dialog.open(NewPatientComponent);
        const instance = dialogRef.componentInstance;
        instance.customer = { ...this.customer, firestoreId: this.customerId };

        combineLatest([instance.patientCreated, this.patients$])
            .pipe(take(1))
            .subscribe(([patient, patients]) => {
                const newValue = patients.find(p => p.firstName === patient.firstName && p.lastName === patient.lastName);
                if (!newValue) return;
                this.firstFormGroup.controls.patient.setValue(newValue);
                this.firstFormGroup.controls.practice.setValue(patient.practice);
                dialogRef.close();
            });
    }

    openDeleteDialog() {
        this.dialogRef = this.dialog.open(this.deleteDialog);
    }

    showLoader = false;
    currentDeviceSync$ = combineLatest([this.auth.firestoreId$, this.orderId$])
        .pipe(
            switchMap(([id, orderId]) => {
                const docRef = doc(this.firestore, `customers/${id}/orders/${orderId}/sync/__latest`).withConverter(
                    typeConverter<{ device: DeviceInfo; lastSync: Timestamp }>(),
                );
                return docData(docRef);
            }),
        )
        .pipe(
            tap(v => (this.showLoader = !!v)),
            delayWhen(v => interval(v ? 1000 : 0)),
            tap(() => (this.showLoader = false)),
        );

    /**
     * Opens the QR code dialog.
     */
    showQRcode() {
        this.dialogRef = this.dialog.open(this.qrCodeDialog);
    }
    async resetSync() {
        this.showLoader = false;
        if (!this.orderId() || !this.customerId) {
            throw new Error(`Not enough customer information for update order`);
        }
        const docRef = doc(this.firestore, `customers/${this.customerId}/orders/${this.orderId()}/sync/__latest`);
        await deleteDoc(docRef);
    }

    /**
     * Returns the QR link for the current step of the order creation process.
     * The link is generated based on the current domain and the current path.
     * @returns {string} The QR link.
     */
    getQRlink(): string {
        const domain = environment.production ? 'https://smile-art.app' : 'https://smile-art.dev';
        return `${domain}${`/orders/${this.orderId()}`}/draft?step=1&QRsync=true`;
    }

    getDefaultDeadline(type: OrderType): number {
        const settings = this.settingsService.settingsSignal$();
        if (!settings) throw new Error('Could not determine settings in getDefaultDeadline()');
        return settings.orderDefaults[type].deadline;
    }

    /**
     * Retrieves the possible customer refinement ID.
     * If it is a refinement order, the customer ID of the original order is used.
     * Throws an error if the customer ID cannot be determined.
     * @returns The customer ID for possible customer refinement.
     */
    async getPossibleCustomerRefinementID() {
        const refinementCustomerId = this.refinementCustomer$ ? await this.refinementCustomer$.pipe(take(1)).toPromise() : undefined;
        // Als het een refinement order is, de customerID van de originele order gebruiken.
        const firestoreCustomerID = refinementCustomerId ? refinementCustomerId.firestoreId : this.customerId;
        if (!firestoreCustomerID) {
            throw new Error('Could not determine customerID when parsing possible customer refinement ID.');
        }
        return firestoreCustomerID;
    }

    // TODO: This should be in a service.
    async deleteOrder() {
        if (!this.orderId()) {
            throw new Error(`No orderID found for deleteOrder()`);
        }
        const firestoreCustomerID = await this.getPossibleCustomerRefinementID();

        // TODO: Delete all storagefiles as well
        const docRef = doc(this.firestore, `customers/${firestoreCustomerID}/orders/${this.orderId()}`);
        // TODO: Delete subcollections or use a cloud function for this.
        await deleteDoc(docRef);
        this.dialogRef?.close();
        this.router.navigateByUrl('');
    }

    async finishOrder() {
        // FIXME: Maak deze check op id's beter of generiek.
        if (!this.orderId()) {
            throw new Error(`No orderID found for finishOrder()`);
        }
        const id = await this.idService.getNewOrderNr();
        if (!id) throw new Error('Could not determine new orderID');

        const firestoreCustomerID = await this.getPossibleCustomerRefinementID();

        const batch = writeBatch(this.firestore);
        const orderRef = doc(this.firestore, `customers/${firestoreCustomerID}/orders/${this.orderId()}`);
        // Update order document
        batch.update(orderRef, {
            status: 'Waiting for acceptance',
            creationDate: Timestamp.now(),
            monthlyInvoiceId: null,
            id,
        });

        // Bij een refinement order ook de originele order updaten.
        // We moeten de originele order updaten met een referentie naar de nieuwe order.
        if (this.firstFormGroup.controls.type.value === 'Refinement') {
            const originalOrderFunctionalID = this.firstFormGroup.controls.refinementFor.value;
            if (!originalOrderFunctionalID) {
                throw new Error('No original order id found for refinement order');
            }
            const admin = await this.auth.admin$.pipe(take(1)).toPromise();
            // Als het een admin is, de originele order ophalen zonder customerID.
            // Als het geen admin is, de originele order ophalen met customerID.
            const originalOrder = await (admin
                ? this.ordersService.getOrderByFunctionalID(originalOrderFunctionalID)
                : this.ordersService.getOrderByFunctionalID(originalOrderFunctionalID, firestoreCustomerID));

            if (!originalOrder) {
                throw new Error('No original order found for refinement order');
            }
            const originalOrderRef = doc(this.firestore, `customers/${firestoreCustomerID}/orders/${originalOrder.firestoreId}`);
            batch.update(originalOrderRef, {
                refinements: [...(originalOrder.refinements ? originalOrder.refinements : []), id],
            });
        }

        // And set seperate collection/doc for email notification
        const orderNotificationRef = doc(this.firestore, `${orderRef.path}/notifications/orderCreated`);
        batch.set(orderNotificationRef, { created: true });
        // Commit both updates
        await batch.commit();
        // Remove sync document
        await this.resetSync();
        this.router.navigateByUrl('');
    }
}
