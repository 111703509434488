import { QueryDocumentSnapshot, Timestamp } from '@angular/fire/firestore';
import * as moment from 'moment';

/**
 * Checks if a value is defined (not null or undefined).
 *
 * @param value - The value to check.
 * @returns True if the value is defined, false otherwise.
 */
export function isDefined<T>(value: T): value is NonNullable<T> {
    return value != null;
}

/**
 * Converts data between Firestore and TypeScript types.
 * @template T The TypeScript type to convert.
 * @returns An object with methods to convert data to and from Firestore.
 */
export const typeConverter = <T>() => ({
    toFirestore: (data: T) => data,
    fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});

/**
 * Compares two objects based on their name and id properties.
 * @param o1 The first object to compare.
 * @param o2 The second object to compare.
 * @returns True if the name and id properties of both objects are equal, false otherwise.
 */
export function compareFunction(o1: any, o2: any) {
    return o1?.name == o2?.name && o1.id == o2.id;
}

/**
 * Checks if a deadline has been reached.
 * @param deadline The deadline to check.
 * @param amount The amount of time to add to the deadline.
 * @param unit The unit of time to add to the deadline.
 * @returns True if the deadline has been reached, false otherwise.
 */
export function deadlineReached(
    deadline: Timestamp,
    { amount, unit }: { amount?: moment.DurationInputArg1; unit?: moment.unitOfTime.DurationConstructor },
) {
    return moment(deadline?.toDate()).add(amount, unit).isSameOrBefore(moment());
}

/**
 * Removes properties with undefined values from an object.
 * @param obj - The object from which to remove undefined properties.
 * @returns A new object with the undefined properties removed.
 */
export function removeUndefinedProperties<T extends { [key: string]: any }>(obj: T): T {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== undefined) {
            acc[key as keyof T] = value;
        }
        return acc;
    }, {} as Partial<T>) as T;
}

/**
 * Retrieves a value from the session storage based on the provided key.
 * If the value does not exist, the default value is returned.
 *
 * @param key - The key used to retrieve the value from session storage.
 * @param defaultValue - The default value to return if the value does not exist.
 * @returns The retrieved value from session storage, or the default value if it does not exist.
 * @template T - The type of the value being retrieved.
 */
export function getSessionStorageValue<T>(key: string, defaultValue: T): T {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
}

/**
 * Adds the specified number of business days to the given date.
 *
 * @param date - The date to which business days should be added.
 * @param businessDays - The number of business days to add.
 * @returns The updated date after adding the specified number of business days.
 */
export const addBusinessDaysToDate = (date: moment.Moment, businessDays: number): moment.Moment => {
    // bit of type checking, and making sure not to mutate inputs ::
    const momentDate = date instanceof moment ? date.clone() : moment(date);

    if (!Number.isSafeInteger(businessDays) || businessDays <= 0) {
        // handle these situations as appropriate for your program; here I'm just returning the moment instance ::
        return momentDate;
    } else {
        // for each full set of five business days, we know we want to add 7 calendar days ::
        const calendarDaysToAdd = Math.floor(businessDays / 5) * 7;
        momentDate.add(calendarDaysToAdd, 'days');

        // ...and we calculate the additional business days that didn't fit neatly into groups of five ::
        const remainingDays = businessDays % 5;

        // if the date is currently on a weekend, we need to adjust it back to the most recent Friday ::
        const dayOfWeekNumber = momentDate.day();
        if (dayOfWeekNumber === 6) {
            // Saturday -- subtract one day ::
            momentDate.subtract(1, 'days');
        } else if (dayOfWeekNumber === 0) {
            // Sunday -- subtract two days ::
            momentDate.subtract(2, 'days');
        }

        // now we need to deal with any of the remaining days calculated above ::
        if (momentDate.day() + remainingDays > 5) {
            // this means that adding the remaining days has caused us to hit another weekend;
            // we must account for this by adding two extra calendar days ::
            return momentDate.add(remainingDays + 2, 'days');
        } else {
            // we can just add the remaining days ::
            return momentDate.add(remainingDays, 'days');
        }
    }
};
