<div class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering">
    <mat-icon *ngIf="formcontrol?.hasValidator(requiredValidator) && formcontrol?.valid" class="upload-icon">done</mat-icon>
    <button
        class="delete-icon"
        mat-icon-button
        *ngIf="formcontrol?.value && !multipleFiles"
        matTooltip="Delete picture"
        (click)="deleteUploadedPicture()"
    >
        <mat-icon>delete</mat-icon>
    </button>
    <div *ngIf="!backgroundImage" class="multifile-upload-list">
        <!-- Bij geen image dan maar tekst -->
        <div>
            <h3>File Upload Drop Zone</h3>
            <p>Drag and Drop a File</p>
        </div>
    </div>
    <!-- Achtergrond plaatje, NIET wat is geupload. -->
    <img *ngIf="!files.length && !formcontrol?.value" [src]="backgroundImage" />
    <!-- Choose file -->
    <div *ngIf="multipleFiles || !formcontrol?.value">
        <input
            #chooseFile
            hidden="true"
            type="file"
            [multiple]="multipleFiles"
            onclick="this.value=null"
            accept=".png,.jpg,.stl,.jpeg,.obj,.dcm,.tiff"
            (change)="chooseFileInput($event)"
        />
        <button
            class="choosefile-button"
            [ngClass]="{ 'multiple-files': multipleFiles }"
            mat-button
            color="primary"
            (click)="chooseFile.click()"
        >
            Open File<span *ngIf="multipleFiles">(s)</span>
        </button>
    </div>

    <div *ngIf="formcontrol?.value && showPicture; else uploadTask">
        <img [src]="downloadURL" />
    </div>
    <ng-template #uploadTask>
        <div *ngFor="let file of files" class="upload-tasks">
            <upload-task
                [prefix]="prefix"
                [customerId]="customerId"
                [orderId]="orderId"
                [file]="file"
                [formcontrol]="formcontrol"
            ></upload-task>
        </div>
    </ng-template>
</div>
<!-- Error message -->
<div *ngIf="error" class="error-message">
    <p>{{ error }}</p>
</div>
<div *ngIf="formcontrol?.value?.length && multipleFiles">
    <div class="filelist-title">
        <h3 style="margin: 0">Uploaded files</h3>
        <button
            [ngClass]="{ show: files.selectedOptions.selected.length }"
            ng
            matTooltip="Delete files"
            mat-icon-button
            (click)="deleteFiles(files.selectedOptions.selected)"
        >
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <mat-selection-list #files>
        <mat-list-option *ngFor="let file of formcontrol?.value" [value]="file">
            {{ onlyFilename(file) }}
        </mat-list-option>
    </mat-selection-list>
</div>
