<mat-card class="login-box">
    <mat-card-header>
        <div mat-card-avatar class="header_image"></div>
        <mat-card-title>Smile Art</mat-card-title>
        <mat-card-subtitle>Your smile is our art</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="login_form">
        <mat-form-field appearance="outline">
            <mat-label>Email address</mat-label>
            <input matInput [formControl]="email" (keyup.enter)="login()" />
            <mat-error *ngIf="email.hasError('required')">Email is required.</mat-error>
            <mat-error *ngIf="email.hasError('email')">Not a valid Email adress</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" [formControl]="password" (keyup.enter)="login()" />
            <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
            >
                <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="password.hasError('required')">Password is required.</mat-error>
        </mat-form-field>
        <mat-error *ngIf="error">Invalid username/password</mat-error>
        <a class="mat-small reset-password" (click)="openResetPasswordDialog()">Forgot your password?</a>
    </mat-card-content>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <mat-card-actions class="login-actions">
        <button mat-stroked-button color="primary" routerLink="/signup">Sign up</button>
        <button
            (keyup.enter)="login()"
            type="submit"
            color="primary"
            [disabled]="email.invalid || password.invalid"
            mat-raised-button
            (click)="login()"
        >
            LOGIN
        </button>
    </mat-card-actions>
</mat-card>

<ng-template #resetPasswordDialog>
    <h3 mat-dialog-title>Reset password</h3>
    <mat-dialog-content class="login_form">
        <p>Tell us the email address associated with your account.</p>
        <p>We will send you an email with a link to reset your password.</p>
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput [formControl]="resetPasswordControl" required />
            <mat-error *ngIf="resetPasswordControl.hasError('required')">Email is required</mat-error>
            <mat-error *ngIf="resetPasswordControl.hasError('email')">Not a valid Email adress</mat-error>
            <mat-error *ngIf="resetPasswordControl.hasError('userUnknown')">Unknown email</mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions class="login-actions">
        <button
            mat-raised-button
            color="primary"
            [disabled]="resetPasswordControl.invalid || resetPasswordControl.pending"
            mat-dialog-close
            (click)="resetPassword()"
        >
            Send mail
        </button>
        <button mat-raised-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</ng-template>
