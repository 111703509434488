import { TextFieldModule } from '@angular/cdk/text-field';
import { DatePipe, NgOptimizedImage } from '@angular/common';
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { ScreenTrackingService, UserTrackingService, getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { REGION } from '@angular/fire/compat/functions';
import { BUCKET, USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as Sentry from '@sentry/angular-ivy';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { QRCodeModule } from 'angularx-qrcode';
import * as moment from 'moment';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppSettingsService } from './app-settings.service';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { AppComponent } from './app.component';
import { AuthService } from './auth.service';
import { CreateOrderComponent } from './create-order/create-order.component';
import { CustomerComponent } from './customers/customer/customer.component';
import { CustomersComponent } from './customers/customers.component';
import { DropzoneDirective } from './dropzone.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { HomeComponent } from './home/home.component';
import { InvoiceConfirmationDialogComponent } from './invoices/invoice-confirmation-dialog/invoice-confirmation-dialog.component';
import { InvoiceManagementComponent } from './invoices/invoice-management/invoice-management.component';
import { InvoiceComponent } from './invoices/invoice/invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { OrderConfirmationDialogComponent } from './invoices/order-confirmation-dialog/order-confirmation-dialog.component';
import { LoginComponent } from './login/login.component';
import { MatTableResponsiveDirective } from './mat-table-responsive.directive';
import { MaterialModule } from './material.module';
import { MessageService } from './message.service';
import { NewPatientComponent } from './new-patient/new-patient.component';
import { ObjectKeysPipe } from './object-keys.pipe';
import { OrderComponent } from './orders/order/order.component';
import { OrdersComponent } from './orders/orders.component';
import { PatientLinkDesignComponent } from './patient-link-design/patient-link-design.component';
import { PatientComponent } from './patient/patient.component';
import { PatientsComponent } from './patients/patients.component';
import { ProductionSubStatusPipe } from './production-substatus.pipe';
import { ProfileComponent } from './profile/profile.component';
import { PromptUpdateService } from './promp-update.service';
import { SignupComponent } from './signup/signup.component';
import { TimelineComponent } from './timeline/timeline.component';
import { TimestampPipe } from './timestamp.pipe';
import { TypeSafeMatCellDef } from './type-safe-mat-cell-def.directive';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { UploaderComponent } from './uploader/uploader.component';
import { VerifyComponent } from './verify/verify.component';

export function momentAdapterFactory() {
    return adapterFactory(moment);
}

@NgModule({
    declarations: [
        AppComponent,
        CreateOrderComponent,
        CustomerComponent,
        CustomersComponent,
        DropzoneDirective,
        FileUploadComponent,
        HomeComponent,
        LoginComponent,
        OrderComponent,
        OrdersComponent,
        SignupComponent,
        UploaderComponent,
        UploadTaskComponent,
        ProfileComponent,
        MatTableResponsiveDirective,
        AppSettingsComponent,
        VerifyComponent,
        PatientsComponent,
        NewPatientComponent,
        PatientComponent,
        TimestampPipe,
        ObjectKeysPipe,
        ProductionSubStatusPipe,
        InvoicesComponent,
        InvoiceComponent,
        InvoiceManagementComponent,
        InvoiceConfirmationDialogComponent,
        TimelineComponent,
        PatientLinkDesignComponent,
        OrderConfirmationDialogComponent,
        TypeSafeMatCellDef,
    ],
    imports: [
        NgOptimizedImage,
        MaterialModule,
        AngularFireAuthModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        provideAnalytics(() => getAnalytics()),
        provideAuth(() => {
            const auth = getAuth();
            if (environment.useEmulators) {
                connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
            }
            return auth;
        }),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => {
            const firestore = getFirestore();
            if (environment.useEmulators) {
                connectFirestoreEmulator(firestore, 'localhost', 8080);
            }
            return firestore;
        }),
        provideFunctions(() => {
            const functions = getFunctions(getApp(), 'europe-west1');
            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            return functions;
        }),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            return storage;
        }),
        ReactiveFormsModule,
        TextFieldModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        QRCodeModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    ],
    providers: [
        AppSettingsService,
        AuthService,
        MessageService,
        PromptUpdateService,
        ScreenTrackingService,
        UserTrackingService,
        DatePipe,
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        // TODO: Delete these when not using angularfire/compat anymore. see: https://github.com/angular/angularfire/blob/master/docs/compat/emulators/emulators.md
        { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
        { provide: USE_STORAGE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9199] : undefined },
        //
        { provide: BUCKET, useValue: environment.firebase.storageBucket },
        { provide: REGION, useValue: 'europe-west1' },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({ showDialog: false }),
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
