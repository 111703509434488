import {
    Component,
    DestroyRef,
    ElementRef,
    Optional,
    TemplateRef,
    ViewChild,
    inject,
    type AfterViewInit,
    type OnDestroy,
    type Signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { serverTimestamp } from '@angular/fire/firestore';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, type SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import {
    Observable,
    combineLatest,
    combineLatestWith,
    debounceTime,
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    forkJoin,
    lastValueFrom,
    map,
    mergeMap,
    of,
    startWith,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { AppSettingsService } from '../../app-settings.service';
import { AuthService } from '../../auth.service';
import { HomeComponent } from '../../home/home.component';
import { IdService } from '../../id.service';
import { InvoiceService } from '../../invoice.service';
import { MessageService, type ChatroomItem } from '../../message.service';
import { OrdersService } from '../../orders.service';
import {
    PRODUCTION_SUB_STATUSES,
    type Order,
    type OrderStatus,
    type OrderWithFirestoreId,
    type ProductionSubStatus,
    type UpdateOrderRequest,
} from '../../types';
import { addBusinessDaysToDate, deadlineReached, isDefined, objectKeys } from '../../util';

const URL_REGEXP = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;

type OrderWithUnreadmessages = OrderWithFirestoreId & {
    unreadMessages?: boolean;
};

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements AfterViewInit, OnDestroy {
    /** Observable of the current order. Undefined if no order is selected. */
    item$: Observable<OrderWithUnreadmessages | undefined>;
    /** Signal of the current order. Undefined if no order is selected. FIXME: Use this signal instead of lastValueFrom(this.item$) */
    itemSignal$: Signal<OrderWithUnreadmessages | undefined>;
    /** Observable of the messages associated with the current order. */
    messages$: Observable<ChatroomItem[]>;
    /** Observable of the picture uploads associated with the current order. Undefined if no pictures have been uploaded. */
    pictureUploads$: Observable<string[] | undefined>;
    /** Observable of the digital scans associated with the current order. Undefined if no digital scans have been uploaded. */
    digitalScans$: Observable<Array<{ url: string; filename: string }> | undefined>;
    /** Observable of the calculated pricing for the current order. Undefined if pricing has not been calculated. */
    calculatedPricing$: Signal<number | undefined>;
    /** Calculated pricing for the current order. Undefined if pricing has not been calculated. */
    calculatedPricing?: number;
    /** Flag indicating whether the current user is an admin. */
    admin = false;
    /** Document ID of the current order. Null if no order is selected. */
    docId?: string | null;
    /** Customer ID associated with the current order. Null if no customer is associated with the order. */
    customerId?: string | null;
    /** Flag to indicate the pictures are from the referenced Aligner order (Refinement) */
    picturesFromReferencedAlignerOrder = false;
    /**
     * Reference to the destroyRef object.
     */
    private destroyRef = inject(DestroyRef);

    @ViewChild('updateDialog') updateDialog!: TemplateRef<unknown>;
    @ViewChild('pictureDialog') pictureDialog!: TemplateRef<unknown>;
    @ViewChild('modelViewer') modelViewer!: TemplateRef<unknown>;
    @ViewChild('notesDialog') notesDialog!: TemplateRef<unknown>;
    @ViewChild('overridePriceDialog') overridePriceDialog!: TemplateRef<unknown>;
    @ViewChild('messageList', { read: ElementRef }) messageList!: ElementRef;
    @ViewChild('messageDrawer') messageDrawer!: MatDrawer;

    designUrl = new FormControl('', [Validators.required, Validators.pattern(URL_REGEXP)]);
    overridePrice = new FormControl<number | null>(null, [Validators.required, Validators.min(0)]);
    amountOfModels = new FormControl<number>(1, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(1), Validators.pattern(/^\d+$/)],
    });
    trackAndTrace = new FormControl('', [Validators.pattern(URL_REGEXP)]);
    manualDelivery = new FormControl(false, { nonNullable: true });
    adminNotes = new FormControl('');
    newMessageControl = new FormControl('', { nonNullable: true });

    /** Seperate property for sanitizing URL */
    modelURL?: SafeResourceUrl;
    /** Boolean if we currently are editing the designURL in the modelviewer */
    editURL = false;
    /** Image URL for showing second(smile) picture as avatar */
    avatarURL?: string;
    /** Last time admin notes was automatically saved */
    adminNotesSavedAt?: Date;
    /** Show progress bar when saving notes */
    savingNotes = false;

    constructor(
        private readonly auth: AuthService,
        private readonly dialog: MatDialog,
        private readonly idService: IdService,
        private readonly invoiceService: InvoiceService,
        private readonly messageService: MessageService,
        private readonly ordersService: OrdersService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly sanitizer: DomSanitizer,
        private readonly settingsService: AppSettingsService,
        private readonly snackBar: MatSnackBar,
        private readonly storage: AngularFireStorage,
        @Optional() private readonly home?: HomeComponent,
    ) {
        auth.admin$.pipe(takeUntilDestroyed()).subscribe(v => (this.admin = v));

        this.item$ = combineLatest([route.paramMap, auth.firestoreId$, auth.admin$]).pipe(
            mergeMap(([paramMap, firestoreId, admin]) => {
                this.docId = paramMap.get('id');
                const customerId = paramMap.get('customerid');
                // Only set customerId from url if admin is true, non-admins should only see their own orders.
                if (customerId && admin) {
                    this.customerId = customerId;
                } else {
                    this.customerId = firestoreId;
                }
                // If we are in the rare case of a customer being an admin, we should use the firestoreId instead of the customerId.
                if (admin && !this.customerId) {
                    this.customerId = firestoreId;
                }

                if (!this.docId || !this.customerId) {
                    throw new Error('Could not determine ids for order');
                }
                return this.ordersService.getOrder(this.customerId, this.docId).pipe(
                    filter(isDefined),
                    map(order => {
                        const checkUnreadMessage = () => {
                            const email = this.auth.userSignal$()?.email?.replace('.', ','); // Firestore does not allow dots in field names, so we replace them with commas.
                            if (!email) return false;

                            // If the chat drawer is open, we assume the user has seen the messages.
                            if (this.messageDrawer.opened) {
                                return false;
                            }

                            const lastSeen = order.lastSeen?.[email];
                            const lastMessage = order.lastMessage;

                            // Migration, if the lastMessage is from before 24-04-2024, assume the user has seen the message.
                            if (lastMessage && lastMessage?.toDate() < new Date('2024-04-24')) return false;

                            if (order.status === 'Waiting for acceptance' && !lastMessage) return false;

                            // If no lastMessage is set, there shouldnt be any unread messages.
                            if (!lastMessage) return false;
                            // If the user has never seen the order, the user has unseen messages
                            if (!lastSeen) return true;
                            // If the lastSeen timestamp is before the lastMessage timestamp, the user has unseen messages
                            return lastSeen < lastMessage;
                        };
                        return {
                            ...order,
                            unreadMessages: checkUnreadMessage(),
                        };
                    }),
                );
            }),
        );

        // Create a signal from the item observable.
        this.itemSignal$ = toSignal(this.item$);

        route.params.pipe(takeUntilDestroyed()).subscribe(async () => {
            const email = this.auth.userSignal$()?.email;
            if (!this.customerId || !this.docId || !email || !this.messageDrawer.opened) return;
            await this.messageService.markMessagesAsSeen(this.customerId, this.docId, email);
        });

        this.item$
            .pipe(
                map(v => v?.adminNotes),
                filter(isDefined),
                filter(v => v !== this.adminNotes.value),
                takeUntilDestroyed(),
            )
            .subscribe(notes => this.adminNotes.patchValue(notes));

        this.messages$ = this.item$.pipe(
            switchMap(() => {
                if (!this.customerId || !this.docId) throw new Error('Couldnt determine ids for chatroom');
                return this.messageService.getChatroom(this.customerId, this.docId);
            }),
            takeUntilDestroyed(),
        );

        this.pictureUploads$ = this.item$.pipe(
            filter(isDefined),
            distinctUntilKeyChanged('pictures'),
            switchMap(async order => {
                let picturesToDownload = order.pictures;
                this.picturesFromReferencedAlignerOrder = false;
                if (!picturesToDownload) {
                    // If the order is a Refinement, we should have pictures from the original Aligner order.
                    if (order.type === 'Refinement') {
                        // TODO: Specific Types for specific orders so we can use the correct properties.
                        if (!order.refinementFor) return;
                        const alignerOrder = this.admin
                            ? await this.ordersService.getOrderByFunctionalID(order.refinementFor)
                            : await this.ordersService.getOrderByFunctionalID(order.refinementFor, order.customer.firestoreId);
                        if (alignerOrder) {
                            picturesToDownload = alignerOrder.pictures;
                            this.picturesFromReferencedAlignerOrder = true;
                        }
                    } else {
                        // If we have no pictures and the order is not a refinement, we should return undefined.
                        return;
                    }
                }
                // At this point picturesToDownload should be set. Either from the order itself or from the referenced Aligner order.
                if (!picturesToDownload) return;

                // Extract keys, sort them, and map back to values.
                const sortedKeys = objectKeys(picturesToDownload ?? {}).sort();
                return sortedKeys.map(key => picturesToDownload[key]).filter((v): v is string => v !== null);
            }),
            switchMap(pictures => {
                if (!pictures) return of(undefined);
                const downloadObservables = pictures.map(picture => storage.ref(picture).getDownloadURL());
                return forkJoin(downloadObservables);
            }),
        );

        this.digitalScans$ = this.item$.pipe(
            filter(isDefined),
            distinctUntilKeyChanged('digitalScans'),
            switchMap(async order => {
                if (!order?.digitalScans?.scans.length) return;
                const promises = order.digitalScans.scans.map(async v => {
                    const filename = v.split('/').at(-1) as string;
                    return lastValueFrom(storage.ref(v).getDownloadURL()).then((url: string) => ({ url, filename }));
                });
                const downloadUrls = await Promise.all(promises);
                return downloadUrls;
            }),
        );

        this.item$.pipe(takeUntilDestroyed()).subscribe(async v => {
            const url = v?.designURL;
            if (url) {
                this.modelURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                this.designUrl.setValue(url);
            }
            const avatarStoragePath = v?.pictures?.second;
            if (avatarStoragePath) {
                const ref = this.storage.ref(avatarStoragePath);
                this.avatarURL = await lastValueFrom(ref.getDownloadURL());
            }
        });

        this.adminNotes.valueChanges.pipe(distinctUntilChanged(), debounceTime(600), takeUntilDestroyed()).subscribe(async adminNotes => {
            if (!this.customerId || !adminNotes || !this.docId) return;
            this.savingNotes = true;
            await this.ordersService.updateOrder(this.customerId, this.docId, { adminNotes });
            this.adminNotesSavedAt = new Date();
            this.savingNotes = false;
        });

        this.calculatedPricing$ = toSignal(
            // TODO: #203 Check if we can use more signals and make this a computed signal.
            // For now we just used the toSignal() function to create a signal from the observable.
            this.item$.pipe(
                filter(isDefined),
                takeUntilDestroyed(),
                tap(() => (this.loadingPricing = true)),
                combineLatestWith(
                    this.amountOfModels.valueChanges.pipe(
                        tap(() => (this.loadingPricing = true)),
                        startWith(1),
                        debounceTime(600),
                    ),
                    this.settingsService.settingsDoc$,
                ),
                map(([order, quantity, settings]) => {
                    // If already calculated, return that.
                    if (order.pricing?.amount) {
                        return order.pricing.amount;
                    }
                    // If no settings can be found, return undefined.
                    if (!settings) {
                        this.calculatedPricing = undefined;
                        this.loadingPricing = false;
                        return;
                    }
                    // If the order is an aligner and the amount of models filled in is invalid, return undefined.
                    if (order.type === 'Aligner' && this.amountOfModels.invalid) {
                        this.calculatedPricing = undefined;
                        this.loadingPricing = false;
                        return;
                    }
                    // Else calculate the pricing..
                    const pricing = settings.orderDefaults[order.type].pricing;
                    // Thresholds
                    if (typeof pricing === 'object') {
                        const threshold = pricing.thresholds
                            ?.sort((a, b) => a.min - b.min)
                            .reverse()
                            .find(threshold => quantity >= threshold.min);
                        if (!threshold) {
                            this.calculatedPricing = undefined;
                            this.loadingPricing = false;
                            return;
                        }
                        const amount = order.lowerArch && order.upperArch ? threshold.price.double : threshold.price.single;
                        this.calculatedPricing = amount;
                        this.loadingPricing = false;
                        return amount;
                    } else {
                        // Else single price per arch
                        this.calculatedPricing = order.lowerArch && order.upperArch ? pricing * 2 : pricing;
                        this.loadingPricing = false;
                        return this.calculatedPricing;
                    }
                }),
            ),
        );
    }
    ngOnDestroy(): void {
        // Mark messages as seen when navigating away from the order and the messageDrawer is open.
        if (this.customerId && this.docId && this.messageDrawer.opened) {
            this.messageService.markMessagesAsSeen(this.customerId, this.docId, this.auth.userSignal$()?.email ?? '');
        }
    }
    ngAfterViewInit(): void {
        /**
         * After the view initializes, perform several operations:
         * 1. If the 'showmessages' query parameter is set and the messageDrawer exists, open the messageDrawer.
         * 2. When the messageDrawer is first opened, scroll to the bottom of the messages.
         * 3. When the messageDrawer is opened, scroll to the top of the home toolbar.
         * 4. Listen for changes in the messages and scroll to the bottom of the messages when a change occurs.
         *
         * The operations are wrapped in a setTimeout to ensure they are executed in the next change detection cycle,
         * preventing ExpressionChangedAfterItHasBeenCheckedError.
         */
        setTimeout(async () => {
            // Open messageDrawer when query param from email notification is set.
            if (this.route.snapshot.queryParams['showmessages'] && this.messageDrawer) {
                this.messageDrawer.open().then(async () => {
                    this.scrollToBottomMessage();
                    if (!this.customerId || !this.docId) return;
                    await this.messageService.markMessagesAsSeen(this.customerId, this.docId, this.auth.userSignal$()?.email ?? '');
                });
            }
            // Scroll once when first opened.
            // this.messageDrawer?.openedStart.pipe(take(1)).subscribe(() => this.scrollToBottomMessage());
            // Second listener to scroll to the top when opening the drawer and mark messages as seen.
            this.messageDrawer?.openedStart.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async () => {
                this.home?.toolbar.nativeElement.scrollIntoView({ behavior: 'smooth' });
                if (!this.customerId || !this.docId) return;
                await this.messageService.markMessagesAsSeen(this.customerId, this.docId, this.auth.userSignal$()?.email ?? '');
            });
            // ..And then listen to changes.
            this.messages$?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.scrollToBottomMessage());
        });
    }

    loadingPricing = false;
    productionSubstatusCheckbox = new FormControl<ProductionSubStatus | 'Send'>('Partly Printed', { nonNullable: true });

    isProductionUpdateButtonDisabled() {
        if (isProductionSubstatus(this.productionSubstatusCheckbox.value)) return false;
        if (this.trackAndTrace.touched && this.trackAndTrace.invalid) {
            return this.manualDelivery.value === false ? true : false;
        } else if (this.manualDelivery.value === false) {
            return this.trackAndTrace.value && this.trackAndTrace.valid ? false : true;
        }
        return false;
    }

    getUpperLowerArch(item: Order) {
        if (item.lowerArch && item.upperArch) return 'Both';
        if (item.lowerArch && !item.upperArch) return 'Only Lower Arch';
        if (!item.lowerArch && item.upperArch) return 'Only Upper Arch';
        return;
    }

    /**
     * Add a message to the current order's case discussion.
     * Resets the newMessageControl.
     */
    async addMessage() {
        if (!this.customerId || !this.docId || !this.newMessageControl.value) return;
        await this.messageService.addMessage({
            customerId: this.customerId,
            orderId: this.docId,
            message: this.newMessageControl.value,
            type: 'chat',
        });
        this.newMessageControl.reset();
    }

    isOwnMessage(message: ChatroomItem) {
        if (message.type === 'system') return false;
        // If its an admin message, and the current user is admin, return true.
        if (message.admin && this.admin) return true;
        // If the message is from the current user, return true.
        if (message.email === this.auth.userSignal$()?.email || message.user === this.auth.userSignal$()?.displayName) return true;
        return false;
    }

    scrollToBottomMessage() {
        // Scroll to bottom in message list.
        this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight;
    }

    async openPdfViewer() {
        const item = await lastValueFrom(this.item$.pipe(take(1)));
        if (!item || !this.docId) return;
        (await this.invoiceService.createPDF([{ ...item, firestoreId: this.docId }])).open();
    }

    openUpdateOrderDialog() {
        this.dialog.open(this.updateDialog);
    }

    async openOverridePriceDialog() {
        const current = (await lastValueFrom(this.item$.pipe(take(1))))?.pricing?.amount;
        if (current) this.overridePrice.setValue(current);
        this.dialog.open(this.overridePriceDialog);
    }

    openPictureDialog(url: string) {
        this.dialog.open(this.pictureDialog, {
            data: { url },
            height: 'calc(100% - 30px)',
            width: 'calc(100% - 30px)',
            maxWidth: '100%',
            maxHeight: '100%',
        });
    }

    // TODO: #135 Move design card to seperate component
    designVisible = false;
    toggleViewDesign() {
        this.designVisible = !this.designVisible;
    }

    openNotesDialog() {
        this.dialog.open(this.notesDialog, { width: '90%' });
    }

    canViewInvoice(item: Order): boolean {
        const allowedStatus: OrderStatus[] = [...PRODUCTION_SUB_STATUSES, 'In production', 'Send'];
        return !!item.pricing && this.admin && allowedStatus.includes(item.status);
    }

    refinementDeadlineReached(order: OrderWithFirestoreId) {
        // Admins can always request a refinement
        if (this.admin) return true;
        if (!order.lastTimeUpdated) return false;
        return deadlineReached(order.lastTimeUpdated, { amount: order.pricing?.quantity, unit: 'weeks' });
    }

    async navigateByFunctionalId(functionalId: string | undefined): Promise<void> {
        if (!functionalId) throw new Error('No functionalId received when navigating by FunctionalID');

        let referencedOrder: OrderWithFirestoreId | undefined;
        if (this.admin) {
            // If we are an admin, we cant use the customerId to get the order. Since it is possible not -our- order.
            // So we use the functionalId and no customerId get the referenced order.
            referencedOrder = await this.ordersService.getOrderByFunctionalID(functionalId);
        } else {
            if (!this.customerId) throw new Error('Could not determine customerId when navigating to referenced order');
            referencedOrder = await this.ordersService.getOrderByFunctionalID(functionalId, this.customerId);
        }

        if (!referencedOrder) return;
        this.router.navigate(
            this.admin
                ? ['customers', referencedOrder.customer.firestoreId, 'orders', referencedOrder.firestoreId]
                : ['orders', referencedOrder.firestoreId],
        );
    }

    async updateStatus(status: OrderStatus, order: OrderWithFirestoreId) {
        const payload: UpdateOrderRequest = {
            status,
        };
        if (status === 'Ready for review') {
            payload.designURL = this.designUrl.value;
            payload.shareDesignCode = Math.random().toString(36).substring(2, 8);
            // FIXME: We hebben nu amountOfModels en pricing.quantity, dit is dubbelop.
            payload.amountOfModels = this.amountOfModels.value;
            // TODO: Dubbelcheck of this.amountOfModels.value undefined kan zijn.??
            // Misschien moeten we een default waarde instellen.
            payload.pricing = {
                quantity: this.amountOfModels.value,
                amount: this.overridePrice.value ? this.overridePrice.value : this.calculatedPricing,
            };
        }
        if (status === 'In production') {
            const desiredCompletionDate = this.calculateDesiredCompletionDate(order);
            const currentDesiredDate = order.desiredCompletionDate;
            // If the newly calculated date is further in the future than the current desiredCompletionDate, use the new date.
            // Only update the desiredCompletionDate if the new date is further in the future and not the same day.
            // TODO: #206 Desired overal renamen naar estimated.
            if (currentDesiredDate == null) {
                payload.desiredCompletionDate = desiredCompletionDate;
            } else if (
                currentDesiredDate.toDate() < desiredCompletionDate &&
                currentDesiredDate.toDate().getDate() !== desiredCompletionDate.getDate()
            ) {
                payload.desiredCompletionDate = desiredCompletionDate;
            }
            // Als de order niet van het type 'Aligner' is dan is er geen review.
            // Dus gaan we van 'In Design' direct naar 'In Production'.
            const amount = this.calculatedPricing$() ?? order.pricing?.amount;
            if (amount == null) throw new Error(`Could not determine amount for pricing when setting item to 'In Production'`);
            payload.pricing = {
                quantity: order.pricing?.quantity ?? this.amountOfModels.value,
                amount,
            };
            // Set invoiceId, note this is an invoiceId for the order. Not for the actual monthly invoice which is generated by the invoiceService and are send to the customer.
            const invoiceId = await this.idService.getNewInvoiceNr();
            payload.invoiceId = invoiceId;
        }
        // Als de order klaar is en verzonden is zetten we de track n trace link.
        if (status === 'Send') {
            payload.sendDate = serverTimestamp();
            payload.trackAndTrace = this.trackAndTrace.value;
            // If the manualDelivery checkbox is checked, set the manualDelivery timestamp.
            if (this.manualDelivery.value) {
                payload.manualDelivery = { timestamp: serverTimestamp() };
            }
        }
        this.dialog.afterAllClosed.pipe(take(1)).subscribe(async () => {
            if (!this.customerId || !this.docId) return;
            await this.ordersService.updateOrder(this.customerId, this.docId, { ...payload, lastTimeUpdated: serverTimestamp() });
            this.snackBar.open(`Status updated to ${status}`, 'close', { duration: 5000 });
            // Only send a system message if the status it NOT a production substatus.
            if (!isProductionSubstatus(status)) {
                let message: string;
                if (status === 'In production' && payload.desiredCompletionDate != null) {
                    message = `Order status updated to ${status}! Please notice the delivery date has changed to ${(payload.desiredCompletionDate as Date).toLocaleDateString('nl-NL')}`;
                } else {
                    message = `Order status updated to ${status}!`;
                }
                await this.messageService.addMessage({
                    customerId: this.customerId,
                    orderId: this.docId,
                    type: 'system',
                    message,
                });
            }
        });
        this.dialog.closeAll();
    }

    async saveEditedURL(): Promise<void> {
        if (this.designUrl.valid && this.designUrl.value && this.customerId && this.docId) {
            this.editURL = false;
            await this.ordersService.updateOrder(this.customerId, this.docId, {
                designURL: this.designUrl.value,
            });
            this.modelURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.designUrl.value);
        }
    }

    async overridePricing(order: OrderWithFirestoreId): Promise<void> {
        const newPrice = this.overridePrice.value;
        const onBehalf = this.auth.userSignal$()?.email;
        if (newPrice == null || !onBehalf) {
            throw new Error('Could not determine price/onBehalf for manual override.');
        }
        await this.ordersService.updateOrder(order.customer.firestoreId, order.firestoreId, {
            pricing: { quantity: order.amountOfModels ?? order.pricing?.quantity, amount: newPrice, manual: true, onBehalf },
        });
        this.snackBar.open(`Pricing updated to €${newPrice},-`, 'close', { duration: 5000 });
    }

    sharedLink: string | null = null;
    copyDesignLink() {
        if (!this.sharedLink) return;
        const input = document.createElement('input');
        input.value = this.sharedLink;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        this.snackBar.open('Design link copied to clipboard', 'close', { duration: 5000 });
    }

    getSharedLink(order: OrderWithFirestoreId): string {
        const hash = this.ordersService.hash(order.customer.firestoreId, order.firestoreId);
        const link = `${window.location.origin}/share/${hash}`;
        this.sharedLink = link;
        return link;
    }

    async setShareDesignCode(order: OrderWithFirestoreId): Promise<void> {
        await this.ordersService.setShareDesignCode(order.customer.firestoreId, order.firestoreId);
    }

    printQRforPatient(order: OrderWithFirestoreId): void {
        if (!order.shareDesignCode) throw new Error('Could not find shareDesignCode for order');
        const link = this.getSharedLink(order);
        this.ordersService.getQRPpdf(link, order.shareDesignCode);
    }

    calculateDesiredCompletionDate(order: OrderWithFirestoreId): Date {
        // Calculate a new desiredCompletionDate based on the current date and the default deadline.
        const settings = this.settingsService.settingsSignal$();
        if (!settings) throw new Error('Could not determine settings when updating status to In design');
        const defaultDays = settings.orderDefaults[order.type].deadline;
        const desiredCompletionDate = addBusinessDaysToDate(moment(), defaultDays).toDate();
        return desiredCompletionDate;
    }
}

function isProductionSubstatus(value: string): value is ProductionSubStatus {
    return PRODUCTION_SUB_STATUSES.includes(value as ProductionSubStatus);
}
