<mat-toolbar>
  <div class="title-box">
    <mat-icon class="title-icon" matListItemIcon>person_search</mat-icon>
    <span class="subtitle">My Patients</span>
    <button mat-raised-button (click)="toggleShowNewPatientForm()">{{ showNewPatientForm ? 'Close ' : 'Create new patient' }}</button>
  </div>
</mat-toolbar>
<mat-divider></mat-divider>
@if (customer$ | async; as customer) {
  <div class="content-wrapper">
    @if (showNewPatientForm) {
      <app-new-patient [customer]="customer" (patientCreated)="onNewPatientCreated($event.id)"></app-new-patient>
      <mat-divider></mat-divider>
    } @else {
      <mat-form-field class="table-filter" subscriptSizing="dynamic">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input />
      </mat-form-field>
      <ng-container>
        <table mat-table matTableResponsive [dataSource]="dataSource" class="mat-elevation-z8" matSort>
          <ng-container matColumnDef="firstName">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>First name</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.firstName }}</td>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Last name</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.lastName }}</td>
          </ng-container>
          <ng-container matColumnDef="practice">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Practice</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.practice.name }}</td>
          </ng-container>
          <ng-container matColumnDef="birthdate">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Birthdate</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.birthdate | timestamp }}</td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            @if (input.value) {
              <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
            }
            @if (!input.value) {
              <td class="mat-cell" colspan="4">No patients found</td>
            }
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="['/patients', row.id]"></tr>
        </table>
      </ng-container>
    }
  </div>
}
