import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Firestore, doc, docData } from '@angular/fire/firestore';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { AuthService } from '../auth.service';
import { PatientService } from '../patient.service';
import { Customer, Patient } from '../types';
import { typeConverter } from '../util';

@Component({
    selector: 'app-patients',
    templateUrl: './patients.component.html',
    styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnDestroy {
    displayedColumns: Array<keyof Patient> = ['firstName', 'lastName', 'practice', 'birthdate'];
    dataSource = new MatTableDataSource<Patient>();
    @ViewChild(MatSort) sort?: MatSort;

    showNewPatientForm = false;
    customer$: Observable<Customer | undefined>;
    private readonly destroyed$ = new Subject<void>();
    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    constructor(
        readonly firestore: Firestore,
        private readonly patientService: PatientService,
        private readonly authService: AuthService,
        private readonly router: Router,
    ) {
        this.customer$ = this.authService.firestoreId$.pipe(
            takeUntil(this.destroyed$),
            switchMap(customerId => {
                // TODO: refactor to use a service
                const document = doc(firestore, `/customers/${customerId}`).withConverter(
                    typeConverter<Customer & { firestoreId: string }>(),
                );

                return docData(document, { idField: 'firestoreId' });
            }),
        );

        this.authService.firestoreId$
            .pipe(
                switchMap(id => this.patientService.getPatients(id)),
                takeUntil(this.destroyed$),
            )
            .subscribe(patients => {
                this.dataSource.data = patients;
                this.dataSource.filter = '';
                this.dataSource.sort = this.sort || null;
            });
    }

    /**
     * Applies a filter to the data source based on the input value.
     *
     * @param event The event object triggered by the input element.
     */
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    toggleShowNewPatientForm() {
        this.showNewPatientForm = !this.showNewPatientForm;
    }

    /**
     * Handles the event when a new patient is created.
     * @param {string} patientId - The ID of the newly created patient.
     * @returns {void}
     */
    onNewPatientCreated(patientId: string): void {
        this.showNewPatientForm = false;
        this.router.navigate(['patients', patientId]);
    }
}
