import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { PasswordValidators } from '../password-validators';

type EmailAction = 'resetPassword' | 'recoverEmail' | 'verifyEmail';

@Component({
    selector: 'app-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly auth: AuthService,
        private readonly snack: MatSnackBar,
    ) {}

    mode?: EmailAction;
    token?: string | null;
    /** Toggle for showing/hiding password field entry */
    hide = true;

    newPasswordForm = new FormGroup(
        {
            password: new FormControl('', { validators: [Validators.required, Validators.minLength(8)], nonNullable: true }),
            confirmPassword: new FormControl(null, [Validators.required]),
        },
        // Note: Matchvalidator is on the formGroup control but sets the errors on 'confirmPassword'.
        { validators: PasswordValidators.MatchValidator },
    );

    ngOnInit(): void {
        const mode = (this.mode = this.route.snapshot.queryParamMap.get('mode') as EmailAction);
        const token = (this.token = this.route.snapshot.queryParamMap.get('oobCode'));
        this.continueUrl = this.route.snapshot.queryParamMap.get('continueUrl');
        if (!token || !mode) {
            console.info(`No verification token or mode found, rerouting..`);
            this.router.navigate(['/']);
        } else {
            switch (mode) {
                case 'verifyEmail':
                    this.auth
                        .verifyAccount(token)
                        .then(user => {
                            console.info(`Verification success!`);
                            if (user) {
                                user.reload();
                            }
                        })
                        .catch(this.catchError);
                    break;
            }
        }
    }

    private catchError = (e: Error) => {
        console.error(e);
        this.error = e.message;
    };

    async resetPassword() {
        if (!this.token) throw new Error('Could not get email action token during password reset flow.');
        await this.auth
            .resetPassword(this.token, this.newPasswordForm.controls.password.value)
            .then(() => {
                this.snack.open('Password reset complete!', 'close', { duration: 5000 });
                this.router.navigate(['/']);
            })
            .catch(this.catchError);
    }

    async resendVerificationMail() {
        await this.auth.sendVerificationMail();
    }

    error?: string;
    continueUrl?: string | null;
}
