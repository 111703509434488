@if (data.length) {
  <div class="with-data" [class.line]="data.length > 1">
    @for (status of data; track status) {
      <div class="itemcontainer" [class.current]="isCurrentStatus(status)" [class.done]="statusDone(status)">
        <div class="item">
          <p>{{ status }}</p>
        </div>
      </div>
    }
  </div>
}
