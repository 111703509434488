import { Injectable } from '@angular/core';
import { doc, DocumentReference, Firestore, runTransaction } from '@angular/fire/firestore';
import { typeConverter } from './util';

/**
 * The starting sequence for generating IDs.
 */
const DEFAULT_STARTING_SEQUENCE = '312';

type Sequence = {
    latest: string;
};

/**
 * Service for generating new IDs for orders, customers, and invoices.
 */
@Injectable({
    providedIn: 'root',
})
export class IdService {
    constructor(private readonly firestore: Firestore) {}

    private readonly orderSequenceRef = doc(this.firestore, 'ordersequence/latest').withConverter(typeConverter<Sequence>());
    private readonly customerSequenceRef = doc(this.firestore, 'customersequence/latest').withConverter(typeConverter<Sequence>());
    private readonly invoiceSequenceRef = doc(this.firestore, 'invoicesequence/latest').withConverter(typeConverter<Sequence>());

    /** Increment corresponding document reference */
    private async increment(reference: DocumentReference<Sequence>, startOfSequence?: string) {
        return await runTransaction(this.firestore, async txn => {
            const latest = (await txn.get(reference)).data()?.latest;
            if (!latest) {
                const STARTING_SEQUENCE = startOfSequence ?? DEFAULT_STARTING_SEQUENCE;
                txn.set(reference, { latest: STARTING_SEQUENCE });
                return STARTING_SEQUENCE;
            } else {
                const newValue = (parseInt(latest) + 1).toString();
                txn.update(reference, { latest: newValue });
                return newValue;
            }
        });
    }

    /**
     * Get a new -functional- order number.
     * e.g. #312
     * @returns The new order number.
     */
    async getNewOrderNr() {
        return await this.increment(this.orderSequenceRef);
    }

    /**
     * Get a new customer number.
     * @returns The new customer number.
     */
    async getNewCustomerNr() {
        return await this.increment(this.customerSequenceRef);
    }

    /**
     * Get a new invoice number.
     * @returns The new invoice number.
     */
    async getNewInvoiceNr() {
        return await this.increment(this.invoiceSequenceRef);
    }

    /**
     * Get a new monthly invoice number.
     * @returns The new invoice number.
     */
    async getNewMonthlyInvoiceNr({
        functionalId,
        firestoreId,
        year,
        month,
    }: {
        functionalId: string;
        firestoreId: string;
        year: number;
        month: number;
    }) {
        const monthlyInvoiceRef = doc(this.firestore, `customers/${firestoreId}/invoicesequence/latest`).withConverter(
            typeConverter<Sequence>(),
        );
        const seq = await this.increment(monthlyInvoiceRef, '001');
        const paddedSeq = String(seq).padStart(3, '0');
        return `${functionalId}-${year}-${month}-${paddedSeq}`;
    }
}
