<mat-card class="signup-box">
    <mat-card-header>
        <div mat-card-avatar class="header_image"></div>
        <mat-card-title>Smile Art</mat-card-title>
        <mat-card-subtitle>Your smile is our art</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="profileForm" (keyup.enter)="openSignupDialog()">
            <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input formControlName="email" matInput />
                <mat-error *ngIf="f.email.hasError('required')">Email is required.</mat-error>
                <mat-error *ngIf="f.email.hasError('email')">Not a valid Email adress</mat-error>
                <mat-error *ngIf="f.email.hasError('userAlreadyExists')">This email is already in use.</mat-error>
            </mat-form-field>
            <div class="signup-wrapper">
                <div class="name-block">
                    <mat-form-field appearance="fill">
                        <mat-label>First name</mat-label>
                        <input formControlName="firstName" matInput />
                        <mat-error *ngIf="f.firstName.hasError('required')">First name is required.</mat-error>
                        <mat-error *ngIf="f.firstName.hasError('pattern')">Only letters are allowed for first name</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Last name</mat-label>
                        <input formControlName="lastName" matInput />
                        <mat-error *ngIf="f.lastName.hasError('required')">Last name is required.</mat-error>
                        <mat-error *ngIf="f.lastName.hasError('pattern')">Only letters are allowed for last name</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field appearance="fill">
                <mat-label>Phone number</mat-label>
                <input #phoneinput formControlName="phonenumber" matInput type="text" inputmode="numeric" />
                <mat-icon matSuffix>phone</mat-icon>
                <mat-error *ngIf="f.phonenumber.hasError('pattern')">Only numbers are allowed (max 15)</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" [formControl]="f.password" />
                <button
                    tabindex="-1"
                    mat-icon-button
                    matSuffix
                    type="button"
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                >
                    <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="f.password.hasError('required')">Password is required.</mat-error>
                <mat-error *ngIf="f.password.hasError('minlength')">Password must be at least 8 characters long.</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Confirm Password</mat-label>
                <input matInput type="password" [formControl]="f.confirmPassword" />
                <mat-error *ngIf="f.confirmPassword.hasError('required')">Password confirmation is required.</mat-error>
                <mat-error *ngIf="f.confirmPassword.hasError('mismatch')">Password confirmation does not match.</mat-error>
            </mat-form-field>
        </form>
        <mat-error *ngIf="error">Something went wrong with the signup process, please try again or contact info&#64;smileart.nl</mat-error>
    </mat-card-content>
    <mat-card-actions class="signup-actions">
        <button tabindex="-1" mat-stroked-button color="primary" routerLink="/login">Cancel</button>
        <button
            type="submit"
            (keyup.enter)="openSignupDialog()"
            color="primary"
            [disabled]="profileForm.invalid"
            mat-raised-button
            (click)="openSignupDialog()"
        >
            Complete signup
        </button>
    </mat-card-actions>
</mat-card>

<ng-template #signupDialog>
    <h1 mat-dialog-title>Smile Art EULA</h1>
    <mat-dialog-content>
        <p>END USER LICENSE AGREEMENT (EULA)</p>
        <p>
            PLEASE READ THIS END USER LICENSE AGREEMENT ("AGREEMENT") CAREFULLY BEFORE USING THE CLEAR ALIGNER SERVICES OFFERED BY SMILE ART
            ("COMPANY"). BY USING THE SERVICES, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO
            THE TERMS OF THIS AGREEMENT, DO NOT USE THE SERVICES.
        </p>
        <p>GRANT OF LICENSE</p>
        <p>
            Subject to the terms and conditions of this Agreement, Smile Art grants you a limited, non-exclusive, non-transferable license
            to access and use the Smile Art clear aligner services ("Services"). You may only use the Services for your personal use and may
            not resell or transfer the Services to any third party.
        </p>
        <p>RESTRICTIONS ON USE</p>
        <p>
            You agree not to use the Services in any way that violates any applicable laws or regulations or infringes on the intellectual
            property rights of Smile Art or any third party. You also agree not to use the Services to upload, post, transmit or otherwise
            distribute any content that is unlawful, defamatory, abusive, obscene, or otherwise objectionable.
        </p>

        <p>USER ACCOUNTS</p>
        <p>
            To use the Services, you must create an account with Smile Art. You agree to provide accurate and complete information when
            creating your account and to keep your account information up-to-date. You are responsible for maintaining the confidentiality
            of your account login information and for all activities that occur under your account.
        </p>
        <p>PAYMENT</p>
        <p>
            You agree to pay all fees associated with your use of the Services. Fees are subject to change at any time and Smile Art will
            provide you with notice of any changes to the fees. Failure to pay fees may result in suspension or termination of your access
            to the Services.
        </p>
        <p>WARRANTY DISCLAIMER</p>
        <p>
            Smile Art makes no warranties or representations about the Services, including but not limited to the accuracy, reliability,
            completeness, or timeliness of the Services. The Services are provided "as is" without warranty of any kind.
        </p>
        <p>LIMITATION OF LIABILITY</p>
        <p>
            In no event shall Smile Art be liable to you or any third party for any direct, indirect, incidental, special, or consequential
            damages arising out of or in connection with your use of the Services, including but not limited to damages for loss of profits,
            goodwill, use, data or other intangible losses.
        </p>
        <p>INDEMNIFICATION</p>
        <p>
            You agree to indemnify, defend, and hold harmless Smile Art and its affiliates, officers, directors, employees, and agents from
            any and all claims, damages, expenses, and liabilities (including reasonable attorneys' fees) arising out of or in connection
            with your use of the Services or any breach of this Agreement.
        </p>
        <p>GOVERNING LAW</p>
        <p>
            This Agreement shall be governed by and construed in accordance with the laws of the Netherlands, without giving effect to any
            principles of conflicts of law.
        </p>
        <p>ENTIRE AGREEMENT</p>
        <p>
            This Agreement constitutes the entire agreement between you and Smile Art and supersedes all prior or contemporaneous
            communications and proposals, whether oral or written, between you and Smile Art.
        </p>
        <p>MODIFICATIONS TO AGREEMENT</p>
        <p>
            Smile Art may modify this Agreement at any time, and such modifications shall be effective immediately upon posting of the
            modified Agreement on the Smile Art website. Your continued use of the Services after such modifications constitutes your
            acceptance of the modified Agreement.
        </p>
        <p>TERMINATION</p>
        <p>
            Smile Art may terminate this Agreement at any time for any reason, with or without notice. Upon termination, you must
            immediately stop using the Services and destroy any copies of the Smile Art materials in your possession.
        </p>
        <p>CONTACT INFORMATION</p>
        <p>If you have any questions or comments about this Agreement, please contact Smile Art at info&#64;smileart.nl.</p>
    </mat-dialog-content>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <mat-dialog-actions class="eula-dialog-actions">
        <button mat-stroked-button (click)="closeSignupDialog()">Decline</button>
        <button type="submit" color="primary" [disabled]="profileForm.invalid" mat-raised-button (click)="signup()">Accept</button>
    </mat-dialog-actions>
</ng-template>
