import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { userExistsValidator } from '../password-validators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    constructor(
        private readonly authService: AuthService,
        public readonly dialog: MatDialog,
    ) {}

    @ViewChild('resetPasswordDialog') resetPasswordDialog!: TemplateRef<unknown>;

    readonly email = new FormControl('', [Validators.required, Validators.email]);
    readonly password = new FormControl('', Validators.required);
    readonly resetPasswordControl = new FormControl('', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [userExistsValidator(this.authService, { reverse: true })],
        nonNullable: true,
    });
    hide = true;
    error = false;

    loading = false;

    login() {
        if (this.email.invalid || this.password.invalid) {
            return;
        }
        if (this.email.value && this.password.value) {
            this.error = false;
            this.loading = true;
            this.authService
                .login(this.email.value, this.password.value)
                // TODO: Kijken hoe we die lelijke errors uit de console kunnen houden tijdens login.
                .catch(() => (this.error = true))
                .finally(() => (this.loading = false));
        }
    }

    openResetPasswordDialog() {
        this.dialog.open(this.resetPasswordDialog);
    }

    async resetPassword() {
        await this.authService.sendResetPasswordMail(this.resetPasswordControl.value);
    }
}
