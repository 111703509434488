<div class="layout-wrapper">
    @if (invoice$(); as invoice) {
        <mat-card>
            <mat-card-header>
                <mat-icon mat-card-avatar>receipt_long</mat-icon>
                <mat-card-title>Invoice Details</mat-card-title>
                <mat-card-subtitle>{{ invoice.functionalId }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p><strong>Customer:</strong> {{ invoice.customer.firstName }} {{ invoice.customer.lastName }}</p>
                <p><strong>Practice:</strong> {{ invoice.practice.name }}</p>
                <p><strong>Invoice Date:</strong> {{ invoice.createdAt.toDate() | date: 'longDate' }}</p>
                <p><strong>Year/Month:</strong> {{ invoice.yearMonth }}</p>
                <p><strong>Total:</strong> {{ invoice.totals.total | currency }}</p>
                <p *ngIf="invoice.totals.discount"><strong>Discount:</strong> {{ invoice.totals.discount }}%</p>
                <p *ngIf="invoice.totals.discountAmount">
                    <strong>Discount Amount:</strong> {{ invoice.totals.discountAmount | currency }}
                </p>
                <p *ngIf="invoice.totals.totalWithDiscount">
                    <strong>Total with Discount:</strong> {{ invoice.totals.totalWithDiscount | currency }}
                </p>
                <p *ngIf="invoice.paidAt"><strong>Paid At:</strong> {{ invoice.paidAt.toDate() | date: 'longDate' }}</p>
                <p *ngIf="invoice.mail"><strong>Invoice sent to:</strong> {{ invoice.mail.address }}</p>
            </mat-card-content>
        </mat-card>
        <mat-divider vertical></mat-divider>
        @if (orders$(); as orders) {
            <mat-accordion multi="true">
                @for (order of orders; track $index) {
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>#{{ order.id }} - {{ order.type }}</mat-panel-title>
                            <mat-panel-description>{{ order.patient.firstName }} {{ order.patient.lastName }}</mat-panel-description>
                        </mat-expansion-panel-header>
                        <p><strong>Creation Date:</strong> {{ order.creationDate?.toDate() | date: 'longDate' }}</p>
                        <p><strong>Estimated Completion Date:</strong> {{ order.desiredCompletionDate?.toDate() | date: 'longDate' }}</p>
                        <p *ngIf="order.sendDate"><strong>Send Date:</strong> {{ order.sendDate.toDate() | date: 'longDate' }}</p>
                        <p *ngIf="order.amountOfModels"><strong>Amount of Models:</strong> {{ order.amountOfModels }}</p>
                        <p *ngIf="order.pricing"><strong>Price:</strong> {{ order.pricing.amount | currency }}</p>
                        <mat-action-row>
                            <button
                                routerLink="/customers/{{ order.customer.firestoreId }}/orders/{{ order.firestoreId }}"
                                mat-button
                                color="primary"
                            >
                                Go to order page
                            </button>
                        </mat-action-row>
                    </mat-expansion-panel>
                }
            </mat-accordion>
        }
    }
    @if (pdfURL) {
        <iframe data-embed="true" [src]="pdfURL" height="100%" style="border: none"></iframe>
    }
</div>
