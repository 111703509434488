import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
import { PasswordValidators, userExistsValidator } from '../password-validators';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
    /** Toggle for showing/hiding password field entry */
    hide = true;
    loading = false;
    error = false;

    @ViewChild('signupDialog') signupDialog!: TemplateRef<unknown>;

    constructor(
        private readonly auth: AuthService,
        private readonly router: Router,
        public readonly dialog: MatDialog,
    ) {}

    profileForm = new FormGroup(
        {
            firstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
            lastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
            phonenumber: new FormControl('', [Validators.required, Validators.pattern('[0-9]{0,15}')]),
            email: new FormControl('', {
                validators: [Validators.required, Validators.email],
                asyncValidators: [userExistsValidator(this.auth)],
            }),
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
            confirmPassword: new FormControl(null, [Validators.required]),
        },
        // Note: Matchvalidator is on the formGroup control but sets the errors on 'confirmPassword'.
        { validators: PasswordValidators.MatchValidator },
    );

    /** For cleaner template references */
    get f() {
        return this.profileForm.controls;
    }

    openSignupDialog() {
        if (this.profileForm.valid) {
            this.dialog.open(this.signupDialog);
        }
    }

    closeSignupDialog() {
        this.dialog.closeAll();
    }

    async signup() {
        if (!environment.production && !environment.useEmulators) {
            alert('Signup procedure turned off.');
            return;
        }
        if (this.profileForm.valid) {
            const { email, password, firstName, lastName, confirmPassword, ...rest } = this.profileForm.value;
            // Shouldnt be possible, but cant really hurt
            if (!email || !password) return;
            this.loading = true;
            this.error = false;
            await this.auth
                .createCustomer({
                    email,
                    password,
                    displayName: `${firstName} ${lastName}`,
                    docInput: { firstName, lastName, ...rest },
                })
                .then(() => this.router.navigateByUrl('/'))
                .catch(e => {
                    this.error = true;
                    console.error(e);
                    throw new Error('Signup failed', e);
                })
                .finally(() => {
                    this.loading = false;
                    this.closeSignupDialog();
                });
        }
    }
}
