import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
import { OrdersService } from '../orders.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
    constructor(
        private readonly authService: AuthService,
        private readonly ordersService: OrdersService,
        private readonly router: Router,
    ) {}

    user$ = this.authService.user$;
    admin$ = this.authService.adminSignal$;
    environment = environment.production;

    ordersWithUnseenMessages$ = this.ordersService.unseenMessagesSignal.asReadonly();

    @ViewChild('drawer') drawer!: MatDrawer;
    /** Expose toolbar so we can scroll to top of the view */
    @ViewChild('homeToolbar', { read: ElementRef }) toolbar!: ElementRef;

    logout() {
        this.authService.signOut();
    }

    async navigateTo(route: string) {
        await this.drawer.close();
        await this.router.navigateByUrl(route);
    }
}
