import { Injectable, signal, type WritableSignal } from '@angular/core';
import {
    FieldPath,
    Firestore,
    collection,
    collectionData,
    collectionGroup,
    deleteDoc,
    doc,
    docData,
    documentId,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    updateDoc,
    where,
    writeBatch,
    type WhereFilterOp,
} from '@angular/fire/firestore';
import * as pdfMake from 'pdfmake/build/pdfmake';
import type { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Observable } from 'rxjs';
import type { OrderWithFirestoreId, Patient, UpdateOrderRequest } from './types';
import { typeConverter } from './util';
const pdfFonts = require('../assets/js/vfs_fonts');

(pdfMake.vfs as any) = pdfFonts.pdfMake.vfs;
(pdfMake.fonts as any) = {
    Quicksand: {
        normal: 'Quicksand-Regular.ttf',
        bold: 'Quicksand-Bold.ttf',
        medium: 'Quicksand-Medium.ttf',
        semiBold: 'Quicksand-SemiBold.ttf',
    },
};

type QueryOrdersOptions = {
    limit?: number;
    startAfter?: string;
    orderBy?: {
        field: string | FieldPath;
        direction: 'asc' | 'desc';
    };
    where?: {
        field: string | FieldPath;
        operator: WhereFilterOp;
        value: string | number | boolean | string[] | number[] | boolean[];
    }[];
};

type UnseenMessage = { firestoreId: string; functionalId: string; customerFirestoreId: string };

@Injectable({
    providedIn: 'root',
})
export class OrdersService {
    constructor(private readonly firestore: Firestore) {}

    /**
     * Signal that emits the IDs of orders with unseen messages.
     */
    unseenMessagesSignal: WritableSignal<UnseenMessage[] | null> = signal(null);

    getOrder(customerFirestoreID: string, orderFirestoreId: string): Observable<OrderWithFirestoreId | undefined> {
        const docRef = doc(this.firestore, `customers/${customerFirestoreID}/orders/${orderFirestoreId}`).withConverter(
            typeConverter<OrderWithFirestoreId>(),
        );
        return docData(docRef, { idField: 'firestoreId' });
    }

    /**
     * Get order by functional ID
     *
     * NOTE: Use customerFirestoreID when possible. This significantly reduces the number of reads.
     */
    async getOrderByFunctionalID(functionalId: string): Promise<OrderWithFirestoreId | undefined>;
    async getOrderByFunctionalID(functionalId: string, customerFirestoreID: string): Promise<OrderWithFirestoreId | undefined>;
    async getOrderByFunctionalID(functionalId: string, customerFirestoreID?: string): Promise<OrderWithFirestoreId | undefined> {
        if (customerFirestoreID == null) {
            const collectionRef = collectionGroup(this.firestore, 'orders').withConverter(typeConverter<OrderWithFirestoreId>());
            const queryRef = query(collectionRef, where('id', '==', functionalId));
            const snapshot = await getDocs(queryRef);
            if (snapshot.empty) {
                return undefined;
            }
            const document = snapshot.docs[0];
            if (!document) return;
            return { ...document.data(), firestoreId: document.id };
        }
        const collectionRef = collection(this.firestore, `customers/${customerFirestoreID}/orders`).withConverter(
            typeConverter<OrderWithFirestoreId>(),
        );
        const queryRef = query(collectionRef, where('id', '==', functionalId));
        const snapshot = await getDocs(queryRef);
        if (snapshot.empty) {
            return undefined;
        }
        const document = snapshot.docs[0];
        if (!document) return;
        return { ...document.data(), firestoreId: document.id };
    }

    async updateOrder(customerFirestoreID: string, orderFirestoreId: string, order: UpdateOrderRequest): Promise<void> {
        const docRef = doc(this.firestore, `customers/${customerFirestoreID}/orders/${orderFirestoreId}`).withConverter(
            typeConverter<OrderWithFirestoreId>(),
        );
        await updateDoc(docRef, order);
    }

    async deleteOrder(orderFirestoreId: string, customerFirestoreID: string) {
        const docRef = doc(this.firestore, `customers/${customerFirestoreID}/orders/${orderFirestoreId}`);
        await deleteDoc(docRef);
        // TODO: #145 Delete all subcollections on a function trigger.
        // TODO: #145 Delete all storagefiles as well
    }

    /**
     * Retrieves a list of orders from the Firestore database.
     *
     * @param options - An optional object containing query options.
     * @returns An Observable that emits an array of orders with Firestore IDs.
     */
    getOrders(options?: QueryOrdersOptions): Observable<OrderWithFirestoreId[]> {
        let queryRef = collectionGroup(this.firestore, 'orders').withConverter(typeConverter<OrderWithFirestoreId>());
        if (options?.where?.length) {
            const whereClauses = options.where.map(({ field, operator, value }) => where(field, operator, value));
            queryRef = query(queryRef, ...whereClauses);
        }
        if (options?.orderBy) {
            queryRef = query(queryRef, orderBy(options.orderBy.field, options.orderBy.direction));
        }
        if (options?.limit) {
            queryRef = query(queryRef, limit(options.limit));
        } else {
            queryRef = query(queryRef, limit(500));
        }
        return collectionData(queryRef, { idField: 'firestoreId' });
    }

    /**
     * Retrieves the customer orders from Firestore.
     * @param customerFirestoreId - The Firestore ID of the customer.
     * @param options - Optional query options for filtering and ordering the results.
     * @returns An Observable of OrderWithFirestoreId[] representing the customer orders.
     */
    getCustomerOrders(customerFirestoreId: string, options?: QueryOrdersOptions): Observable<OrderWithFirestoreId[]> {
        let queryRef = query(
            collection(this.firestore, `customers/${customerFirestoreId}/orders`).withConverter(typeConverter<OrderWithFirestoreId>()),
        );
        if (options?.where?.length) {
            const whereClauses = options.where.map(({ field, operator, value }) => where(field, operator, value));
            queryRef = query(queryRef, ...whereClauses);
        }
        if (options?.orderBy) {
            queryRef = query(queryRef, orderBy(options.orderBy.field, options.orderBy.direction));
        }
        return collectionData(queryRef, { idField: 'firestoreId' });
    }

    /**
     * Get completed aligner orders by patientFirestoreId. If customerFirestoreId is provided, it will be used to query the specific customer's orders.
     *
     * NOTE: Use customerFirestoreID when possible. This significantly reduces the number of reads.
     */
    getCompletedAlignerOrdersByPatient(patientFirestoreId: string): Observable<OrderWithFirestoreId[]>;
    getCompletedAlignerOrdersByPatient(patientFirestoreId: string, customerFirestoreId: string): Observable<OrderWithFirestoreId[]>;
    getCompletedAlignerOrdersByPatient(patientFirestoreId: string, customerFirestoreId?: string): Observable<OrderWithFirestoreId[]> {
        if (!patientFirestoreId) throw new Error('Patient ID is required when querying orders by patient');
        if (customerFirestoreId === undefined) {
            const collectionRef = collectionGroup(this.firestore, 'orders').withConverter(typeConverter<OrderWithFirestoreId>());
            return collectionData(query(collectionRef, where('status', '==', 'Send'), where('patient.id', '==', patientFirestoreId)), {
                idField: 'firestoreId',
            });
        }
        const collectionRef = collection(this.firestore, `customers/${customerFirestoreId}/orders`).withConverter(
            typeConverter<OrderWithFirestoreId>(),
        );
        return collectionData(query(collectionRef, where('status', '==', 'Send'), where('patient.id', '==', patientFirestoreId)), {
            idField: 'firestoreId',
        });
    }

    /**
     * Retrieves the orders that have not been paid yet.
     * @returns A Firestore query for orders without a monthlyInvoiceId.
     */
    getOrdersNotPaidYet(): Observable<OrderWithFirestoreId[]> {
        const collection = collectionGroup(this.firestore, 'orders').withConverter(typeConverter<OrderWithFirestoreId>());
        return collectionData(query(collection, where('status', '==', 'Send'), where('monthlyInvoiceId', '==', null)), {
            idField: 'firestoreId',
        });
    }

    /**
     * Retrieves the customer orders that have not been paid yet.
     *
     * @param customerFirestoreId - The Firestore ID of the customer.
     * @returns An Observable of OrderWithFirestoreId[] representing the customer orders not paid yet.
     */
    getCustomerOrdersNotPaidYet(customerFirestoreId: string): Observable<OrderWithFirestoreId[]> {
        const collectionRef = collection(this.firestore, `customers/${customerFirestoreId}/orders`).withConverter(
            typeConverter<OrderWithFirestoreId>(),
        );
        return collectionData(query(collectionRef, where('status', '==', 'Send'), where('monthlyInvoiceId', '==', null)), {
            idField: 'firestoreId',
        });
    }

    /**
     * Retrieves orders from Firestore based on the customer Firestore ID and order IDs.
     * @param customerFirestoreId - The Firestore ID of the customer.
     * @param orderIds - An array of order IDs.
     * @returns A promise that resolves to an array of orders with Firestore IDs.
     */
    async getOrdersByFirestoreId(customerFirestoreId: string, orderIds: string[]): Promise<OrderWithFirestoreId[]> {
        const collectionRef = collection(this.firestore, `customers/${customerFirestoreId}/orders`).withConverter(
            typeConverter<OrderWithFirestoreId>(),
        );

        // Helper function to chunk the orderIds array
        const chunkArray = (array: string[], chunkSize: number): string[][] => {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        };

        // Split the orderIds into chunks of 30
        const chunks = chunkArray(orderIds, 30);

        // Perform multiple queries and collect the results
        const promises = chunks.map(chunk =>
            getDocs(query(collectionRef, where(documentId(), 'in', chunk))).then(snapshot =>
                snapshot.docs.map(doc => ({ ...doc.data(), firestoreId: doc.id })),
            ),
        );

        // Wait for all promises to resolve and combine the results
        const results = await Promise.all(promises);
        return results.flat();
    }

    // TODO: This should query on id instead of firstName and lastName.
    getPatientOrders(customerFirestoreId: string, patient: Patient): Observable<OrderWithFirestoreId[]> {
        const collectionRef = collection(this.firestore, 'customers', customerFirestoreId, 'orders').withConverter(
            typeConverter<OrderWithFirestoreId>(),
        );
        const orderQuery = query(
            collectionRef,
            where('patient.firstName', '==', patient.firstName),
            where('patient.lastName', '==', patient.lastName),
            orderBy('creationDate', 'desc'),
        );
        return collectionData(orderQuery, { idField: 'firestoreId' });
    }

    async markOrdersAsPaid(orders: OrderWithFirestoreId[], monthlyInvoiceId: string): Promise<void> {
        if (!orders.length) return;
        if (orders.length > 500) {
            throw new Error('Cannot mark more than 500 orders as paid at once');
        }
        const batch = writeBatch(this.firestore);
        orders.forEach(order => {
            const docRef = doc(this.firestore, `customers/${order.customer.firestoreId}/orders/${order.firestoreId}`);
            batch.update(docRef, { monthlyInvoiceId });
        });
        await batch.commit();
    }

    async verifyShareDesignCode(customerFirestoreId: string, orderFirestoreId: string, code: string): Promise<string | undefined> {
        const docRef = doc(this.firestore, `customers/${customerFirestoreId}/orders/${orderFirestoreId}`).withConverter(
            typeConverter<OrderWithFirestoreId>(),
        );
        const order = (await getDoc(docRef)).data();
        if (!order?.designURL) {
            throw new Error('Design URL not found');
        }
        if (order?.shareDesignCode === code) {
            return order.designURL;
        }
        return;
    }

    async setShareDesignCode(customerFirestoreId: string, orderFirestoreId: string): Promise<void> {
        const docRef = doc(this.firestore, `customers/${customerFirestoreId}/orders/${orderFirestoreId}`).withConverter(
            typeConverter<OrderWithFirestoreId>(),
        );
        await updateDoc(docRef, { shareDesignCode: Math.random().toString(36).substring(2, 8) });
    }

    hash(customerFirestoreId: string, orderFirestoreId: string): string {
        return window.btoa(`${customerFirestoreId}:::${orderFirestoreId}`);
    }

    unhash(hash: string): { customerFirestoreId: string; orderFirestoreId: string } {
        const [customerFirestoreId, orderFirestoreId] = window.atob(hash).split(':::');
        if (!customerFirestoreId || !orderFirestoreId) {
            throw new Error('Invalid hash');
        }
        return { customerFirestoreId, orderFirestoreId };
    }

    getQRPpdf(link: string, code: string): void {
        const pdfDocument: TDocumentDefinitions = {
            content: [
                {
                    qr: link,
                    fit: 250,
                },
                { marginTop: 20, text: `Code: ${code}` },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
            },
            defaultStyle: {
                font: 'Quicksand',
                lineHeight: 0.85,
                alignment: 'center',
            },
        };
        return pdfMake.createPdf(pdfDocument).print();
    }
}
