import { Component, ViewChild } from '@angular/core';
import { collection, collectionData, docData, Firestore, Query } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { doc } from '@firebase/firestore';
import { combineLatest, map, Observable, startWith, switchMap } from 'rxjs';
import { getIconColor, mapToIcon, TableOrder } from '../../orders/orders.component';
import { Customer, Order } from '../../types';
import { typeConverter } from '../../util';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent {
    customer$: Observable<Customer | undefined>;
    admin = false;
    displayedColumns: (keyof TableOrder)[] = ['icon', 'status', 'fullname', 'creationDate', 'type'];
    customerId?: string | null;

    // TODO: FIX TYPING
    dataSource!: MatTableDataSource<any>;
    @ViewChild(MatSort) sort?: MatSort;

    mapToIcon = mapToIcon;
    getIconColor = getIconColor;

    viewDraft = new FormControl<boolean>(getSessionStorageValue('customer.viewDraft', false), { nonNullable: true });
    viewSend = new FormControl<boolean>(getSessionStorageValue('customer.viewSend', false), { nonNullable: true });
    swapViewDraft() {
        const swappedvalue = !this.viewDraft.value;
        sessionStorage.setItem('customer.viewDraft', `${swappedvalue}`);
        this.viewDraft.setValue(swappedvalue);
    }
    swapViewSend() {
        const swappedValue = !this.viewSend.value;
        sessionStorage.setItem('customer.viewSend', `${swappedValue}`);
        this.viewSend.setValue(swappedValue);
    }

    constructor(
        readonly firestore: Firestore,
        readonly route: ActivatedRoute,
    ) {
        const viewDraft$ = this.viewDraft.valueChanges.pipe(startWith(this.viewDraft.value));
        const viewSend$ = this.viewSend.valueChanges.pipe(startWith(this.viewSend.value));

        this.customer$ = route.paramMap.pipe(
            switchMap(paramMap => {
                const customerId = paramMap.get('customerid');
                this.customerId = customerId;
                if (!customerId) {
                    throw new Error('customerId not found in route at customercomponent');
                }
                const document = doc(firestore, `/customers/${customerId}`).withConverter(typeConverter<Customer>());

                return docData(document);
            }),
        );
        combineLatest([route.paramMap, viewDraft$, viewSend$])
            .pipe(
                map(([paramMap, viewDraft, viewSend]) => {
                    const customerId = paramMap.get('customerid');
                    this.customerId = customerId;
                    if (!customerId) {
                        throw new Error('customerId not found in route at customercomponent');
                    }
                    const query = collection(firestore, `customers/${customerId}/orders`) as Query<Order>;
                    collectionData(query, { idField: 'id' })
                        .pipe(
                            map(v =>
                                v
                                    .filter(i => ![viewSend ? '' : 'Send', viewDraft ? '' : 'Draft'].includes(i['status']))
                                    .map(order => ({ ...order, fullname: `${order.patient.firstName} ${order.patient.lastName}` }))
                                    .sort(
                                        (a, b) =>
                                            (a.desiredCompletionDate?.valueOf() as unknown as number) -
                                            (b.desiredCompletionDate?.valueOf() as unknown as number),
                                    ),
                            ),
                        )
                        .subscribe(v => {
                            this.dataSource = new MatTableDataSource(v);
                            this.dataSource.sort = this.sort || null;
                        });
                }),
                // Fix takeuntil
            )
            .subscribe();
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
}

/** FIXME: Generieke plek en weghalen bij customer component. Generic type fixen. */
function getSessionStorageValue(key: string, defaultValue: boolean): boolean {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
}
