import { Component, Input, type OnChanges, type SimpleChanges } from '@angular/core';
import { PRODUCTION_SUB_STATUSES, PUBLIC_STATUSES, type OrderStatus, type OrderType, type ProductionSubStatus } from '../types';

// Default data for the timeline based on the public statuses, excluding 'Draft'.
const PUBLIC_DATA: OrderStatus[] = [...PUBLIC_STATUSES.filter(status => status !== 'Draft')];
const PRODUCTION_DATA: OrderStatus[] = [...PRODUCTION_SUB_STATUSES];
// Insert the production statuses after 'In production'.
const PUBLIC_DATA_WITH_PRODUCTION = PUBLIC_DATA.slice(0, 4).concat(PRODUCTION_DATA, PUBLIC_DATA.slice(4));

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnChanges {
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['admin']) {
            this.data = changes['admin'].currentValue ? PUBLIC_DATA_WITH_PRODUCTION : PUBLIC_DATA;
        }
        if (changes['orderType']) {
            // If orderType is not 'Aligner', remove 'Ready for review' from the data.
            if (changes['orderType'].currentValue !== 'Aligner') {
                this.data = this.data.filter(status => status !== 'Ready for review');
            }
        }
    }
    @Input() admin = false;
    @Input() data: OrderStatus[] = this.admin ? PUBLIC_DATA_WITH_PRODUCTION : PUBLIC_DATA;
    @Input({ required: true }) currentStatus!: OrderStatus;
    @Input({ required: true }) orderType!: OrderType;

    isCurrentStatus(status: OrderStatus): boolean {
        // If input currentStatus is a production sub status and we are in non admin mode, return as if its in production.
        if (!this.admin && PRODUCTION_SUB_STATUSES.includes(this.currentStatus as ProductionSubStatus)) {
            return status === 'In production';
        } else {
            return status === this.currentStatus;
        }
    }

    statusDone(status: OrderStatus): boolean {
        return PUBLIC_DATA_WITH_PRODUCTION.indexOf(status) < PUBLIC_DATA_WITH_PRODUCTION.indexOf(this.currentStatus);
    }
}
